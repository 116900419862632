import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-adm-charts',
  templateUrl: './adm-charts.component.html',
  styleUrls: ['./adm-charts.component.scss']
})
export class AdmChartsComponent implements OnInit {
  @Input() adminData: any  = []
  @Input() i18n: any  = []
  showCard: boolean = true;

  constructor() { }

  ngOnInit(): void {
    this.checkDomain();
  }

  checkDomain() {
    if (window.location.hostname === 'portal.educacao.aju.br') {
      this.showCard = false;
    }
  }

}
