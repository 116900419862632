<div class="box-options">
    <div 
        class="box-options__option" 
        [formGroup]="modelForm"
        [ngClass]="{'cursor-not-allowed opacity-75' : disabled}"
    >
        <div class="text-options-1">
            {{ discipline.title }}
        </div>
        <label class="container" formArrayName="targetDisciplines">
            <input
                [id]="discipline.external_id"
                class="{{ discipline.external_id }}"
                type="checkbox"
                [value]="discipline.external_id"
                [checked]="selected"
                [disabled]="disabled"
                [ngClass]="{'cursor-not-allowed opacity-75' : disabled}"
                (change)="toggleSelect(); checkDisciplineChange(discipline);"
            />
            <span 
                [ngClass]="{'cursor-not-allowed opacity-75' : disabled}"
                class="check"
            ></span>
        </label>
    </div>
</div>
