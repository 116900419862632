<div class="w-full">
    <!-- Control contents navigation -->
    <div
        class="flex justify-center items-center py-3 px-3 text-center md:px-16 rounded-lg bg-customBlue-royal text-customGray-default"
        style="min-height: 54px"
    >
        <span
            class="rounded ml-2 animate-pulse"
            style="background-color: #dedfe3; height: 15px; width: 50%"
        ></span>
    </div>

    <!-- Content type description and expand view -->
    <div
        class="mt-3 mb-5 rounded-md border border-customBlue-glass bg-customWhite-default"
    >
        <div
            class="flex p-4 justify-between gap-4 text-customGray-default animate-pulse"
        >
            <!-- content type description -->
            <div class="flex items-center gap-2 w-full">
                <div
                    class="rounded-full w-7 h-7"
                    style="background-color: #dedfe3"
                ></div>
                <span
                    class="rounded ml-2"
                    style="background-color: #dedfe3; height: 15px; width: 80%"
                ></span>
            </div>
        </div>
    </div>

    <!-- Content Rendering -->
    <div
        class="mb-8 rounded-md border border-customBlue-glass bg-customWhite-default"
    >
        <!-- Content Description -->
        <div class="px-4 py-6 animate-pulse">
            <ng-container *ngFor="let dc of contentDescription; let i = index">
                <div
                    class="flex mb-2"
                    [ngClass]="{ 'mb-8': i === 0 }"
                    style="height: 15px"
                >
                    <div
                        class="ml-2 rounded"
                        style="background-color: #dedfe3"
                        [style.width]="dc['width_description' + i] + '%'"
                    ></div>
                </div>
            </ng-container>
        </div>

        <!-- Content Completion Rules -->
        <div class="mt-4">
            <hr class="text-customBlue-glass" />
            <ul class="flex flex-col gap-2 p-4 text-customGray-default w-full">
                <li class="flex items-center gap-2">
                    <div
                        class="rounded-full w-7 h-7"
                        style="background-color: #dedfe3"
                    ></div>
                    <span
                        class="rounded ml-2"
                        style="
                            background-color: #dedfe3;
                            height: 15px;
                            width: 80%;
                        "
                    ></span>
                </li>
            </ul>
        </div>

        <!-- Content Preview -->
        <ng-container>
            <div class="flex p-2 justify-center">
                <div
                    class="animate-pulse rounded"
                    style="
                        width: 100%;
                        height: 500px;
                        background-color: #dedfe3;
                    "
                ></div>
            </div>
        </ng-container>
    </div>
</div>
