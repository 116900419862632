<section>
    <!-- HEAD -->
    <app-header-forum
        *ngIf="!isComponentForum"
        [showFilter]="false" [showAddModal]="false" [showSearch]="false"
        [user]="user" [titlePage]="titlePage"
        (sendQuery)="searchForum($event)">
    </app-header-forum>
    <!-- FIM HEAD -->
    <!-- CONTENT -->
    <div class="grid grid-cols-1 gap-4">
        <!-- CARD FORUM -->
        <ng-container *ngIf="!isLoading">
            <div id="root_post" class="shadow-card rounded-3xl bg-customWhite-default ">
                <div class="p-4" >
                    <h3 class="justify-center text-customGray-dark font-bold" >{{forum_post?.items?.title}}</h3>
                    <div class="flex pl-0 p-4">
                        <div 
                            class="w-full text-customGray-default" 
                            [innerHtml]="forum_post?.items?.text | safeHTML"
                            #threadText
                        ></div>
                    </div>
                    <ng-container *ngIf="forum_post?.items?.history.length > 0">
                        <div class="flex gap-2">
                            <div class="text-customGray-default text-sm italic">
                                {{i18n.forum_edited_for_the_last_time}} {{forum_post?.items?.history[forum_post?.items?.history.length-1].edited_at | dateAgo}}
                            </div>
                            <div class="text-customGray-grayThree text-sm font-bold cursor-pointer" (click)="openModalEditHistory(forum_post?.items?.history)">
                                {{i18n.forum_see_edits}}
                            </div>
                        </div>
                    </ng-container>
                    <div 
                        class="flex items-center "
                        [ngClass]="{'justify-end' : isComponentForum, 'justify-between' : !isComponentForum}"
                    >
                        <div 
                            *ngIf="!isComponentForum"
                            class="flex items-center cursor-pointer"
                            (click)="returnRoute()"
                        >
                            <i class="keyboard-arrow-left-icon keyboard-arrow-left"></i>
                            <span class="text-customGray-dark">
                                {{ i18n.forum_back }}
                            </span>
                        </div>
                        <div class="lg:flex flex xs:flex-col-reverse text-center items-center gap-2">
                            <div class="grid text-center">
                                <span 
                                    class="text-customGray-darker font-bold text-sm"
                                    style="white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    max-width: 250px;"
                                >
                                    {{forum_post?.items?.author?.name}}
                                </span>
                                <span class="text-customGray-default text-sm">{{forum_post?.items?.edited_at | dateAgo}}</span>
                            </div>
                            <app-user-frame
                                [style]="{'width':'40px', 'height':'40px', 'font-size': '15px', 'font-weight': 'bold'}"
                                [img_src]="forum_post?.items?.author?.photo || ''"
                                [user_name]="forum_post?.items?.author?.name || ''">
                            </app-user-frame>
                        </div>
                    </div>
                    
    
                    <ng-container *ngIf="isLoading">
                        <app-forum-detail-skeleton></app-forum-detail-skeleton>
                    </ng-container>
                </div>
            </div>
        </ng-container>
        <!-- SEÇÕES DE COMENTÁRIO -->
        <router-outlet></router-outlet>
    </div>
    <!-- FIM CONTENT -->
</section>
<app-loading-modal *ngIf="isLoading$"></app-loading-modal>
<app-decision-modal></app-decision-modal>
<app-message-modal></app-message-modal>

<app-modal-edit-history style="width: 30%"
    [isModalOpen]="isModalEditHistoryOpen"
    [edit_history]="edit_history"
    (sendIsModalOpen)="closeModalHistory($event)">
</app-modal-edit-history>

