import {
    ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, QueryList, ViewChild, ViewChildren
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';

import { ResponseModulesValues, ResponseModulesValuesSelects, Skill } from '../../../interfaces/curricular-parameters.interface';
import { Methodology } from '../../../interfaces/methodologies.interface';
import { ModuleDetail, ResponseModules } from '../../../interfaces/modules.interface';
import { ResponsePlanning } from '../../../interfaces/planning.interface';
import { ContentPlannerService } from '../../../services/content-planner.service';

@Component({
    selector: 'app-curricular-parameters',
    templateUrl: './curricular-parameters.component.html',
    styleUrls: ['./curricular-parameters.component.scss']
})
export class CurricularParametersComponent implements OnInit {
    @ViewChildren('multiSelectElement') multiSelectElements: QueryList<ElementRef> | undefined;
    @ViewChild('dropdown') dropdown: ElementRef;
    form: FormGroup;
    modulesValuesList: ResponseModulesValues[] = [];
    modulesValuesListSelect: ResponseModulesValuesSelects[] = [];
    planning: ResponsePlanning | null;
    modulesList: ResponseModules[] = [];
    receiveSelectedSkills: Skill[] = [];
    receiveSelectedMethodologies: Methodology[] = [];
    selectedSkills: Skill[] = [];
    valueModalDetailedPlanning = false;
    valueModalDecisionModule = false;
    valueModalSelectSkills = false;
    valueModalSelectMethodologies = false;
    createdModule = false;
    editMode = false;
    editModeSkills = false;
    editModeMethodologies = false;
    enableToGetDetailModule = false;
    triedGoToPlanning = false;
    loading = true;
    planningExternalId = '';
    moduleExternalId = '';
    i18n: any = {};
    suggestedModuleNames: string[] = [];
    selectedModuleName: string;
    valuesSelects: any = {};
    nameModule: string;

    isVisibleModalLoading = false;
    messageModalLoading = '';
    selectedModuleCode: string;

    constructor(private fb: FormBuilder, private contentPlannerService: ContentPlannerService, private platformModalService: PlatformModalsService, private cdRef: ChangeDetectorRef, private route: ActivatedRoute, private router: Router, private sharedService: SharedService) {
        this.createFormModule();
    }

    ngOnInit(): void {
        this.getTraductions();
        this.getParamsFromRoute();
        this.getPlanningDetail();
        this.getTags();
        this.cdRef.detectChanges();
    }

    @HostListener('window:resize', ['$event'])
    onResize(): void {
        this.updateDynamicMaxWidth();
    }

    getTraductions(): void {
        this.i18n = this.sharedService.getTranslationsOf('ContentPlanner');
    }

    getParamsFromRoute(): void {
        this.planningExternalId = this.route.snapshot.params.planning_external_id;
    }

    createFormModule(): void {
        this.form = this.fb.group({
            skills: [[]],
            methodologies: [[]]
        });
    }

    getTags(): void {
        this.loading = true;
        this.contentPlannerService.getModulesValues().subscribe({
            next: (response) => {
                this.modulesValuesList = response.tags.filter((e) => e.values.length);
                this.modulesValuesList = this.modulesValuesList.sort((a, b) => a.order - b.order);

                this.modulesValuesList.forEach((module) => {
                    this.modulesValuesListSelect.push({
                        ...module,
                        values: [
                            {
                                index: 0,
                                values: module.values
                            }
                        ]
                    });

                    this.valuesSelects[module.key_name] = module.values;

                    const control = this.fb.control([]);
                    this.form.addControl(`${module.key_name}_0`, control);
                });

                setTimeout(() => this.updateDynamicMaxWidth(), 400);
                this.loading = false;
            },
            error: () => {
                this.modulesValuesList = [];
                this.loading = false;
            },
        });
    }

    getModules(): void {
        if (this.planning) {
            this.contentPlannerService.getModules(this.planning.external_id).subscribe({
                next: (response) => {
                    this.modulesList = response.plannings_modules;
                    this.cdRef.markForCheck();
                    setTimeout(() => this.updateDynamicMaxWidth(), 500);
                },
                error: () => {
                    this.modulesList = [];
                }
            });
        }
    }

    getPlanningDetail(): void {
        if (this.planningExternalId) {
            this.contentPlannerService.getPlanningDetail(this.planningExternalId).subscribe({
                next: (response) => {
                    this.planning = response;
                    this.getModules();
                },
                error: () => {
                    this.router.navigate(['class-planner']);
                }
            });
        }
    }

    decisionModule(module_external_id: string): void {
        this.moduleExternalId = module_external_id;
        const formValues = this.form.value;

        const hasNonEmptyField = Object.keys(formValues)
            .filter((key) => key !== 'skills')
            .some((key) => {
                const value = formValues[key];
                return value !== null && value !== undefined && (Array.isArray(value) ? value.length > 0 : value !== '');
            });

        if (hasNonEmptyField) {
            this.toggleModalDecisionModule(true);
        } else {
            console.log('hasNonEmptyField', hasNonEmptyField);
            this.getModuleDetail(module_external_id);
        }
    }

    getModuleDetail(module_external_id: string): void {
        if (this.planning && module_external_id) {
            this.contentPlannerService.getModulesDetail(this.planning.external_id, module_external_id).subscribe({
                next: (response) => {
                    this.receiveSelectedSkills = response.skills;
                    this.receiveSelectedMethodologies = response.methodologies;
                    this.transformSelectedSkills(response.skills);
                    this.setValueEditMode(response);
                    this.editMode = true;
                    this.editModeSkills = true;
                    this.enableToGetDetailModule = false;
                },
                error: () => {
                    this.platformModalService.toggle('message', this.i18n.planner_error_edit_module, 'close');
                }
            });
        }
    }

    patchModuleName(module_external_id: string, name_value: string): void {
        if (this.planningExternalId && module_external_id && name_value) {
            const payload = { name: name_value };

            this.contentPlannerService.patchModuleName(this.planningExternalId, module_external_id, payload).subscribe({
                next: () => {
                    this.modulesList.map((e) => {
                        if (e.external_id === module_external_id) {
                            e.name = name_value;
                        }
                        return null;
                    });
                },
                error: () => {
                    this.platformModalService.toggle('message', this.i18n.planner_error_update_name_module, 'close');
                }
            });
        }
    }

    decisionDeleteModule(module_external_id: string): void {
        this.platformModalService.toggle('decision', this.i18n.planner_decision_delete_module, {
            forward: () => {
                this.deleteModule(module_external_id);
            },
            finally: () => {
                this.platformModalService.close('decision');
            },
        });
    }

    deleteModule(module_external_id: string): void {
        if (this.planning && module_external_id) {
            this.contentPlannerService.deleteModule(this.planning.external_id, module_external_id).subscribe({
                next: () => {
                    this.getModules();
                },
                error: () => {
                    this.platformModalService.toggle('message', this.i18n.planner_error_delete_module, 'close');
                },
                complete: () => {
                    this.resetRequest();
                }
            });
        }
    }

    onSubmit(): void {
        this.createdModule = false;
        this.saveModule();
    }

    addModule(): void {
        this.valueModalDecisionModule = true;
        this.moduleExternalId = '';
    }

    saveModule(): void {
        if (!this.nameModule) {
            this.platformModalService.toggle('message', this.i18n.planner_empty_knowledge_objects, 'close');
            return;
        }

        const payload = this.transformObject(this.form.value);

        if (payload?.skills?.length === 0) {
            this.platformModalService.toggle('message', this.i18n.planner_empty_skills, 'close');
            return;
        }

        if (payload?.methodologies?.length === 0) {
            this.platformModalService.toggle('message', 'É necessário selecionar metodologias', 'close');
            return;
        }

        this.isVisibleModalLoading = true;
        this.messageModalLoading = this.i18n.planner_decision_loading_create_module;

        if (this.form.valid && this.modulesValuesList.length && this.planning) {
            if (this.editMode) {
                this.contentPlannerService.patchModule(this.planning.external_id, this.moduleExternalId, payload).subscribe({
                    next: () => {
                        this.resetRequest();
                        this.resetSkills();
                        this.isVisibleModalLoading = false;
                        this.receiveSelectedMethodologies = [];
                    },
                    error: () => {
                        this.platformModalService.toggle('message', this.i18n.planner_error_edit_module_send, 'close');
                        this.isVisibleModalLoading = false;
                    }
                });
            } else {
                this.contentPlannerService.postModules(this.planning.external_id, payload).subscribe({
                    next: () => {
                        this.resetRequest();
                        this.resetSkills();

                        if (this.enableToGetDetailModule) {
                            this.getModuleDetail(this.moduleExternalId);
                        }

                        this.isVisibleModalLoading = false;
                        this.receiveSelectedMethodologies = [];
                    },
                    error: () => {
                        this.isVisibleModalLoading = false;
                        this.platformModalService.toggle('message', this.i18n.planner_error_create_module, 'close');
                    }
                });
            }
        }
    }

    transformObject(object: any): any {
        const payload: any = {
            skills: object.skills,
            methodologies: [...object.methodologies], // Fazer cópia direta do array de metodologias
            name: this.nameModule
        };

        const uniqueKeys = new Set<string>();

        // Extrair chaves únicas sem o índice
        Object.keys(object).forEach((key) => {
            const baseKey = key.replace(/_\d+$/, '');
            if (baseKey !== 'skills' && baseKey !== 'methodologies') {
                uniqueKeys.add(baseKey);
            }
        });

        uniqueKeys.forEach((e) => {
            const values = Object.entries(object)
                .filter(([key]) => key.startsWith(`${e}_`))
                .flatMap(([_, value]) => value) // Combina arrays em um único array
                .filter((value) => value !== null && value !== undefined && value !== '');

            payload[e] = values;
        });

        return payload;
    }

    setValueEditMode(response: ModuleDetail): void {
        if (response) {
            this.nameModule = response.name;

            if (response.code) {
                this.selectedModuleCode = response.code;
            }

            this.modulesValuesList.forEach((el) => {
                const parameterName = el.key_name;

                if (response.details[parameterName]) {
                    response.details[parameterName].map((e, index) => {
                        if (index === 0) {
                            this.form.controls[`${parameterName}_0`].setValue([e.id]);
                        } else {
                            setTimeout(() => {
                                this.addModelValueSelects(parameterName, e.id);
                                this.form.controls[`${parameterName}_${index}`].setValue([e.id]);

                                this.generateModuleNameSuggestions();
                            }, 100);
                        }
                    });
                }
            });
        }
    }

    resetRequest(): void {
        this.selectedSkills = [];
        this.resetForm();
        this.resetModuleValueSelects();
        this.createdModule = true;
        this.editMode = false;
        this.editModeSkills = false;
        this.suggestedModuleNames = [];
        this.nameModule = '';
        this.selectedModuleCode = '';
        this.getModules();
    }

    resetForm(): void {
        this.form.reset({
            skills: [],
        });
    }

    resetModuleValueSelects(): void {
        this.modulesValuesListSelect = [];

        this.modulesValuesList.forEach((module) => {
            this.modulesValuesListSelect.push({
                ...module,
                values: [
                    {
                        index: 0,
                        values: module.values
                    }
                ]
            });

            this.valuesSelects[module.key_name] = module.values;

            const control = this.fb.control([]);
            this.form.addControl(`${module.key_name}_0`, control);
        });
    }

    toggleModalDetailedPlanning(boolean: boolean) {
        if (typeof boolean === 'boolean') {
            this.valueModalDetailedPlanning = boolean;
        }
    }

    toggleModalDecisionModule(boolean: boolean) {
        if (typeof boolean === 'boolean') {
            this.valueModalDecisionModule = boolean;
        }
    }

    closeModalDecisionModuleAndNotSave(): void {
        this.resetRequest();
        this.getModuleDetail(this.moduleExternalId);
        this.toggleModalDecisionModule(false);
    }

    closeModalDecisionModuleAndSave(): void {
        this.enableToGetDetailModule = true;
        this.onSubmit();
        this.toggleModalDecisionModule(false);
    }

    updateDynamicMaxWidth(): void {
        if (this.multiSelectElements) {
            this.multiSelectElements.forEach((e: ElementRef) => {
                const containerWidth = e.nativeElement.parentElement.parentElement.offsetWidth;
                e.nativeElement.style.maxWidth = `${(containerWidth) - 50}px`;
            });
        }
    }

    onLoadResizeTextArea(element: HTMLTextAreaElement): void {
        if (element) {
            const textArea = element;
            textArea.style.height = `${textArea.scrollHeight}px`;
        }
    }

    autoResizeTextArea(event: Event): void {
        const textArea = event.target as HTMLTextAreaElement;
        textArea.style.height = 'auto';
        textArea.style.height = `${textArea.scrollHeight}px`;
    }

    checkHasNameModule(): void {
        this.triedGoToPlanning = true;

        if (this.modulesList.every((e) => e.name !== null)) {
            this.router.navigate(['class-planner/detailed-class-to-class', this.planningExternalId, 'class_to_class']);
            this.triedGoToPlanning = false;
        }
    }

    toggleModalSelectSkills(boolean: boolean) {
        if (typeof boolean === 'boolean') {
            this.valueModalSelectSkills = boolean;
        }
    }

    toggleModalSelectMethodologies(boolean: boolean) {
        if (typeof boolean === 'boolean') {
            this.valueModalSelectMethodologies = boolean;
        }
    }

    receiveGuidelines(event: any) {
        if (event.length) {
            const payload = event.map((e) => {
                const { isSelected, ...guideline } = e;
                return { isSelected, guideline };
            });

            const filteredGuidelinesList = payload.map((e) => e.guideline.id);

            this.form.controls.skills.setValue(filteredGuidelinesList);
        } else {
            this.form.controls.skills.setValue(event);
        }
    }

    transformSelectedSkills(skills: any) {
        this.selectedSkills = skills.map((e) => {
            const skill = {
                ...e,
                isSelected: true,
                guideline: e
            };
            return skill;
        });

        this.form.controls.skills.setValue(this.selectedSkills.filter((e) => e.isSelected).map((e) => e.guideline?.id));
    }

    submitSelectedSkills(event: any) {
        if (event.length) {
            const payload = event.map((e) => {
                const { isSelected, ...guideline } = e;
                return { isSelected, guideline };
            });

            this.selectedSkills = payload;

            const filteredGuidelinesList = payload.map((e) => e.guideline.id);

            this.form.controls.skills.setValue(filteredGuidelinesList);
        } else {
            this.form.controls.skills.setValue(event);
        }
    }

    submitSelectedMethodologies(event: any) {
        const methodologiesIds = event.map((e) => e.id);
        this.form.controls.methodologies.setValue(methodologiesIds);
        this.receiveSelectedMethodologies = event;

        console.log('receiveSelectedMethodologies', this.receiveSelectedMethodologies);

        this.toggleModalSelectMethodologies(false);
    }

    generateModuleNameSuggestions(): void {
        this.suggestedModuleNames = [];

        const fields = this.transformObject(this.form.value);

        if (fields.knowledge_objects?.length) {
            this.setSugestionByKnowledgeObject(fields.knowledge_objects);
        }

        // Retirando a sugestão de nome do módulo temporariamente

        // if (fields.knowledge_objects?.length) {
        //     this.setSugestionByKnowledgeObject(fields.knowledge_objects);
        // }

        // if (fields.thematic_unit?.length && fields.knowledge_objects?.length) {
        //     this.setSugestionByThematicUnitAndKnowledgeObject(fields.thematic_unit, fields.knowledge_objects);
        // }

        // if (fields.thematic_unit?.length && fields.language_axis?.length) {
        //     this.setSugestionByLanguageAxis(fields.language_axis, fields.thematic_unit);
        // }

        // if (this.editMode) {
        //     setTimeout(() => this.markNameModuleSuggestion(), 100);
        // }
    }

    setSugestionByKnowledgeObject(knowledgeObjects: any[]): void {
        const knowledgeObjectsList = this.modulesValuesList.find((field) => field.key_name === 'knowledge_objects')?.values;

        if (knowledgeObjectsList) {
            const selectedKnowledgeNames = knowledgeObjects
                .map((selectedId) => knowledgeObjectsList.find((value) => value.id === selectedId)?.description)
                .filter((fieldName) => fieldName !== undefined) || [];

            const uniqueSuggestions = Array.from(new Set(selectedKnowledgeNames));
            const concatenatedName = uniqueSuggestions.join(', ');

            // Remover essa linha caso retorne as sugestões de nome do módulo
            this.nameModule = concatenatedName;

            this.suggestedModuleNames.push(concatenatedName);
        }
    }

    setSugestionByThematicUnitAndKnowledgeObject(thematicUnit: any[], knowledgeObjects: any[]): void {
        const thematicUnitsList = this.modulesValuesList.find((field) => field.key_name === 'thematic_unit')?.values;
        const knowledgeObjectsList = this.modulesValuesList.find((field) => field.key_name === 'knowledge_objects')?.values;

        if (thematicUnitsList && knowledgeObjectsList) {
            const selectedKnowledge = knowledgeObjectsList
                .find((value) => value.id === knowledgeObjects[0])?.description;

            const tematicUnitNames = thematicUnit
                .map((selectedId) => thematicUnitsList.find((value) => value.id === selectedId)?.description)
                .filter((fieldName) => fieldName !== undefined) || [];

            const combinedNames = `${tematicUnitNames[0]} - ${selectedKnowledge}`;

            this.suggestedModuleNames.push(combinedNames);
        }
    }

    setSugestionByLanguageAxis(languageAxis: any[], thematicUnit: any[],): void {
        const languageAxisObjectsList = this.modulesValuesList.find((field) => field.key_name === 'language_axis')?.values;

        const thematicUnitsList = this.modulesValuesList.find((field) => field.key_name === 'thematic_unit')?.values;

        if (thematicUnitsList && languageAxisObjectsList) {
            const selectedThematicUnit = thematicUnitsList
                .find((value) => value.id === thematicUnit[0])?.description;

            const languageAxisNames = languageAxis
                .map((selectedId) => languageAxisObjectsList.find((value) => value.id === selectedId)?.description)
                .filter((fieldName) => fieldName !== undefined) || [];

            const combinedNames = `${languageAxisNames[0]} - ${selectedThematicUnit}`;

            this.suggestedModuleNames.push(combinedNames);
        }
    }

    selectModuleNameSuggestion(selectedName: string) {
        this.nameModule = selectedName;
        this.selectedModuleName = selectedName;
        // Desmarcar todos os outros checkboxes
        this.suggestedModuleNames.forEach((name) => {
            if (name !== selectedName) {
                const checkbox = document.querySelector(`input[value="${name}"]`) as HTMLInputElement;

                if (checkbox) {
                    checkbox.checked = false;
                }
            }
        });
    }

    markNameModuleSuggestion() {
        if (this.nameModule) {
            const checkbox = document.querySelector(`input[value="${this.nameModule}"]`) as HTMLInputElement;

            if (checkbox) {
                checkbox.checked = true;
            }
        }
    }

    onSelectChange(event: any): void {
        this.generateModuleNameSuggestions();
    }

    addModelValueSelects(selectName: string, id?: number): void {
        const values = this.getValues(selectName);

        if (!values) {
            return;
        }

        const lastIndex = values[values.length - 1]?.index ?? 0;
        const formControlName = `${selectName}_${lastIndex}`;
        const formValue = this.form.value[formControlName];

        if (!this.isFormValueValid(formValue, formControlName)) {
            return;
        }

        const formValues = this.getFormValues(selectName);
        const valuesSelect = this.getValuesSelect(selectName, formValues);

        this.addNewControl(selectName, lastIndex, valuesSelect, id);
        this.updateValuesList(selectName, values, valuesSelect, lastIndex);
    }

    private getValues(selectName: string): any[] | undefined {
        return this.modulesValuesListSelect.find((e) => e.key_name === selectName)?.values;
    }

    private isFormValueValid(formValue: any, formControlName: string): boolean {
        if (!formValue || formValue.length === 0) {
            this.setControlError(formControlName, { required: true });
            return false;
        }
        return true;
    }

    private setControlError(controlName: string, error: any): void {
        const control = this.form.get(controlName);
        if (control) {
            control.setErrors(error);
        }
    }

    private getFormValues(selectName: string): any[] {
        return Object.entries(this.form.value)
            .filter(([key, value]) => key.startsWith(`${selectName}_`) && Array.isArray(value))
            .flatMap(([_, value]) => value)
            .filter((value) => value !== null && value !== undefined && value !== '');
    }

    private getValuesSelect(selectName: string, formValues: any[]): any[] {
        return this.valuesSelects[selectName].filter((e) => !formValues.includes(e.id));
    }

    private addNewControl(selectName: string, lastIndex: number, valuesSelect: any[], id?: number): void {
        const control = this.fb.control(id || []);
        this.form.addControl(`${selectName}_${lastIndex + 1}`, control);
    }

    private updateValuesList(selectName: string, values: any[], valuesSelect: any[], lastIndex: number): void {
        const valuesList = {
            index: lastIndex + 1,
            values: valuesSelect
        };

        const limits = {
            thematic_axis: 20,
            knowledge_objects: 5,
            ods: Infinity,
            default: 3
        };

        const limit = limits[selectName] ?? limits.default;

        if (values.length < limit) {
            values.push(valuesList);
        } else {
            this.setControlError(`${selectName}_${lastIndex + 1}`, { limitReached: true });
        }
    }

    removeModelValueSelects(selectName: string, index: number): void {
        let values = this.modulesValuesListSelect.filter((e) => e.key_name === selectName)[0]?.values;

        if (values && values.length > 1) {
            values = values.filter((e) => e.index !== index);
            this.modulesValuesListSelect.filter((e) => e.key_name === selectName)[0].values = values;
        }

        this.form.removeControl(`${selectName}_${index}`);
        this.generateModuleNameSuggestions();
    }

    toggleSkillSelected(skillId: number): void {
        this.selectedSkills = this.selectedSkills.map((e) => {
            if (e.guideline && e.guideline.id === skillId) {
                e.isSelected = !e.isSelected;
            }
            return e;
        });

        this.form.controls.skills.setValue(this.selectedSkills.filter((e) => e.isSelected).map((e) => e.guideline?.id));
    }

    toggleMethodologySelected(methodologyId: number): void {
        this.receiveSelectedMethodologies = this.receiveSelectedMethodologies.map((e) => {
            if (e.id === methodologyId) {
                e.isSelected = !e.isSelected;
            }
            return e;
        });

        this.form.controls.methodologies.setValue(this.receiveSelectedMethodologies.filter((e) => e.isSelected).map((e) => e.id));
    }

    getNameModule(): string {
        if (this.editMode && this.selectedModuleCode) {
            return this.selectedModuleCode;
        }

        if (this.modulesList.length) {
            const moduleNumber = (this.modulesList.length + 1).toString().padStart(2, '0');
            return `${this.planning?.code}M${moduleNumber}`;
        }

        return '';
    }

    resetSkills(): void {
        this.selectedSkills = [];
        this.receiveSelectedSkills = [];
        this.form.controls.skills.setValue([]);
    }
}
