import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LocalStorageService } from 'src/app/services/localStorageService/local-storage.service';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';

import { AccountsService } from '../../accounts.service';

@Component({
    selector: 'app-login-guardian-code',
    templateUrl: './login-guardian-code.component.html',
    styleUrls: ['./login-guardian-code.component.scss']
})
export class LoginGuardianCodeComponent implements OnInit, OnDestroy {
    subscription$: Subscription;
    form: FormGroup;
    supportEmail = 'https://suporte.inicie.digital/';
    backgroundLogin = '';
    imageSideLogin = '';
    logoLogin = '';
    userGuardianLogin = '';
    messageCodeSended = '';
    messageCodeNotSended = '';

    isLoading = false;

    i18n: any = {};
    timeOutHandlerMessage;

    constructor(private sharedService: SharedService, private fb: FormBuilder, private localStorageService: LocalStorageService, private accountsService: AccountsService, private router: Router, private platModalService: PlatformModalsService) {
        this.createForm();
    }

    ngOnInit(): void {
        this.getTranslations();
        this.getWhoamiData();
        this.getUserGuardianLogin();
    }

    getTranslations(): void {
        this.i18n = this.sharedService.getTranslationsOf('Login');
    }

    createForm(): void {
        this.form = this.fb.group({
            code_input_1: ['', [Validators.required, Validators.maxLength(1)]],
            code_input_2: ['', [Validators.required, Validators.maxLength(1)]],
            code_input_3: ['', [Validators.required, Validators.maxLength(1)]],
            code_input_4: ['', [Validators.required, Validators.maxLength(1)]],
            code_input_5: ['', [Validators.required, Validators.maxLength(1)]],
        });
    }

    getWhoamiData(): void {
        if (this.localStorageService.getLocalStorageItem('main_logo')) {
            this.logoLogin = JSON.parse(localStorage.getItem('main_logo') || '');
        }

        if (this.localStorageService.getLocalStorageItem('login_background')) {
            this.backgroundLogin = JSON.parse(localStorage.getItem('login_background') || '');
        }

        if (this.localStorageService.getLocalStorageItem('login_image')) {
            this.imageSideLogin = JSON.parse(localStorage.getItem('login_image') || '');
        }
    }

    getUserGuardianLogin(): void {
        this.subscription$ = this.accountsService.userGuardianLogin$.subscribe({
            next: (response) => {
                if (response) {
                    this.userGuardianLogin = response;
                } else {
                    this.router.navigate(['./accounts/login-guardian']);
                }
            }
        });
    }

    validateInputNumber(formControlName: string, nextInputElement?: HTMLInputElement): void {
        if (formControlName) {
            let numericValue = this.form.controls[formControlName].value.replace(/[^0-9]/g, '');

            numericValue = numericValue.replace(/^0*(\d+)/, '$1');

            this.form.controls[formControlName].setValue(numericValue);

            if (numericValue && nextInputElement) {
                nextInputElement.focus();
            }
        }
    }

    onKeyDown(event: KeyboardEvent, formControlName: string, previousInputElement?: HTMLInputElement): void {
        if (event.key === 'Backspace') {
            const inputValue = this.form.controls[formControlName].value;
            if (!inputValue && previousInputElement) {
                previousInputElement.focus();
            }
        }
    }

    resendCode(): void {
        if (this.userGuardianLogin) {
            this.isLoading = true;

            // Reseta o contador
            if (this.timeOutHandlerMessage) {
                clearTimeout(this.timeOutHandlerMessage);
            }

            const isPhone = /^\d{4}/.test(this.userGuardianLogin);
            const typePayload = isPhone ? 'sms' : 'email';

            const payload = {
                type: typePayload,
                value: this.userGuardianLogin
            };

            this.accountsService.postAuthGuardian(payload).subscribe({
                next: () => {
                    // Limpa a mensagem
                    this.messageCodeNotSended = '';

                    this.messageCodeSended = this.i18n.login_code_sended_successfully;
                    this.form.reset();
                    this.isLoading = false;

                    // Limpa a mensagem em 5 minutos
                    this.timeOutHandlerMessage = setTimeout(() => {
                        this.messageCodeSended = '';
                    }, 300000);
                },
                error: (err) => {
                    let message = this.i18n.login_something_went_wrong;

                    if (err.error?.error?.includes('User not found') || err.error?.message?.includes('User not found')) {
                        message = this.i18n.login_guardian_not_found;
                    }

                    this.isLoading = false;

                    if (err.error?.error?.includes('The code is already sent in the last 5 minutes') || err.error?.message?.includes('The code is already sent in the last 5 minutes')) {
                        // Limpa a mensagem
                        this.messageCodeSended = '';
                        this.messageCodeNotSended = this.i18n.login_code_sent_in_5_minutes;

                        // Limpa a mensagem em 5 minutos
                        this.timeOutHandlerMessage = setTimeout(() => {
                            this.messageCodeNotSended = '';
                        }, 300000);
                        return;
                    }

                    this.platModalService.toggle('message', message, 'close');
                }
            });
        }
    }

    onSubmit(): void {
        if (this.form.valid && this.userGuardianLogin) {
            this.isLoading = true;
            let validCode = '';

            for (let i = 1; i < 6; i += 1) {
                validCode += `${this.form.controls[`code_input_${i}`].value}`;
            }

            const payload = {
                user: this.userGuardianLogin,
                code: validCode
            };

            this.accountsService.postAuthGuardianCode(payload).subscribe({
                next: (response) => {
                    this.isLoading = false;
                    this.sharedService.storeUserCredentials(response);

                    if (response.terms !== null) {
                        this.accountsService.authObject$.next(response);
                        this.accountsService.openModalPolicyAndPrivacy$.next({ openModal: true, readOnly: false });
                    } else {
                        this.sharedService.loggedIn.next(true);
                        this.accountsService.isRedirectRoute();
                        this.router.navigate(['']);
                    }
                },
                error: (err) => {
                    let message = this.i18n.login_something_went_wrong;

                    if (err.error?.error?.includes('Unauthorized') || err.error?.message?.includes('Unauthorized')) {
                        message = this.i18n.login_invalid_code;
                    }

                    this.isLoading = false;
                    this.platModalService.toggle('message', message, 'close');
                }
            });
        }
    }

    ngOnDestroy(): void {
        if (this.subscription$) {
            this.subscription$.unsubscribe();
        }
    }
}
