import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'src/app/services/localStorageService/local-storage.service';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';

import { AccountsService } from '../../accounts.service';

@Component({
    selector: 'app-microsoft-login',
    templateUrl: './microsoft-login.component.html',
    styleUrls: ['./microsoft-login.component.scss']
})
export class MicrosoftLoginComponent implements OnInit {
    @Input() isVisible: boolean;

    isLoading = false;
    i18n: any = [];
    login_url = '';
    window: any;

    constructor(
        private route: ActivatedRoute,
        private accountsService: AccountsService,
        private sharedService: SharedService,
        public platModalService: PlatformModalsService,
        private localStorageService: LocalStorageService,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.getTranslations();
        this.getAllowProvider();

        this.route?.queryParams?.subscribe({
            next: (params) => {
                if (Object.keys(params).length !== 0) {
                    this.getCode(params);
                }
            }
        });
    }

    getAllowProvider() {
        if (this.localStorageService.getLocalStorageItem('allowedProviders')) {
            const providers = JSON.parse(localStorage.getItem('allowedProviders') || '');

            if (providers?.length) {
                const microsoftProvider = providers.find((item) => item.name === 'microsoft');

                if (microsoftProvider !== undefined && Object.keys(microsoftProvider).length) {
                    this.getMicrosoftClientId();
                }
            }
        }
    }

    getTranslations() {
        this.i18n = { ...this.sharedService.getTranslationsOf('Errors'), ...this.sharedService.getTranslationsOf('Login') };
    }

    getMicrosoftClientId() {
        this.isLoading = true;
        const allowedProviders = JSON.parse(localStorage.getItem('allowedProviders') || '');
        if (Array.isArray(allowedProviders) || allowedProviders !== undefined || allowedProviders !== '') {
            allowedProviders.forEach((element) => {
                if (element.name === 'microsoft' && element.client_id !== '' && element.client_id !== undefined) {
                    this.login_url = this.setUpMicrosoftClient(element.client_id);
                }
            });
        } else {
            throw new Error('Plataform settings is undefined');
        }

        this.isLoading = false;
        this.isVisible = true;
    }

    storageEventListener(event: StorageEvent) {
        if (event.storageArea === localStorage) {
            const ms_authCode = event?.newValue;

            if (ms_authCode !== undefined && ms_authCode !== null) {
                this.authMicrosoft(ms_authCode);
            }
        }
    }

    setUpMicrosoftClient(client_id: any) {
        const uri = window.location.origin;
        return `https://login.live.com/oauth20_authorize.srf?prompt=consent
        &access_type=offline
        &state=bb4e6f98a143f20e1a5de9e70717df57
        &scope=wl.basic,wl.emails
        &response_type=code
        &approval_prompt=auto
        &redirect_uri=${uri}
        &client_id=${client_id}`;
    }

    loginPopup() {
        if (this.login_url !== '') {
            this.window = window.open(
                this.login_url,
                'popUpWindow',
                'height=500,width=400,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes'
            );
        }
    }

    getCode(params: any) {
        localStorage.setItem('ms_auth_code', params?.code);
        window.close();
    }

    authMicrosoft(code: any) {
        this.isLoading = true;

        const params = {
            code
        };

        this.accountsService.loginWithMicrosoft(params)
            .subscribe({
                next: (response) => {
                    this.sharedService.storeUserCredentials(response);
                    if (response.terms !== null) {
                        this.accountsService.authObject$.next(response);
                        this.accountsService.openModalPolicyAndPrivacy$.next({ openModal: true, readOnly: false });
                    } else {
                        this.sharedService.deleteKeyLocalStorage('ms_auth_code');
                        this.sharedService.loggedIn.next(true);
                        this.accountsService.isRedirectRoute();
                        this.router.navigate(['']);
                    }
                },
                error: (err) => {
                    this.isLoading = false;

                    if (err.status === 422) {
                        this.platModalService.toggle('message', this.i18n?.[err?.error?.key_error], 'close');
                    } else {
                        this.platModalService.toggle('message', this.i18n?.error_system_error, 'close');
                    }

                    this.sharedService.loggedIn.next(false);
                },
                complete: () => { this.isLoading = false; }

            });
    }
}
