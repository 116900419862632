import {
    AfterViewInit, Component, OnDestroy, OnInit
} from '@angular/core';
import { Subscription } from 'rxjs';
import { AccountsService } from 'src/app/pages/accounts/accounts.service';
import { SettingsIntegrationsService } from 'src/app/pages/settings/components/settings-integrations/settings-integrations.service';
import { WindowRef } from 'src/app/shared/window-ref';

@Component({
    selector: 'app-vlibras',
    templateUrl: './vlibras.component.html',
    styleUrls: ['./vlibras.component.scss']
})
export class VlibrasComponent implements OnInit, AfterViewInit, OnDestroy {
    subscription: Subscription[] = [];
    hasVLibrasActivated = false;

    constructor(
        private windowRef: WindowRef,
        private accountsService: AccountsService,
        private settingsIntegrationsService: SettingsIntegrationsService
    ) { }

    ngOnInit(): void {
        this.getPlatformAdvancedSettings();
    }

    ngAfterViewInit(): void {
        this.insertVlibrasPlugin();
        // esse subscribe abaixo é para quando estiver iniciando o sistema
        // ele notifica quando é inserido as customizações da plataforma
        const subscription$ = this.accountsService.update_localstorage.subscribe({
            next: (localStorageIsUpdated) => {
                if (localStorageIsUpdated) {
                    this.getPlatformAdvancedSettings();
                }
            }
        });

        this.subscription.push(subscription$);
    }

    getPlatformAdvancedSettings() {
        if (localStorage.getItem('advanced') === null) {
            return;
        }
        const advanced = JSON.parse(localStorage.getItem('advanced') || '');

        if (advanced !== null && advanced !== '' && advanced !== undefined) {
            this.hasVLibrasActivated = advanced.vlibras_enabled;
        }
    }

    insertVlibrasPlugin() {
        const item = document.createElement('script');
        item.src = 'https://vlibras.gov.br/app/vlibras-plugin.js';
        item.onload = () => {
            new this.windowRef.nativeWindow.VLibras.Widget('https://vlibras.gov.br/app');
        };
        document.body.appendChild(item);
    }

    ngOnDestroy(): void {
        this.subscription.forEach((item) => {
            item.unsubscribe();
        });
    }
}
