<app-submission-overview-box
    [question_number]="state.activeCourseWork.course_work.id"
    [question_subject]="state.contentName"
    [question_title]="state.contentDescription"
>
    <div class="container">
        <div *ngIf="courseWorkFiles.length">
            <div class="video-container mb-4" *ngFor="let file of courseWorkFiles">
                <iframe
                    [src]="file.public_url | safeUrl"
                    frameborder="0"
                    allow="accelerometer; encrypted-media; gyroscope;"
                    allowfullscreen
                ></iframe>
            </div>
        </div>
        <p *ngIf="!courseWorkFiles.length" class="text-center">
            {{ i18n.disciplines_content_submissions_no_task_delivered }}
        </p>
    </div>
</app-submission-overview-box>
