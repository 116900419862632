import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';

import { ButtonsModule } from '../buttons/buttons.module';
import { CustomDropdownComponent } from './custom-dropdown/custom-dropdown.component';
import { MultipleChoiceDropdownComponent } from './multiple-choice-dropdown/multiple-choice-dropdown.component';
import { SingleChoiceDropdownComponent } from './single-choice-dropdown/single-choice-dropdown.component';

@NgModule({
    declarations: [
        SingleChoiceDropdownComponent,
        MultipleChoiceDropdownComponent,
        CustomDropdownComponent
    ],
    exports: [
        SingleChoiceDropdownComponent,
        MultipleChoiceDropdownComponent,
        CustomDropdownComponent
    ],
    imports: [
        SharedModule,
        ButtonsModule,
    ]
})

export class DropdownsModule { }
