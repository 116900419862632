<app-box-login [background_login]="backgroundLogin" [image_side_login]="imageSideLogin" [hasBackButton]="true" [i18n]="i18n">
    <img [src]="logoLogin" class="accounts__logo_width" alt="Logo" onerror="this.src='../../../assets/png/empty_img.png'"/>

    <div class="text-center w-full md:w-10/12 flex flex-col gap-2">
        <p class="text-22 font-medium">{{ i18n.login_welcome }} 👋</p>
        <p class="text-14">{{ i18n.login_enter_email_or_phone }}.</p>
    </div>
    
    <form [formGroup]="form" (ngSubmit)="onSubmit()" class="w-full flex flex-col items-center gap-6" *ngIf="!isLoading">
        <fieldset class="w-full">
            <input type="text" formControlName="email_or_cellphone" class="border border-customGray-mediumGray rounded-sm w-full py-2 px-4" placeholder="Email ou número de celular" [mask]="mask" 
            (input)="onInputChangeMask()" autocomplete="off" required>
        </fieldset>

        <button type="submit" class="bg-customBlue-default text-customWhite-default font-medium rounded py-2 px-12" [ngClass]="{'opacity-75 cursor-not-allowed': form.invalid }" [disabled]="form.invalid">{{ i18n.login_next }}</button>
    </form>

    <!-- LOADING -->
    <ng-container *ngIf="isLoading">
        <div class="flex flex-col p-4 justify-center">
            <app-bullets-loader></app-bullets-loader>
        </div>  
    </ng-container>

    <app-contact-support [i18n]="i18n" [support_email]="supportEmail"></app-contact-support>
</app-box-login>