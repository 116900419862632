import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/services/localStorageService/local-storage.service';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';

import { AccountsService } from '../../accounts.service';

@Component({
    selector: 'app-standalone-login',
    templateUrl: './standalone-login.component.html',
    styleUrls: ['./standalone-login.component.scss']
})
export class StandaloneLoginComponent implements OnInit {
    @Input() providers: any = [];
    userForm: FormGroup;
    isHiddenPass: boolean;
    i18n: any = [];
    isVisible = false;
    isLoading: boolean;

    constructor(
        private formBuilder: FormBuilder,
        private accountsService: AccountsService,
        private sharedService: SharedService,
        public platformModalsService: PlatformModalsService,
        private localStorageService: LocalStorageService,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.form();
        this.getTranslations();
        this.getAllowProvider();
    }

    getAllowProvider() {
        if (this.localStorageService.getLocalStorageItem('allowedProviders')) {
            const providers = JSON.parse(localStorage.getItem('allowedProviders') || '');

            if (providers?.length) {
                const passwordProvider = providers.find((item) => item.name === 'password');

                if (passwordProvider !== undefined && Object.keys(passwordProvider).length) {
                    this.isVisible = true;
                }
            }
        }
    }

    getTranslations() {
        this.i18n = this.sharedService.getTranslationsOf('Login');
    }

    form() {
        this.userForm = this.formBuilder.group({
            email: ['', Validators.required],
            password: ['', Validators.required]
        });
    }

    hiddenPass() {
        const x = (<HTMLInputElement>document.getElementById('pass'));
        if (x.type === 'password') {
            x.type = 'text';
        } else {
            x.type = 'password';
        }
    }

    openModalResetPassword() {
        this.accountsService.openModalResetPassword$.next(true);
    }

    authUser() {
        this.isLoading = true;
        const e = this.userForm.controls.email.value;
        const p = this.userForm.controls.password.value;

        const params = {
            user: e,
            password: p,
        };

        this.accountsService.login(params)
            .subscribe({
                next: (response) => {
                    this.isLoading = false;
                    this.sharedService.storeUserCredentials(response);
                    if (response.terms !== null) {
                        this.accountsService.authObject$.next(response);
                        this.accountsService.openModalPolicyAndPrivacy$.next({ openModal: true, readOnly: false });
                    } else {
                        this.sharedService.loggedIn.next(true);
                        this.accountsService.isRedirectRoute();
                        this.router.navigate(['']);
                    }
                },
                error: (err) => {
                    this.isLoading = false;
                    if (err.error.error === 'Forbidden: Unauthorized.') {
                        this.platformModalsService.toggle('message', this.i18n.login_password_or_email_wrong, 'close');
                    }
                    this.sharedService.loggedIn.next(false);
                }
            });
    }
}
