import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'src/app/services/localStorageService/local-storage.service';
import { SharedService } from 'src/app/shared/shared.service';

@Injectable({
    providedIn: 'root'
})
export class ResetPasswordGuard implements CanActivate {
    constructor(
        private sharedService: SharedService,
        private router: Router,
        private localStorageService: LocalStorageService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        // Checks if all data to run application is loaded
        if (!this.localStorageService.checkAppIsLoaded()) {
            this.router.navigate(['']);
            return false;
        }

        if (!this.sharedService.fnRequestedValidationCode()) {
            this.router.navigate(['']);
            return false;
        }

        return true;
    }
}
