<div
    *ngIf="isShowToast"
    class="container"
    [ngClass]="{
        success: toastType === 'success',
        error: toastType === 'error',
        info: toastType === 'info',
        load: toastType === 'load'
    }"
>
    <div class="flex flex-row gap-2 items-center">
        <div class="toast-icon">
            <!-- Ícone está sendo colocado por scss -->
        </div>

        <div class="flex w-full items-center justify-center flex-wrap">
            <p class="text-customGray-dark text-15 ml-1">{{ toastMsg }}</p>

            <div *ngIf="urlAction && urlAction !== ''">
                <button class="flex justify-center border rounded-md py-2 px-1 font-bold text-sm text-customWhite-default bg-customBlue-default" (click)="clickEvent()">
                    {{ msgBtn }}
                </button>
            </div>
        </div>
    </div>
</div>
