import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import {
    filter, Observable, take
} from 'rxjs';

import { AccountsService } from './accounts.service';

@Injectable({ providedIn: 'root' })
export class AccountsResolver implements Resolve<any> {
    constructor(private accountsService: AccountsService) {}

    resolve(): Observable<any> {
        // Resolvendo os dados do whoami que já foram previamente carregados
        return this.accountsService.whoamiData$.pipe(
            filter((data) => !!data), // Apenas quando os dados estiverem disponíveis o resolver vai completar
            take(1) // Resolver vai completar após emitir os dados uma vez
        );
    }
}
