<app-generic-modal [isVisible]="true">
  <div class="header-modal">
    <div class="title-modal">
      {{ title }}
    </div>
  </div>
  <div class="container">
    <div class="body-modal">
      <form class="inputs-wrapper-modal" [formGroup]="modelForm">
        <div class="label-modal">{{ i18n.disciplines_form_class_name }}</div>
        <input class="input-modal" [maxlength]="201" formControlName="title" />
        <div *ngIf="trySubmitted">
          <div class="invalid-feedback" *ngIf="hasError('title') && hasError('title')!['required']">
            {{i18n.disciplines_template_name_required}}
          </div>
          <div class="invalid-feedback" *ngIf="hasError('title') && hasError('title')!['minlength']">
            {{ i18n.disciplines_template_name_min }} {{ hasError("title")!["minlength"].requiredLength }} {{ i18n.disciplines_template_characters }}
          </div>
          <div class="invalid-feedback" *ngIf="hasError('title') && hasError('title')!['maxlength']">
            {{  i18n.disciplines_template_name_max }} {{ hasError("title")!["maxlength"].requiredLength }} {{ i18n.disciplines_template_characters }}
          </div>
        </div>
        <div class="label-modal">{{ i18n.disciplines_form_class_code }}</div>
        <input class="input-modal" [maxlength]="201" formControlName="external_id"/>
        <div *ngIf="trySubmitted">
          <div class="invalid-feedback" *ngIf="hasError('external_id') && hasError('external_id')!['required']">
            {{i18n.disciplines_template_cod_required}}
          </div>
          <div class="invalid-feedback" *ngIf="hasError('external_id') && hasError('external_id')!['minlength']">
            {{i18n.disciplines_template_cod_min}} {{ hasError("external_id")!["minlength"].requiredLength }} {{ i18n.disciplines_template_characters }}
          </div>
          <div class="invalid-feedback" *ngIf="hasError('external_id') && hasError('external_id')!['maxlength']">
            {{i18n.disciplines_template_cod_max}} {{ hasError("external_id")!["maxlength"].requiredLength }} {{ i18n.disciplines_template_characters }}
          </div>
        </div>
        <div class="label-modal">{{ i18n.disciplines_segment }}</div>
        <select class="select-modal" formControlName="segment_external_id">
          <option disabled hidden [value]="''">
            {{ i18n.disciplines_segment }}
          </option>
          <option class="select" *ngFor="let segment of segmentSelect" [value]="segment.external_id"
            [ngValue]="segment.external_id">
            {{ translationsSegment(segment.external_id, segment.name) }}
          </option>
        </select>
        <div *ngIf="trySubmitted">
          <div class="invalid-feedback" *ngIf="hasError('segment_external_id') && hasError('segment_external_id')!['required']">
            {{i18n.disciplines_template_segment_required}}
          </div>
        </div>
        <div class="label-modal">{{ i18n.disciplines_form_modality }}</div>
        <select class="select-modal" formControlName="modality_external_id">
          <option disabled hidden [value]="''">
            {{ i18n.disciplines_form_modality }}
          </option>
          <option class="select" *ngFor="let modalitie of modalitiesSelect" [value]="modalitie.external_id"
            [ngValue]="modalitie.external_id">
            {{ translationsModality(modalitie.external_id, modalitie.name) }}
          </option>
        </select>
        <div *ngIf="trySubmitted">
          <div class="invalid-feedback" *ngIf="
              hasError('modality_external_id') &&
              hasError('modality_external_id')!['required']
            ">
            {{i18n.disciplines_template_modality_required}}
          </div>
        </div>
        <div class="ous-container">
          <div class="flex flex-col justify-end items-end">
            <div class="treeSelect-container">
              <p-treeSelect appendTo="body" [style]="{ width: '100%' }" [(ngModel)]="selectedNodes1"
                [ngModelOptions]="{ standalone: true }" [options]="orgUnits" (onNodeUnselect)="nodeUnselect($event)"
                (onNodeSelect)="nodeSelect($event)" [metaKeySelection]="false" selectionMode="single"></p-treeSelect>
            </div>
            <div *ngIf="isInvalidOrgUnits() == true" class="invalid-feedback">
              {{i18n.disciplines_template_ou_required}}
            </div>
          </div>
        </div>
        <div class="mt-2">
          <!-- <div class="treeSelect-container">
                    <p-treeSelect
                        appendTo="body"
                        [style]="{width: '100%'}"
                        [(ngModel)]="selectedNodes1"
                        [ngModelOptions]="{standalone: true}"
                        [options]="orgUnits"
                        (onNodeUnselect)="nodeUnselect($event)" (onNodeSelect)="nodeSelect($event)"
                        [metaKeySelection]="false"
                        selectionMode="multiple"
                    ></p-treeSelect>
                </div> -->
        </div>
        <!-- <div *ngIf="!orgUnitCheck">
                    <p class="text-sm " style="color: #E8532C;">
                      i18n.disciplines_form_unit_is_mandatory
                    </p>
                </div> -->
      </form>
    </div>
  </div>
  <!-- <button class="button-">TESTE</button> -->
  <div class="footer">
    <button class="button-save" (click)="onSubmit()" [disabled]="!isButtonActive"
      [ngClass]="{ 'button-disabled': !isButtonActive }">
      {{ i18n.disciplines_form_create }}
    </button>
    <button class="button-cancel" (click)="onCancel()">
      {{ i18n.disciplines_cancel }}
    </button>
  </div>
</app-generic-modal>
