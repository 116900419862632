<app-generic-modal [isVisible]="isVisibleModalListGuardian" [maxWidth]="'33.75rem'" [width]="'33.75rem'">
    <form class="py-4">
      <header class="pb-6 px-4 border-b border-customBlue-glass text-customBlue-default text-center">
        <p class="text-26 font-bold">{{ i18n.communication_app_guardians }}</p>
        <p class="text-14">{{ i18n.communication_app_view_guardians_son }}</p>
      </header>

     <article class="py-4 px-4 text-customGray-dark" *ngIf="!isLoading">
        <p class="font-medium mb-2 px-2">{{ i18n.communication_app_guardians_son }}</p>

        <ul class="flex flex-col gap-3 mb-5 overflow-y-auto guardian__list--scroll px-2" *ngIf="guardians.length">
            <li class="border border-customGray-default rounded p-2 flex items-start gap-3" *ngFor="let item of guardians">
                <div class="bg-customBlue-glass rounded-full bg-cover bg-no-repeat bg-center guardian__img--minwidth flex items-center justify-center font-semibold" [style.background-image]="'url(' + item.image + ')'">
                    <!-- img -->
                    <span *ngIf="!item.image" class="text-18 text-customWhite-default">{{ item.name | slice:0:1 }}</span>
                </div>
                <div class="w-full">
                    <div class="flex items-center justify-between gap-3">
                        <p class="font-medium text-19 overflow-hidden guardian__name--line break-words mb-1" pTooltip="{{ checkHasTooltip(32, item.name) ? item.name : '' }}" tooltipPosition="top" pRipple>{{ textLimitLength(32, item.name) }}</p>

                        <div class="flex items-center">
                            <button type="button" class="p-1" (click)="editGuardian(item.cpf)">
                                <span class="iconify text-18" data-icon="bx:edit-alt"></span>
                            </button>

                            <button type="button" class="p-1" (click)="deteleGuardian(item.external_id)">
                                <span class="iconify text-18" data-icon="bi:trash"></span>
                            </button>
                        </div>
                    </div>

                    <div class="flex flex-col sm:flex-row items-center gap-2 sm:gap-3 text-14">
                        <div class="flex flex-col gap-2 w-full">
                            <div class="flex items-center gap-1">
                                <span class="iconify text-18 text-customBlue-default" data-icon="bi:telephone"></span>
                                <p>{{ i18n.communication_app_telephone }}: 
                                    <span *ngIf="item.telephone">{{ item.telephone | mask: '(00) 0000-0000||(00) 00000-0000' }}</span> 
                                    <span *ngIf="!item.telephone">{{ i18n.communication_app_there_no }}</span>
                                </p>
                            </div>

                            <div class="flex items-center gap-1">
                                <span class="iconify text-18 text-customBlue-default" data-icon="mingcute:cellphone-line"></span>
                                <p>{{ i18n.communication_app_cellphone }}: 
                                    <span *ngIf="item.cellphone">{{ item.cellphone | mask: '(00) 0000-0000||(00) 00000-0000' }}</span> 
                                    <span *ngIf="!item.cellphone">{{ i18n.communication_app_there_no }}</span>
                                </p>
                            </div>
                        </div>

                        <div class="flex flex-col gap-2 w-full">
                            <div class="flex items-center gap-1">
                                <span class="iconify text-18 text-customBlue-default" data-icon="lucide:user-round"></span>
                                <p>{{ i18n.communication_app_cpf }}: {{ item.cpf | mask: '000.000.000-00' }}</p>
                            </div>

                            <div class="flex items-center gap-1">
                                <span class="iconify text-18 text-customBlue-default" data-icon="ic:outline-email"></span>
                                <p pTooltip="{{ checkHasTooltip(17, item.email) ? item.email : '' }}" tooltipPosition="top" pRipple>{{ i18n.communication_app_email }}: {{ textLimitLength(15, item.email) }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>

        <!-- EMPTY MESSAGE -->
        <div class="px-2 py-4" *ngIf="!guardians.length">
            <app-empty-data [text]="i18n.communication_app_message_empty_guardians_list"></app-empty-data>
        </div>

        <button type="button" class="flex items-center gap-3 px-2" (click)="closeModal(); callAddGuardianEmitter()">
            <div class="w-8 h-8 rounded-full bg-customOrange-default text-customWhite-default flex items-center justify-center">
                <span class="iconify text-18" data-icon="ic:baseline-plus"></span>
            </div>
            <span class="text-customBlue-dark">{{ i18n.communication_app_add_new_guardian }}</span>
        </button>
    </article>
    
     
     <footer class="px-6 py-4 flex justify-center items-center sm:items-stretch gap-4 flex-col sm:flex-row" *ngIf="!isLoading">
         <button class="bg-customBlue-default text-customWhite-default rounded font-bold w-max sm:w-auto px-10 py-2" type="button" (click)="closeModal()">{{ i18n.communication_app_continue }}</button>
         <button class="text-customBlue-default font-bold w-max sm:w-auto px-10 py-2" type="button" (click)="closeModal()">{{ i18n.communication_app_cancel }}</button>
        </footer>
    </form>


    <!-- LOADING -->
    <ng-container *ngIf="isLoading">
        <div class="flex flex-col px-8 pb-8 justify-center items-center font-medium gap-4 h-48">
            <p>{{ i18n.communication_app_loading }}.</p>
            <p>{{ i18n.communication_app_await_few_moments }}.</p>
            <app-bullets-loader></app-bullets-loader>
        </div>  
     </ng-container>
</app-generic-modal>