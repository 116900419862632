<app-generic-modal [isVisible]="isVisibleModalAddGuardian" [maxWidth]="'33.75rem'" [width]="'33.75rem'">
    <form class="py-4" [formGroup]="form" (ngSubmit)="onSubmit()">
      <header class="pb-6 px-4 border-b border-customBlue-glass text-customBlue-default text-center">
        <p class="text-26 font-bold">{{ editMode ? i18n.communication_app_edit_guardian : i18n.communication_app_add_guardian }}</p>
        <p class="text-14">{{ editMode ? i18n.communication_app_fill_fields_edit_guardian : i18n.communication_app_fill_fields_add_guardian }}</p>
      </header>

     <article class="py-4 px-6 text-customGray-dark" *ngIf="!isLoading">
        <p class="font-medium mb-3">{{ i18n.communication_app_guardian_data }}</p>

        <div class="flex flex-col gap-3">
            <fieldset class="flex flex-col gap-1">
                <label for="cpf">{{ i18n.communication_app_cpf }}</label>
                <input type="text" id="cpf" formControlName="cpf" class="border border-customGray-mediumGray rounded-sm py-2 px-4 w-full" autocomplete="off" mask="000.000.000-00" required> 

                <!-- Valitadion -->
                <div *ngIf="form.controls['cpf'].invalid && (form.controls['cpf'].dirty || form.controls['cpf'].touched)">
                    <p class="text-customOrange-default italic text-14" *ngIf="form.controls['cpf'].errors?.['required']">
                        {{ i18n.communication_app_cpf_required }}
                    </p>
                </div>

                <p *ngIf="IsInvalidCPF" class="text-customOrange-default italic text-14">{{ i18n.communication_app_cpf_invalid }}</p>

                <p *ngIf="isAlreadyExistsCPF" class="text-customOrange-default italic text-14">{{ i18n.communication_app_cpf_exists }}</p>
            </fieldset>

            <fieldset class="flex flex-col gap-1">
                <label for="name">{{ i18n.communication_app_guardian_name }}</label>
                <input type="text" id="name" formControlName="name" class="border border-customGray-mediumGray rounded-sm py-2 px-4 w-full" [ngClass]="{'bg-customGray-iceGray': form.controls['name'].disabled }" autocomplete="off" required>
                
                <!-- Valitadion -->
                <div *ngIf="form.controls['name'].invalid && (form.controls['name'].dirty || form.controls['name'].touched)">
                    <p class="text-customOrange-default italic text-14" *ngIf="form.controls['name'].errors?.['required']">
                        {{ i18n.communication_app_guardian_name_required }}
                    </p>
                </div>
            </fieldset>

            <fieldset class="flex flex-col gap-1">
                <label for="email">{{ i18n.communication_app_email }}</label>
                <input type="email" id="email" formControlName="email" class="border border-customGray-mediumGray rounded-sm py-2 px-4 w-full" [ngClass]="{'bg-customGray-iceGray': form.controls['email'].disabled }" autocomplete="off" required>

                 <!-- Valitadion -->
                 <div *ngIf="form.controls['email'].invalid && (form.controls['email'].dirty || form.controls['email'].touched)">
                    <p class="text-customOrange-default italic text-14" *ngIf="form.controls['email'].errors?.['required']">
                        {{ i18n.communication_app_email_required }}
                    </p>
                    <p class="text-customOrange-default italic text-14" *ngIf="form.controls['email'].errors?.['email']">
                        {{ i18n.communication_app_email_invalid }}
                    </p>
                </div>
            </fieldset>

            <div class="flex flex-col sm:flex-row gap-4">
                <fieldset class="flex flex-col gap-1">
                    <label for="telephone">{{ i18n.communication_app_telephone }}</label>
                    <input type="text" id="telephone" formControlName="telephone" class="border border-customGray-mediumGray rounded-sm py-2 px-4 w-full" [ngClass]="{'bg-customGray-iceGray': form.controls['telephone'].disabled }" autocomplete="off" maxlength="15" mask="(00) 0000-0000||(00) 00000-0000">
                </fieldset>

                <fieldset class="flex flex-col gap-1">
                    <label for="cellphone">{{ i18n.communication_app_cellphone }}</label>
                    <input type="text" id="cellphone" formControlName="cellphone" class="border border-customGray-mediumGray rounded-sm py-2 px-4 w-full" autocomplete="off" maxlength="15" mask="(00) 0000-0000||(00) 00000-0000" [ngClass]="{'bg-customGray-iceGray': form.controls['cellphone'].disabled }" required>

                    <!-- Valitadion -->
                    <div *ngIf="form.controls['cellphone'].invalid && (form.controls['cellphone'].dirty || form.controls['cellphone'].touched)">
                        <p class="text-customOrange-default italic text-14" *ngIf="form.controls['cellphone'].errors?.['required']">
                            {{ i18n.communication_app_cellphone_required }}
                        </p>
                    </div>
                </fieldset>
            </div>

            <fieldset class="flex flex-col gap-1">
                <label for="image">{{ i18n.communication_app_avatar }}</label>

                <div class="flex items-center gap-3">
                    <button type="button" class="guardian__img--minwidth bg-customBlue-light rounded-full border-dashed border-customBlue-superLight flex justify-center items-center bg-cover bg-center bg-no-repeat" [ngClass]="{'border': !fileImage, 'bg-customGray-iceGray cursor-not-allowed': form.controls['image'].disabled }" [disabled]="form.controls['image'].disabled" [style.background-image]="'url(' + fileImage + ')'" (click)="openModalUploadImage()">
                        <div *ngIf="!fileImage">
                            <span class="iconify text-26 text-customBlue-default" data-icon="ant-design:cloud-upload-outlined" ></span>
                        </div>
                        <div *ngIf="fileImage && !fileImage.includes('storage.googleapis')">
                            <span class="iconify text-26 text-customBlue-default" data-icon="material-symbols:check"></span>
                        </div>
                    </button>

                    <div>
                        <p class="text-customGray-textGray text-14 break-all">{{ fileName || i18n.communication_app_no_file_selected }}</p>
                        <p class="text-12">{{ i18n.communication_app_max_midia }}</p>
                    </div>
                </div>
            </fieldset>
        </div>
    </article>
    
     
     <footer class="px-6 py-4 flex justify-center items-center sm:items-stretch gap-4 flex-col sm:flex-row" *ngIf="!isLoading">
         <button class="bg-customBlue-default text-customWhite-default rounded font-bold w-max sm:w-auto px-10 py-2" type="submit" [ngClass]="{'opacity-75 cursor-not-allowed': form.invalid || IsInvalidCPF || isAlreadyExistsCPF }" [disabled]="form.invalid || IsInvalidCPF || isAlreadyExistsCPF">{{ editMode ? i18n.communication_app_edit : i18n.communication_app_add }}</button>
         <button class="text-customBlue-default font-bold w-max sm:w-auto px-10 py-2" type="button" (click)="closeModal(); callListGuardianEmitter()">{{ i18n.communication_app_back }}</button>
        </footer>
    </form>

    <!-- LOADING -->
    <ng-container *ngIf="isLoading">
        <div class="flex flex-col px-8 pb-8 justify-center items-center font-medium gap-4 h-48">
            <p>{{ editMode ? i18n.communication_app_editing_guardian : i18n.communication_app_creating_guardian }}</p>
            <p>{{ i18n.communication_app_await_few_moments }}.</p>
            <app-bullets-loader></app-bullets-loader>
        </div>  
     </ng-container>
</app-generic-modal>

<ng-container *ngIf="isVisibleModalAddGuardian">
    <app-upload
      *ngIf="modalOpen$ | async"
      [sharingStatus]="true"
      [maxFiles]="1"
      [files]="files"
      [multiSelect]="false"
      [fileType]="'photo'"
      (filesEvent)="receiveFiles($event)"
      ></app-upload>
</ng-container>