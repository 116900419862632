<div class="relative">
  <div (click)="openTooltip()">
    <div>
      <span
        class="text-customBlue-default iconify w-7 h-7 cursor-pointer"
        data-icon="clarity:bell-solid"
      ></span>
    </div>
    <div class="absolute" style="top: -3px; left: 17px">
      <span
        class="text-customOrange-default iconify w-4 h-4 cursor-pointer"
        data-icon="heroicons-solid:exclamation-circle"
      ></span>
    </div>
  </div>

  <ng-container *ngIf="isTooltipOpen">
    <div class="tooltip">
      <div class="flex flex-col gap-2 justify-center items-center font-Montserrat text-14">
        <div class="font-bold text-customGray-default">
          {{ i18n.notifications_tooltip_title }}
        </div>

        <div class="text-customGray-default text-center">
          {{ i18n.notifications_tooltip_message }}

          <div *ngIf="getBrowserInfo() === 'Microsoft Edge'">
            <div class="my-3">
              {{ i18n.notifications_edge_enable }}
            </div>

            <ol>
              <li class="my-2">{{ i18n.notifications_edge_enable_step_one }}</li>
              <li class="my-2">{{ i18n.notifications_edge_enable_step_two }}</li>
              <li class="my-2">{{ i18n.notifications_edge_enable_step_three }}</li>
            </ol>
          </div>
        </div>
      </div>
      <ng-container *ngIf="!isLoading">
        <div class="flex flex-col justify-center items-center">
          <div class="py-2 mt-2">
            <button
              (click)="requestPermission()"
              class="flex justify-center border border-customBlue-default bg-customBlue-default rounded-md cursor-pointer p-2 px-12"
            >
              <span class="text-customWhite-default font-bold">
                {{ i18n.notifications_enable }}
              </span>
            </button>
          </div>

          <div>
            <button (click)="closeTooltip()" class="flex justify-center cursor-pointer p-2 px-12">
              <span class="text-customBlue-default">{{ i18n.notifications_block }}</span>
            </button>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="isLoading">
        <div class="flex justify-center items-center" style="height: 98px">
          <app-bullets-loader></app-bullets-loader>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
