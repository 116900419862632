import {
    AfterViewInit, Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
    selector: 'app-pre-registered-dates-modal',
    templateUrl: './pre-registered-dates-modal.component.html',
    styleUrls: ['./pre-registered-dates-modal.component.scss']
})
export class PreRegisteredDatesModalComponent implements AfterViewInit, OnChanges {
    @ViewChild('checkButton', { static: true }) checkButton!: ElementRef;
    @ViewChild('containerReplicateDateType', { static: true }) containerReplicateDateType!: ElementRef;

    @Input() showResorucesDatesCheckboxes;
    @Input() parentComponent;
    @Output() sendDatesResourcesValues = new EventEmitter();
    @Output() sendCloseModal = new EventEmitter();

    keepingCurrentDates = true;
    noDates = false;
    i18n;

    datesResourcesValues = {
        date_resource_posting: false, // Datas de postagem dos recursos, verifica se tem algum conteúdo que não contém entrega e contém start_date.
        date_task_posting: false, // Datas de postagem das tarefas, verifica se tem algum conteúdo que contém entrega e start_date. Conteúdos com entrega: UploadAssignment, DocsAssignment, SheetsAssignment, InternalQuiz, Forum, Lti, UploadQuiz
        date_delivery: false, // Datas de entrega, verifica se tem conteúdo com end_date.
        date_start_videoconference: false, // Datas de inicio da videoconferências
        date_section_display: false, // Datas de exibição das seções, verifica se tem alguma seção com hidden_until
    };

    translationValues = {
        title: '',
        message: '',
        keep_dates: '',
        no_keep_dates_label: '',
        no_keep_dates: '',
    };

    constructor(
        private sharedService: SharedService
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes) {
            if (this.showResorucesDatesCheckboxes && Object.values(this.showResorucesDatesCheckboxes).length) {
                Object.keys(this.datesResourcesValues).forEach((key) => {
                    if (Object.prototype.hasOwnProperty.call(this.showResorucesDatesCheckboxes, key)) {
                        this.datesResourcesValues[key] = this.showResorucesDatesCheckboxes[key];
                    }
                });
            }

            this.getTranslations();
            if (this.parentComponent) {
                this.findTranslationsByParentComponent();
            }
        }
    }

    ngAfterViewInit() {
        const checkButtonWidth = this.checkButton.nativeElement.offsetWidth;
        this.containerReplicateDateType.nativeElement.style.marginLeft = `${checkButtonWidth}px`;
    }

    findTranslationsByParentComponent() {
        Object.keys(this.translationValues).forEach((key) => {
            const translationKey = `disciplines_pre_registered_${this.parentComponent}_${key}`;
            this.translationValues[key] = this.i18n[translationKey];
        });
    }

    getTranslations() {
        this.i18n = { ...this.sharedService.getTranslationsOf('Disciplines') };
    }

    getAlertMessage(): string {
        if (!this.showResorucesDatesCheckboxes) {
            return '';
        }

        if (this.showResorucesDatesCheckboxes.notification_videoconference_expired) {
            return this.i18n.disciplines_pre_registered_videoconference_expired;
        }

        if (this.showResorucesDatesCheckboxes.notification_date_delivery_expired) {
            return this.i18n.disciplines_pre_registered_delivery_expired;
        }

        if (this.showResorucesDatesCheckboxes.notification_date_delivery_ontime) {
            return this.i18n.disciplines_pre_registered_delivery_ontime;
        }
        return '';
    }

    changeValueKeepingCurrentDates(event) {
        this.keepingCurrentDates = event;

        if (this.keepingCurrentDates) {
            this.noDates = false;
        }

        if (!this.keepingCurrentDates) {
            this.removeAllChecksFromDatesByResources();
            this.noDates = true;
        }
    }

    changeValueNoDates(event) {
        this.noDates = event;

        if (this.noDates) {
            this.keepingCurrentDates = false;
            this.removeAllChecksFromDatesByResources();
        }

        if (!this.noDates) {
            this.keepingCurrentDates = true;
        }
    }

    removeAllChecksFromDatesByResources() {
        Object.keys(this.datesResourcesValues).forEach((key) => {
            this.datesResourcesValues[key] = false;
        });
    }

    changeValueDateResourceTypes(resoruce) {
        this.datesResourcesValues[resoruce] = !this.datesResourcesValues[resoruce];
        const hasAnyDateResourcesValueTrue = this.hasAnyDateResourcesValueTrue();
        if (hasAnyDateResourcesValueTrue) {
            this.noDates = false;
            this.keepingCurrentDates = true;
            return;
        }

        if (!hasAnyDateResourcesValueTrue) {
            this.noDates = true;
            this.keepingCurrentDates = false;
        }
    }

    hasAnyDateResourcesValueTrue(): boolean {
        return Object.values(this.datesResourcesValues).some((dateResourceValue) => dateResourceValue === true);
    }

    closeModal() {
        this.sendCloseModal.emit();
    }

    save() {
        this.sendDatesResourcesValues.emit(this.datesResourcesValues);
    }
}
