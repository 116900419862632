import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';
import { AccountsService } from '../accounts/accounts.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  resetForm: FormGroup;
  isHiddenPass: boolean = false;
  isHiddenPassConfirm: boolean = false;

  strengthPassword: any = {};
  isPasswordStrength: any = null;
  isConfirmPasswordValid: boolean = false;
  main_logo: any;

  i18n: any = [];

  @ViewChild('password') password: ElementRef;
  @ViewChild('confirmPassword') confirmPassword: ElementRef;

  constructor(
    private formBuilder: FormBuilder,
    private accountsService: AccountsService,
    private router: Router,
    public platformModalService: PlatformModalsService,
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    this.getTranslations();
    this.createResetForm();
    this.getMainLogo();
  }

  getTranslations() {
    this.i18n = {
      ...this.sharedService.getTranslationsOf('Errors'),
      ...this.sharedService.getTranslationsOf('Login'),
    };
  }

  getMainLogo(){
    this.main_logo = localStorage.getItem('main_logo') != null ? localStorage.getItem('main_logo') : '';
  }

  createResetForm(){
    this.resetForm = this.formBuilder.group({
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    })
  }


  hiddenPass(typeButton: string) {

    if (typeButton == 'password') {
      if (this.password.nativeElement.type === "password") {
        this.password.nativeElement.type = "text";
      } else {
        this.password.nativeElement.type = "password";
      }
    }else{
      if (this.confirmPassword.nativeElement.type === "password") {
        this.confirmPassword.nativeElement.type = "text";
      } else {
        this.confirmPassword.nativeElement.type = "password";
      }
    }
  }
  
  checkStrengthPassword(password: string){
    this.strengthPassword = { 
      downLetters: false,
      upperLetters: false,
      numbers: false,
      symbols: false,
      maxLength: false,
    }     

    if (password.match(/[a-z]+/)) {
      this.strengthPassword.downLetters = true;
    }
    if (password.match(/[A-Z]+/)) {
      this.strengthPassword.upperLetters = true;
    }
    if (password.match(/[0-9]+/)) {
      this.strengthPassword.numbers = true;
    }
    if (password.match(/[$@#&!]+/)) {
      this.strengthPassword.symbols =true;
    }
    if (password.length > 6) {
      this.strengthPassword.maxLength = true;
    }

    this.isPasswordStrength = Object.values(this.strengthPassword).every(
      value => value === true
    );
  }

  checkConfirmPassword(confirmPassword: string){
    
    if (this.password.nativeElement.value == confirmPassword) {
      this.isConfirmPasswordValid = true;
    }else{
      this.isConfirmPasswordValid = false;
    }
  }

  updatePassword(){
    let email = localStorage.getItem('email') != null ? JSON.parse(localStorage.getItem('email') || '') : '';
    let token = localStorage.getItem('codeValidation') != null ? JSON.parse(localStorage.getItem('codeValidation') || '') : '';
    const params = {
      "email": email,
      "password": this.password.nativeElement.value, 
      "token": token.toString(),
    } 

    this.accountsService.updatePassword(params)
    .subscribe({
      next:(response)=>{
        this.router.navigate(['']);
      },
      error: (err)=>{
        console.log('err ',err);
        let message = 'Erro de sistema, por favor contacte um administador';

        if (err.status == 422) {
          switch (err.error) {
            case 'User Token not found':
              message = 'Código não encontrado';
              break;

            case 'User Token expired':
              message = 'Código já está expirado';
              break;
          
            default:
              break;
          }
        }

        this.platformModalService.toggle('message', message, 'close');
      }
    })
  }

}
