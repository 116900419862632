import { Injectable } from '@angular/core';
import {TreeNode} from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class OrgUnitsDataFormat {
  data1: TreeNode[] = [];

  constructor(
  ) { }

   formatOrgunit(ou: any){
        if (Array.isArray(ou)) {

          ou = ou.filter( filterOU => filterOU.parent_external_id == "");

          return ou.map(
              (obj: any) => {
                  return {
                      "external_id":obj.external_id,
                      "label": obj.name,
                      "type": "person",
                      "styleClass": "p-person",
                      "expanded": true,
                      "types": "person",
                      "data": {
                          "name": obj?.name,
                          "avatar": "walter.jpg"
                      },
                      "children":obj?.children
                  }
              }
          )
        }else{
            ou['external_id'] = ou.external_id;
            ou['label'] = ou?.name;
            ou['type'] = "person";
            ou['styleClass'] = "p-person";
            ou['expanded'] = true;
            ou['types'] = "person";
            ou['data'] = {
                "name": ou?.name,
                "avatar": "walter.jpg"
            };
            ou['children'] = ou.children != null ? ou.children : [];

            delete ou['children_cache'];
            // delete ou['id'];
            delete ou['parents'];
            delete ou['parents_cache'];
            delete ou['related_organizational_units'];
            delete ou['name'];
            // let arr:any = [];
            // arr.push(ou);
            // console.log('OUS ',ou);

            return ou;
        }


    }

  jsonReorder(orgUnits: TreeNode[]){

      orgUnits = this.formatOrgunit(orgUnits);

      for (let index = 0; index < orgUnits.length; index++) {
          const element = orgUnits[index];
          this.childrenNames(element);
      }

      return orgUnits;

  }

  childrenNames(orgUnit: any){

      for (let index = 0; index < orgUnit.children?.length; index++) {
          let oNode = orgUnit.children[index];

          oNode = this.formatOrgunit(oNode);

          if (oNode?.hasOwnProperty('children'))
          {

            this.childrenNames(oNode)

          }
      }

  }
}
