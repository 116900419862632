<div class="fake-input" *ngIf="viewOnly">
    <span *ngIf="previousText === null">
        {{ i18n.question_bank_question_no_answer }}
    </span>

    <span *ngIf="previousText !== null" class="student-answer">{{ previousText }}</span>
</div>

<input
  *ngIf="!viewOnly"
  (input)="writeOnData($event)"
  class="real-input"
  type="text"
  [value]="previousText || ''"
  [placeholder]="i18n.question_bank_student_answer"
/>
