import {
    Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges
} from '@angular/core';
import { Subscription } from 'rxjs';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { DisciplinesModelsService } from 'src/app/shared/disciplines-models.service';

@Component({
    selector: 'app-duplicate-discipline',
    templateUrl: './duplicate-discipline.component.html',
    styleUrls: ['./duplicate-discipline.component.scss']
})
export class DuplicateDisciplineComponent implements OnChanges, OnDestroy {
    title: string;
    loading: boolean;
    subTitle: string;
    subscriptions: Subscription[] = [];

    @Input() templateMode;
    @Input() i18n;
    @Input() disciplineExternalId;

    @Output() sendDateResource: EventEmitter<boolean> = new EventEmitter();
    @Output() sendDatesResourcesValues = new EventEmitter();
    @Output() emitPostDuplicateDiscipline = new EventEmitter();
    @Output() templateModeChange = new EventEmitter();

    constructor(
        private disciplinesModelsService: DisciplinesModelsService,
        public platformModalService: PlatformModalsService
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes) {
            if (this.disciplineExternalId) {
                this.postDuplicate(this.disciplineExternalId, true);
            }
        }
    }

    closeModalDuplicateDiscipline() {
        this.disciplinesModelsService.closeDuplicateDiscipline();
    }

    preRegisteredDates;
    getPreRegisteredDatesCheckBoxes() {
        const sub = this.disciplinesModelsService.preRegisteredDates$.subscribe({
            next: (preRegisteredDates) => {
                console.log('preRegisteredDates ', preRegisteredDates);
                this.preRegisteredDates = preRegisteredDates;
                this.isPreRegisteredDateTemplateMode();
            }
        });

        this.subscriptions.push(sub);
    }

    datesResourcesValues;
    getDatesResourcesValues(datesResourcesValues) {
        this.sendDatesResourcesValues.emit(datesResourcesValues);
        this.postDuplicate(this.disciplineExternalId);
    }

    isPreRegisteredDateTemplateMode() {
        this.templateMode = 'preRegisteredSelectionMode';
    }

    getResourcesDatesInDuplicate(discipline_external_id: string): Promise<any> {
        // send empty to search all content and section in discipline
        const sectionsAndContents = '';

        return new Promise((resolve, reject) => {
            this.disciplinesModelsService.getDatesByResources(discipline_external_id, sectionsAndContents).subscribe({
                next: (resourcesDates: any) => {
                    resolve(resourcesDates);
                },
                error: (err) => {
                    reject(err);
                }
            });
        });
    }

    isLoading = false;
    async postDuplicate(discipline_external_id, checkPreRegisteredDates?: boolean) {
        console.log('discipline_external_id ', discipline_external_id);
        this.disciplineExternalId = discipline_external_id;
        this.preRegisteredDates = null;
        if (checkPreRegisteredDates) {
            this.isLoading = true;
            const preRegisteredDates = await this.getResourcesDatesInDuplicate(discipline_external_id);

            this.isLoading = false;
            let hasSomeResourceDateTrue = false;
            if (preRegisteredDates) {
                if (preRegisteredDates.notification_only_videoconference_expired) {
                    this.closeModalDuplicateDiscipline();
                    this.platformModalService.toggle('message', `${this.i18n.disciplines_pre_registered_videoconference_expired} ${this.i18n.disciplines_pre_registered_update_dates_try_again}`, 'close');
                    return;
                }
                hasSomeResourceDateTrue = Object.entries(preRegisteredDates)
                    .filter(([key, value]) => key.startsWith('date_'))
                    .some(([key, value]) => value === true);
            }

            if (hasSomeResourceDateTrue) {
                this.preRegisteredDates = preRegisteredDates;
                console.log('hasSomeResourceDateTrue ', hasSomeResourceDateTrue);
                this.templateMode = 'preRegisteredSelectionMode';
                return;
            }
        }
        this.templateMode = '';
        this.closeModalDuplicateDiscipline();
        this.emitPostDuplicateDiscipline.emit(this.disciplineExternalId);
    }

    ngOnDestroy(): void {
        this.templateMode = '';
        this.disciplineExternalId = '';
        if (this.subscriptions.length) {
            this.subscriptions.forEach((subscription) => {
                subscription.unsubscribe();
            });
        }
    }
}
