import {
    Component, Input, OnChanges, OnInit, SimpleChanges
} from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
    selector: 'app-modal-confirmation',
    templateUrl: './modal-confirmation.component.html',
    styleUrls: ['./modal-confirmation.component.scss']
})
export class ModalConfirmationComponent implements OnInit, OnChanges {
    modalConfirmation: boolean;
    i18n: any = {};

    hasCustomMessage = false;

    @Input() messageFeedback;

    constructor(
        private sharedService:SharedService
    ) { }

    ngOnInit(): void {
        this.getTranslations();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes) {
            console.log('this.messageFeedback ', this.messageFeedback);

            if (this.messageFeedback.length) {
                this.hasCustomMessage = true;
            }
        }
    }

    getTranslations() {
        this.i18n = this.sharedService.getTranslationsOf('Disciplines');
    }

    closeModal() {
        this.sharedService.modalCloseConfirmation();
    }
}
