import {
    Component, EventEmitter, Input, Output
} from '@angular/core';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';

import { GuardiansUsers } from '../../interfaces/user-guardian.interface';
import { UserGuardianService } from '../../services/user-guardian.service';

@Component({
    selector: 'app-modal-list-guardian',
    templateUrl: './modal-list-guardian.component.html',
    styleUrls: ['./modal-list-guardian.component.scss']
})
export class ModalListGuardianComponent {
    @Output() closeModalListGuardian = new EventEmitter();
    @Output() callAddGuardian = new EventEmitter();
    @Output() updatedListGuardian = new EventEmitter();
    @Output() sendCPF = new EventEmitter();
    @Output() sendIsLoading = new EventEmitter();
    @Input() guardians: GuardiansUsers[] = [];
    @Input() isVisibleModalListGuardian = false;
    @Input() sonOfGuardianExternalID = '';
    @Input() i18n: any = {};
    @Input() isLoading = false;

    constructor(private userGuardianService: UserGuardianService, private platModalService: PlatformModalsService) {}

    editGuardian(CPF: string): void {
        this.sendCPF.emit(CPF);
        this.closeModal();
        this.callAddGuardianEmitter();
    }

    deteleGuardian(guardianExternalID: string): void {
        if (this.sonOfGuardianExternalID && guardianExternalID) {
            this.isLoading = true;
            this.sendIsLoading.emit(this.isLoading);

            this.userGuardianService.deleteGuardian(this.sonOfGuardianExternalID, guardianExternalID).subscribe({
                next: () => {
                    this.updatedListGuardian.emit();
                },
                error: () => {
                    this.isLoading = false;
                    this.platModalService.toggle('message', this.i18n.communication_app_error_delete_guardian, 'close');
                }
            });
        }
    }

    textLimitLength(limit: number, textRaw: string): string {
        if (textRaw.length <= limit) {
            return textRaw;
        }

        const truncatedText = textRaw.substring(0, limit);
        return `${truncatedText}...`;
    }

    checkHasTooltip(limit: number, text: string): boolean {
        return text.length >= limit;
    }

    callAddGuardianEmitter(): void {
        this.callAddGuardian.emit(true);
    }

    closeModal(): void {
        this.closeModalListGuardian.emit(false);
    }
}
