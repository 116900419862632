import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    AbstractControl,
    FormBuilder, FormGroup, ValidationErrors, Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LocalStorageService } from 'src/app/services/localStorageService/local-storage.service';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';

import { AccountsService } from '../../accounts.service';

@Component({
    selector: 'app-login-guardian',
    templateUrl: './login-guardian.component.html',
    styleUrls: ['./login-guardian.component.scss']
})
export class LoginGuardianComponent implements OnInit, OnDestroy {
    subscription$: Subscription;
    form: FormGroup;
    supportEmail = 'https://suporte.inicie.digital/';
    backgroundLogin = '';
    imageSideLogin = '';
    logoLogin = '';
    mask = '';

    isLoading = false;

    i18n: any = {};

    constructor(private sharedService: SharedService, private fb: FormBuilder, private localStorageService: LocalStorageService, private accountsService: AccountsService, private router: Router, private platModalService: PlatformModalsService) {
        this.createForm();
    }

    ngOnInit(): void {
        this.getTranslations();
        this.getWhoamiData();
    }

    getTranslations(): void {
        this.i18n = this.sharedService.getTranslationsOf('Login');
    }

    createForm(): void {
        this.form = this.fb.group({
            email_or_cellphone: ['', [Validators.required, this.validateInputEmailCellphone]]
        });
    }

    getWhoamiData(): void {
        if (this.localStorageService.getLocalStorageItem('main_logo')) {
            this.logoLogin = JSON.parse(localStorage.getItem('main_logo') || '');
        }

        if (this.localStorageService.getLocalStorageItem('login_background')) {
            this.backgroundLogin = JSON.parse(localStorage.getItem('login_background') || '');
        }

        if (this.localStorageService.getLocalStorageItem('login_image')) {
            this.imageSideLogin = JSON.parse(localStorage.getItem('login_image') || '');
        }
    }

    validateInputEmailCellphone(control: AbstractControl): ValidationErrors | null {
        if (!control.value) {
            return null;
        }

        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const phonePattern = /^\d{10,11}$/;

        const isValidEmail = emailPattern.test(control.value);
        const isValidPhone = phonePattern.test(control.value);

        if (!isValidEmail && !isValidPhone) {
            return { invalidEmailOrPhone: true };
        }

        return null;
    }

    onInputChangeMask(): void {
        const inputValue = this.form.get('email_or_cellphone')?.value || '';
        const isPhone = /^\d{4}/.test(inputValue);

        if (isPhone) {
            this.mask = '(00) 0000-0000||(00) 00000-0000';
        } else {
            this.mask = '';
        }
    }

    onSubmit(): void {
        if (this.form.valid) {
            this.isLoading = true;

            const isPhone = /^\d{4}/.test(this.form.controls.email_or_cellphone.value);
            const typePayload = isPhone ? 'sms' : 'email';

            const payload = {
                type: typePayload,
                value: this.form.controls.email_or_cellphone.value
            };

            this.accountsService.postAuthGuardian(payload).subscribe({
                next: () => {
                    this.accountsService.userGuardianLogin$.next(payload.value);
                    this.router.navigate(['./accounts/login-guardian-code']);
                    this.isLoading = false;
                },
                error: (err) => {
                    let message = 'Aconteceu um erro';

                    if (err.error?.error?.includes('User not found') || err.error?.message?.includes('User not found')) {
                        message = this.i18n.login_guardian_not_found;
                    }

                    if (err.error?.error?.includes('The code is already sent in the last 5 minutes') || err.error?.message?.includes('The code is already sent in the last 5 minutes')) {
                        message = this.i18n.login_code_sent_in_5_minutes;
                    }

                    this.isLoading = false;
                    this.platModalService.toggle('message', message, 'close');
                }
            });
        }
    }

    ngOnDestroy(): void {
        if (this.subscription$) {
            this.subscription$.unsubscribe();
        }
    }
}
