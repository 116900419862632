import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-box-login',
    templateUrl: './box-login.component.html',
    styleUrls: ['./box-login.component.scss']
})
export class BoxLoginComponent implements OnInit {
    @Input() background_login = '';
    @Input() image_side_login = '';
    @Input() hasBackButton = false;
    @Input() isLoading = false;
    @Input() i18n: any = {};
    currentLanguage = '';

    ngOnInit(): void {
        this.getCurrentLanguage();
    }

    getCurrentLanguage(): void {
        const currentLanguage = localStorage.getItem('currentLanguage');

        if (currentLanguage) {
            this.currentLanguage = currentLanguage;
        }
    }
}
