<div class="title">
  <h1>{{ i18n.permissions_title_main }}</h1>
</div>

<section class="permission-wrapper" *ngIf="!isLoading">
  <section
    class="content transition ease-out duration-180 animate-fade-in-down"
  >
    <div id="container" class="scroller">
      <ng-container *ngFor="let group of groups">
        {{ group.name }}
        <div
          [attr.permission_name]="group.name"
          class="content__item"
          title="{{ getLabelsGrup(group.name) }}"
          [ngClass]="{ 'is-selected': group.name === group_id }"
          (click)="getTheRolesOfThisGroup(group.name)"
        >
        <p [ngClass]="{'letter-first-uppercase': 
        group.name !== 'googlechat' && 
        group.name !== 'chromebook_dashboard'}">
          {{ getLabelsGrup(group.name) }}
        </p>
        </div>
      </ng-container>
    </div>
  </section>
  <section
    class="select-section transition ease-out duration-180 animate-fade-in-down"
  >
    <div class="button-top" title="">
      <button
        *appCheckPermissions="'permissioning'; type: 'create'"
        class="button-add plus-fill-icon"
        id="tooltip"
        (click)="openModal()"
      >
        <span id="tooltipText">{{ i18n.permissions_title_create }}</span>
      </button>
    </div>
    <div class="title">
      <h2 [ngClass]="{'letter-first-uppercase': 
      group_id !== 'googlechat' && group_id !== 'chromebook_dashboard'}">{{ getLabelsGrup(group_id) }}</h2>
    </div>
    <div
      class="table-body scroller"
      *ngIf="!isLoadingGroup"
      id="container-select"
    >
      <ng-container *ngFor="let role of permissionRoles; let y = index">
        <div class="table-body__item-role-wrapper mt-4">
          <div class="table-body__item">
            <div class="tab-body__role">{{ role.role_name }}</div>
          </div>
          <div class="buttons-wrapper">
            <button
              *appCheckPermissions="'permissioning'; type: 'delete'"
              class="button-rounded-small"
              (click)="askToDeleteRole(role.role_external_id)"
            >
              <i class="trash-icon icon-stylized"></i>
            </button>
            <button
              *appCheckPermissions="'permissioning'; type: 'edit'"
              class="button-rounded-small"
              (click)="
                openModalEditMode(
                  role.role_external_id,
                  role.role_name,
                  role.role_type
                )
              "
            >
              <i class="edit-user-icon icon-stylized"></i>
            </button>
          </div>
        </div>
        <div class="table-body__itens-wrapper">
          <div
            class="table-body__item"
            *ngFor="let permission of role.action | keyvalue; let i = index"
          >
            <!--  <div class="header-mobile__item">{{ permission.key }}</div> -->
            <div class="header-mobile__item">
              {{ formatActions(permission.key) }}
            </div>
            <div class="header-mobile__item"></div>
            <button
              class="permission-button"
              (click)="
                permission.value = !permission.value;
                changeProfilePermissioning(role?.role_type, role?.role_external_id, permission.key, i, y);
                processSetPermissionsLocalStore(y, role?.role_type)
              "
              [ngClass]="{ 'is-checked': permission.value }"
            >
              <i class="check-slim-fill-icon icon-check"></i>
            </button>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="loading" *ngIf="isLoadingGroup">
      <img src="../../../assets/svg/loading.svg" alt="loading" />
    </div>
    <div class="footer">
      <button
        class="button-save"
        (click)="savePermissions()"
        [disabled]="!isButtonActive"
        [ngClass]="{ 'button-disabled': !isButtonActive }"
      >
        {{ i18n.permissions_button_save }}
      </button>
      <button class="button-cancel" (click)="getTheRolesOfThisGroup(group_id)">
        {{ i18n.permissions_button_cancelar }}
      </button>
    </div>
  </section>
</section>

<div class="loading" *ngIf="isLoading">
  <img src="../../../assets/svg/loading.svg" alt="loading" />
</div>

<app-modal-permissions
  *ngIf="isModalOpen"
  [editMode]="isEditMode"
  [roles]="roles"
  [externalId]="externalId"
  [roleName]="roleName"
  [roleType]="roleType"
  [group_id]="group_id"
  [i18n]="i18n"
  (visibleChange)="toggleModal($event)"
  (reload)="getTheRolesOfThisGroup(group_id)"
>
</app-modal-permissions>

<app-decision-modal></app-decision-modal>
<app-message-modal></app-message-modal>
