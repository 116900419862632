import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InputMaskModule } from '@ngneat/input-mask';
import { CopyAndReplicationModule } from 'src/app/components/copy-and-replication/copy-and-replication.module';
import { DisplayPanelModule } from 'src/app/components/display-panel/display-panel.module';
import { GenericModalModule } from 'src/app/components/modals/generic-modal/generic-modal.module';
import { LoadingModalModule } from 'src/app/components/modals/loading-modal/loading-modal.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ModalConfirmClassroomComponent } from './components/modal-confirm-classroom/modal-confirm-classroom.component';

import { CardDisciplineComponent } from './components/card-discipline/card-discipline.component';
import { ModalAddDisciplineComponent } from './components/modal-add-discipline/modal-add-discipline.component';
import { DisciplinesModelsComponent } from './disciplines-models.component';
import { DisciplinesModelsRoutingModule } from './disciplines-models-routing.module';

@NgModule({
    declarations: [
        DisciplinesModelsComponent,
        ModalAddDisciplineComponent,
        CardDisciplineComponent,
        ModalConfirmClassroomComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        DisciplinesModelsRoutingModule,
        DisplayPanelModule,
        GenericModalModule,
        CopyAndReplicationModule,
        LoadingModalModule,
        InputMaskModule
    ]
})
export class DisciplinesModelsModule { }
