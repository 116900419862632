<app-box-login [background_login]="backgroundLogin" [image_side_login]="imageSideLogin" [hasBackButton]="true" [i18n]="i18n">
    <img [src]="logoLogin" class="accounts__logo_width" alt="Logo" onerror="this.src='../../../assets/png/empty_img.png'"/>

    <div class="text-center w-3/4 md:w-8/12 flex flex-col gap-2">
        <p class="text-22 font-medium">{{ i18n.login_enter_code }}</p>
        <p class="text-14">{{ i18n.login_enter_code_sent_email }}</p>
    </div>

    <form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="!isLoading" class="w-full flex flex-col items-center gap-6">
        <div class="w-full">
            <fieldset class="grid grid-cols-5 gap-1 sm:gap-3 w-full">
                <input type="text" class="border border-customGray-lighterGray rounded-md w-full py-3.5 text-center font-medium shadow-lg" formControlName="code_input_1" #codeInput1 autocomplete="off" required maxlength="1" (input)="validateInputNumber('code_input_1', codeInput2)" (keydown)="onKeyDown($event, 'code_input_1')">
    
                <input type="text" class="border border-customGray-lighterGray rounded-md w-full py-3.5 text-center font-medium shadow-lg"  formControlName="code_input_2" #codeInput2 autocomplete="off" required maxlength="1" (input)="validateInputNumber('code_input_2', codeInput3)" (keydown)="onKeyDown($event, 'code_input_2', codeInput1)">
    
                <input type="text" class="border border-customGray-lighterGray rounded-md w-full py-3.5 text-center font-medium shadow-lg"  formControlName="code_input_3" #codeInput3 autocomplete="off" required maxlength="1" (input)="validateInputNumber('code_input_3', codeInput4)" (keydown)="onKeyDown($event, 'code_input_3', codeInput2)">
    
                <input type="text" class="border border-customGray-lighterGray rounded-md w-full py-3.5 text-center font-medium shadow-lg"  formControlName="code_input_4" #codeInput4 autocomplete="off" required maxlength="1" (input)="validateInputNumber('code_input_4', codeInput5)" (keydown)="onKeyDown($event, 'code_input_4', codeInput3)">
    
                <input type="text" class="border border-customGray-lighterGray rounded-md w-full py-3.5 text-center font-medium shadow-lg"  formControlName="code_input_5" #codeInput5 autocomplete="off" required maxlength="1" (input)="validateInputNumber('code_input_5')" (keydown)="onKeyDown($event, 'code_input_5', codeInput4)">
            </fieldset>

            <div *ngIf="messageCodeSended || messageCodeNotSended" class="w-full mt-2 text-14 italic font-medium">
                <p *ngIf="messageCodeSended" class="text-customGreen-default">{{ messageCodeSended }}</p>
                <p *ngIf="messageCodeNotSended" class="text-customOrange-default">{{ messageCodeNotSended }}</p>
            </div>
        </div>

        <footer class="flex flex-col gap-2">
            <button type="submit" class="bg-customBlue-default text-customWhite-default font-medium rounded py-2 px-12" [ngClass]="{'opacity-75 cursor-not-allowed': form.invalid }" [disabled]="form.invalid">{{ i18n.login_sign_in }}</button>

            <button type="button" (click)="resendCode()" class="text-customGray-grayThree font-semibold rounded py-2 px-12 text-14">{{ i18n.login_resend }}</button>
        </footer>
    </form>

    <!-- LOADING -->
    <ng-container *ngIf="isLoading">
        <div class="flex flex-col p-4 justify-center">
            <app-bullets-loader></app-bullets-loader>
        </div>  
    </ng-container>
    
    <app-contact-support [i18n]="i18n" [support_email]="supportEmail"></app-contact-support>
</app-box-login>

<app-modal-use-terms></app-modal-use-terms>
<app-modal-policy-and-privacy></app-modal-policy-and-privacy>