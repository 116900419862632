import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-adm-users',
  templateUrl: './adm-users.component.html',
  styleUrls: ['./adm-users.component.scss']
})
export class AdmUsersComponent {

  @Input() adminData: any = []
  @Input() i18n: any = [];
  showCard: boolean = true;

  ngOnInit(): void {
    this.checkDomain();
  }

  checkDomain() {
    if (window.location.hostname === 'portal.educacao.aju.br') {
      this.showCard = false;
    }
  }

}
