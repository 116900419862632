import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-contact-support',
    templateUrl: './contact-support.component.html',
    styleUrls: ['./contact-support.component.scss']
})
export class ContactSupportComponent {
    @Input() support_email = '';
    @Input() i18n: any = {};
}
