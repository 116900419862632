<div class="relative w-full" (click)="$event.stopPropagation()">
    <div
        class="bg-customWhite-default p-2 flex items-center justify-between cursor-pointer"
        style="color: #495057;
        border: 1px solid #cfd4da;
        border-radius: 3px;
        "
        (click)="toggleDropdown()"
    >
        <div class="flex flex-wrap gap-2">
            <span 
                *ngIf="selectedItems.length === 0"
                style="color: #6c757d"
            >
                {{ placeholderLabel }}
            </span>

            <span 
                *ngIf="selectedItems.length > 0"
                style="color: #6c757d"
            >
                {{ selectedItems.length }} {{ placeholderSelectedLabel }}
            </span>
        </div>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            fill="none"
            viewBox="0 0 22 22"
            stroke="#6d757d"
        >
            <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 9l-7 7-7-7"
            />
        </svg>
    </div>

    <!-- Dropdown List -->
    <div
        *ngIf="isDropdownOpen"
        class="absolute mb-1 w-full bg-customWhite-default z-10 max-h-72 overflow-y-auto "
        style="
            color: #495057;
            border-bottom: 10px solid #FFF;
            border-radius: 3px;
            box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
        "
    >
        <!-- Header -->
        <div 
            class="flex justify-center items-center px-4 py-2 mb-2"
            style="background-color: #f8f9fa"
        >
            <div 
                class="p-2 rounded-sm w-full"
                style="border: 1px solid #cfd4da; background-color: #FFF; border: 1px solid #cfd4da;"
            >
                <input
                    type="text"
                    [(ngModel)]="searchText"
                    (input)="onSearch()"
                />
            </div>
        </div>

        <!-- Body -->
        <div class="p-2 flex items-center">
            <div>
                <app-check-button
                (isActive)="toggleSelectAll()"
                [iconSize]="21"
                [roundedValue]="5"
                [active]="areAllSelected()"
                ></app-check-button>
            </div>
            <div 
                class="ml-2"
                style="color:#495057; line-height: normal;"
            >
                {{ areAllSelected() ? i18n.cms_unselect_all : i18n.cms_select_all }}
            </div>
        </div>
        <div
            *ngFor="let item of filteredItems"
            class="flex items-center p-2 pl-4 hover:bg-gray-100 cursor-pointer"
        >
            <div 
                
            >
                <app-check-button
                    (isActive)="toggleSelection(item)"
                    (click)="$event.stopPropagation()" 
                    [iconSize]="21"
                    [roundedValue]="5"
                    [active]="isSelected(item)"
                ></app-check-button>
            </div>
            <div 
                class="ml-2"
                style="color:#495057; line-height: normal;"
            >
                {{ item.name }}
            </div>
        </div>
    </div>
</div>
