import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UsersService } from '../../users.service';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';

@Component({
  selector: 'modal-commission-team',
  templateUrl: './modal-commission-team.component.html',
  styleUrls: ['./modal-commission-team.component.scss']
})
export class ModalCommissionTeamComponent implements OnInit {

  @Input() commissionUser: any;
  @Input() i18n: any;
  @Input() isVisible: boolean = false;

  _refreshCommissionTeam: boolean = false;
  private _isActive: boolean = false;

  get refreshCommissionTeam():boolean {    
    if (this._refreshCommissionTeam){
      this.refreshCommission();      
    };
    return this._refreshCommissionTeam;
  }
  @Input() set refreshCommissionTeam(value: boolean){
    this._refreshCommissionTeam = value;    
    this.refreshCommissionTeam;
  };

  @Output() sendCloseModal = new EventEmitter();
  @Output() sendCommission = new EventEmitter();

  dataSource: any[] = [];
  isLoadingCommissionGroup: boolean = false;
  selectedCommissionGroup: number = 0;
  filterCommission: any;

  constructor(
    private usersService: UsersService,
    public platModalService: PlatformModalsService) { }

  ngOnInit(): void {  
    // this.refreshCommissionTeam = true;
    this.refreshCommission();
  }

  refreshCommission() {
    let params = {query:  ''};
    if (this.filterCommission != '' && this.filterCommission != undefined) {
      params.query = this.filterCommission;
    }
    
    let $this = this;
    this.usersService.getCommssionTeam(params).subscribe({
      next(data: any[]) {
        $this.dataSource = data;
        $this.refreshCommissionTeam = false;
      },
      error(err: any){
        return;
      }
    });
  }


  closeModal(){  
    this.sendCloseModal.emit(false);
  }

  emitCommssionTeam() {
    this.sendCommission.emit(this.dataSource);
  }

  sessionExpanded(index: number) {
    this.dataSource[index].expanded = !this.dataSource[index].expanded ?? true;    
  }

  commissionGroup() {
    let newCommissionGroup = {
      commission_id: null,
      commission_title: 'new commission',
      commission_users: []
    };
    this.dataSource.push(newCommissionGroup);
  }

  changeCommission(row: number, user: any, selected: boolean) {
    if (selected) {
      const commission_user = {
        commission_id: this.dataSource[row].commission_id,
        email: user.email,
        gcs_path: user.image,
        name: user.name,
        user_id: user.id
      }
      this.dataSource[row].commission_users.push(commission_user);
    } else {
      let deleteItem: any[] = [];
      for (let i = 0; i < this.dataSource[row].commission_users.length; i++) {
        const element = this.dataSource[row].commission_users[i];
        if (user !== undefined) {
          if (element.user_id == user.id) {
            deleteItem.push(element.id);
            this.dataSource[row].commission_users.splice(i, 1);
          }
        }
      }
      this.dataSource[row].commission_users.sort();
      let $this = this;
      if (deleteItem.length > 0) {
        this.usersService.deleteCommissionUser(deleteItem)
        .subscribe({
          next(response) {              
            $this.refreshCommissionTeam = true;
          },
        });
      }
      
    }
  }

  isActived(row: number, user: any):boolean {
    this._isActive = false;
    for (let i = 0; i < this.dataSource[row].commission_users.length; i++) {
      const element = this.dataSource[row].commission_users[i];
      if (user !== undefined) {
        if (element.user_id == user.id) {
          this._isActive = true;
        }
      }
    }
    
    return this._isActive;
  }

  editingMode(index: number) {
    this.dataSource[index].editing = !this.dataSource[index].editing ?? true;   
    setTimeout(() => {
      document.getElementById('inputCommissionTitle_'+this.dataSource[index].commission_id)?.focus();  
    }, 500);
  }

  setTitle(index: number, key: any) {
    if (key.keyCode === 13)
    this.dataSource[index].editing = !this.dataSource[index].editing;
  }

  searchCommission() {
      this.refreshCommissionTeam = true;
  }

}
