<div class="list">
  <app-custom-dropdown
    [title]="i18n.disciplines_select"
    [marked]="data.correct && !viewOnly"
    [awaysOpen]="viewOnly"
  >
    <div class="list__alternatives">
      <div
        class="list__alternative text-customGray-dark"
        *ngFor="let alternative of data.options"
        [class.is-active]="alternative[optionKey]"
        [class.is-view-only]="viewOnly"
        (click)="toggleAlternative(alternative)"
        [ngClass]="{
          'is-correct': alternative.correction === 1,
          'is-wrong': alternative.correction === 0,
          'is-selected': alternative.selected
        }"
      >
        {{ alternative.text }}
      </div>
    </div>
  </app-custom-dropdown>
</div>
