import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountsService } from 'src/app/pages/accounts/accounts.service';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-modal-reset-password',
  templateUrl: './modal-reset-password.component.html',
  styleUrls: ['./modal-reset-password.component.scss']
})
export class ModalResetPasswordComponent implements OnInit, OnDestroy {

  isVisible: boolean = false;
  destroy$: any;
  resetForm: FormGroup;
  i18n: any = [];

  constructor(
    private accountsService: AccountsService,
    private formBuilder: FormBuilder,
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    this.getTranslations();
    this.createResetForm();
    this.destroy$ = this.accountsService.openModalResetPassword$
    .subscribe({
      next: (response)=>{
        console.log('response ', response);
        if (response === true) {
          this.isVisible = true;
        }
      }
    })
  }

  getTranslations() {
    this.i18n = {
      ...this.sharedService.getTranslationsOf('Errors'),
      ...this.sharedService.getTranslationsOf('Login'),
    };
  }

  createResetForm(){
    this.resetForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    })
  }

  get email() {
    return this.resetForm.get('email');
  }

  closeModal(){
    this.isVisible = false;
    this.accountsService.openModalResetPassword$.next(false);
  }
  
  resetPassword(){
    let e = this.resetForm.controls.email.value;
    localStorage.setItem('email', JSON.stringify(e))
    const params = {
      "email" : e
    }
    this.accountsService.resetPassword(params)
    .subscribe({
      next: (response)=>{
        this.closeModal();
        this.accountsService.openModalValidationCode$.next(true);
      },
      error: (err)=>{
        console.log(err);
      }
    })
  }

  ngOnDestroy() {
    if (this.destroy$) this.destroy$.unsubscribe();
  }
}
