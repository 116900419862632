<app-generic-modal [isVisible]="isVisibleModalSelectMethodologies" s>
  <div class="py-6 container-modal">
    <header class="text-center text-customBlue-default border-b border-customBlue-glass pb-4 px-6">
      <p class="font-bold text-26">Metodologias</p>
      <p>Selecione até 3 metodologias para o seu módulo</p>
    </header>
    <div class="p-6">
      <article
        class="text-customGray-default mb-8 flex items-center rounded-lg border bg-customWhite-default border-customGray-lighterGray w-full sm:w-auto"
      >
        <button class="cursor-pointer p-2" type="button">
          <span class="iconify text-24" data-icon="mingcute:search-line"></span>
        </button>
        <input
          class="px-4 py-2 rounded-lg w-full outline-none"
          type="text"
          name="search-skills"
          id="search-skills"
          autocomplete="off"
          placeholder="{{ i18n.planner_looking_for }}..."
          [(ngModel)]="searchQuery"
          (keyup)="getSearchMethodology(searchQuery)"
        />
        <label class="hidden" for="search-skills">{{ i18n.planner_looking_for_planners }}</label>
      </article>

      <!-- Grouped By methodologies -->
      <div>
        <div
          *ngIf="methodologies.length && !loading"
          class="methodologies flex flex-col gap-4 text-customGray-dark overflow-y-auto h-max-list pr-2"
        >
          <div
            *ngFor="let methodology of methodologies; let i = index"
            class="methodology border border-l-12 border-customGray-lighterGray rounded-md p-3"
          >
            <div
              id="methodology-title"
              class="flex flex-row justify-between"
              (click)="toggleExpandMethodology(i)"
            >
              <div class="flex flex-row gap-3">
                <div class="checkbox-methodology">
                  <label
                    class="container flex items-center gap-2 cursor-pointer"
                    (click)="$event.stopPropagation()"
                  >
                    <input
                      [(ngModel)]="methodology.isSelected"
                      class="hidden"
                      type="checkbox"
                      (click)="selectedMethodology(methodology, $event); $event.stopPropagation()"
                      [disabled]="selectedCount >= 3 && !methodology.isSelected"
                    />
                    <span class="check"></span>
                    <label></label>
                  </label>
                </div>
                <span
                  class="font-Montserrat font-semibold text-16 text-customBlue-royal cursor-pointer"
                >
                  {{ methodology?.name }}
                </span>
              </div>
              <div [ngClass]="{ 'rotate-180': isExpanded[i] }">
                <span
                  class="iconify"
                  data-icon="raphael:arrowdown"
                  style="color: #233674"
                  data-width="16"
                  data-height="16"
                ></span>
              </div>
            </div>
            <div class="flex flex-col gap-3 mt-3" [ngClass]="{ hidden: !isExpanded[i] }">
              <div
                *ngFor="let step of methodology.steps"
                class="border border-customGray-mediumGray rounded-md p-3"
              >
                <span class="text-15 text-customGray-dark">{{ step.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="loading" class="h-14">
        <app-bullets-loader></app-bullets-loader>
      </div>
    </div>
    <footer class="flex flex-col sm:flex-row justify-center gap-3 p-6 pb-0">
      <button
        type="button"
        (click)="closeModal()"
        class="flex items-center justify-center w-full sm:w-auto text-customBlue-default border border-customBlue-default bg-transparent py-1.5 px-10 rounded"
      >
        {{ i18n.planner_back }}
      </button>
      <button
        type="button"
        [disabled]="!methodologiesSelectedList.length"
        [ngClass]="{
          'cursor-not-allowed opacity-75': !methodologiesSelectedList.length
        }"
        (click)="onSubmit()"
        class="w-full sm:w-auto text-customWhite-default bg-customBlue-default py-1.5 px-10 rounded"
      >
        {{ i18n.planner_save }}
      </button>
    </footer>
  </div>
</app-generic-modal>
