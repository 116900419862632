import {
    Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output,
    Renderer2,
    SimpleChanges
} from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';

interface Item {
    id: number;
    name: string;
}

@Component({
    selector: 'app-multiple-choice-dropdown',
    templateUrl: './multiple-choice-dropdown.component.html',
    styleUrls: ['./multiple-choice-dropdown.component.scss']
})

export class MultipleChoiceDropdownComponent implements OnInit, OnChanges, OnDestroy {
    @Input() items: Item[] = [];
    @Input() selectedItems: Item[] = [];
    @Input() placeholderLabel = '';
    @Input() placeholderSelectedLabel = '';

    @Output() selectedItemsChange = new EventEmitter<Item[]>();

    isDropdownOpen = false;
    searchText = '';
    filteredItems: Item[] = [];
    i18n;
    private clickListener!: () => void;

    constructor(
        private elRef: ElementRef,
        private renderer: Renderer2,
        private sharedService: SharedService
    ) {}

    ngOnInit(): void {
        this.selectedItems = this.selectedItems || [];
        this.getTranslations();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes) {
            if (this.items && this.items.length) {
                this.onSearch();
            }

            console.log('placeholderLabel ', this.placeholderLabel);
            console.log('selectedTemplates ', this.selectedItems);

            if (this.placeholderLabel && this.placeholderLabel === '') {
                this.placeholderLabel = this.i18n.cms_select;
            }
        }
    }

    getTranslations() {
        this.i18n = {
            ...this.sharedService.getTranslationsOf('Upload'),
            ...this.sharedService.getTranslationsOf('CMS')
        };
    }

    toggleSelection(item: Item) {
        const index = this.selectedItems.findIndex((selected) => selected.id === item.id);

        if (index > -1) {
            this.selectedItems.splice(index, 1);
        } else {
            this.selectedItems.unshift(item);
        }

        this.selectedItemsChange.emit([...this.selectedItems]);
    }

    isSelected(item: Item): boolean {
        return this.selectedItems.some((selected) => selected.id === item.id);
    }

    toggleDropdown() {
        this.isDropdownOpen = !this.isDropdownOpen;

        if (this.isDropdownOpen) {
            this.clickListener = this.renderer.listen('document', 'click', (event: Event) => {
                if (!this.elRef.nativeElement.contains(event.target)) {
                    this.isDropdownOpen = false;
                }
            });
        } else {
            this.removeClickListener();
        }
    }

    onSearch() {
        this.filteredItems = this.items.filter((item) => item.name.toLowerCase().includes(this.searchText.toLowerCase()));
    }

    toggleSelectAll() {
        if (this.areAllSelected()) {
            this.selectedItems = [];
        } else {
            this.selectedItems = [...this.items];
        }
        this.selectedItemsChange.emit([...this.selectedItems]);
    }

    areAllSelected(): boolean {
        return this.filteredItems.every((item) => this.isSelected(item));
    }

    ngOnDestroy() {
        this.removeClickListener();
    }

    private removeClickListener() {
        if (this.clickListener) {
            this.clickListener();
        }
    }
}
