import {
    AfterContentInit, Component, EventEmitter, Input, Output
} from '@angular/core';

@Component({
    selector: 'app-check-button',
    templateUrl: './check-button.component.html',
    styleUrls: ['./check-button.component.scss']
})
export class CheckButtonComponent implements AfterContentInit {
    @Input() active;
    @Input() label = '';

    @Input() iconSize = 15;
    @Input() roundedValue = 5;

    @Output() isActive = new EventEmitter();

    addStyle = '';

    ngAfterContentInit(): void {
    // Tamanho do check dentro da caixa do input
        let checkSize = 10;
        if (this.iconSize < 20) {
            checkSize = this.iconSize / 2;
        }
        this.addStyle = `--width: ${this.iconSize}px; --height: ${this.iconSize}px; --roundValue: ${this.roundedValue}px; --checkSize: ${checkSize}px`;
    }

    check() {
        this.active = !this.active;
        this.isActive.emit(this.active);
    }
}
