import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CarouselService {
    updatedCarouselWidthContainer$ = new BehaviorSubject<boolean>(false);

    shouldUpdateCarouselWidthContainer(value: boolean): void {
        this.updatedCarouselWidthContainer$.next(value);
    }
}
