import {Injectable, OnDestroy} from '@angular/core';
import { BehaviorSubject, defer, from, Observable, of } from 'rxjs';
import { AccountsService } from '../pages/accounts/accounts.service';

declare const gapi: any;
declare const google: any;

@Injectable({
  providedIn: 'root'
})
export class GoogleGetFileService implements OnDestroy{
  public isGapiLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false); 
  private scope = 'https://www.googleapis.com/auth/drive.readonly'; 
  public clientId = '';
  public apiKey = '';
  public appId = '';
  public backendAccount = '';
  private haveClientAndAuth: boolean = false;
  private tokenClient: any;

  updatedTokenPicker: string = '';

 
  constructor (
    private accountsService: AccountsService,

  ) {
    this.clientId = JSON.parse(localStorage.getItem('clientId') || 'null'); 
    this.apiKey = JSON.parse(localStorage.getItem('apiKey') || 'null');
    this.appId = JSON.parse(localStorage.getItem('appId') || 'null');
    this.backendAccount = JSON.parse(localStorage.getItem('mail') || 'null');
  }

  ngOnDestroy(): void {
    console.log('DESTROY');
    
    if (this.isGapiLoaded$) this.isGapiLoaded$.unsubscribe();
  }

  get isGapiLoaded() {
    return this.isGapiLoaded$.asObservable();
  }

  public initGoogleDriveGapi(){
    this.getGoogleAuthTokenPicker();
    this.onAuthApiLoad();
    gapi.load("client");
    
    // Validation 
    // if (gapi?.client?.drive?.files.length > 0 ) return;
  }

  getGoogleAuthTokenPicker(){
    if (localStorage.getItem('googleAuthTokenPicker') !== null) {
      let googleAuthTokenPicker = JSON.parse(localStorage.getItem('googleAuthTokenPicker') || '');
      if (googleAuthTokenPicker !== null && googleAuthTokenPicker !== undefined && googleAuthTokenPicker !== '') {
        this.checkTokenValid(googleAuthTokenPicker);
      }
    }else{
      this.authRefreshToken();
    }
  }

  authRefreshToken(){
		this.accountsService.refreshTokenGoogle().subscribe({
			next:(response)=>{
        if (response.hasOwnProperty('token_picker')) {
          localStorage.setItem('googleAuthTokenPicker', JSON.stringify(response.token_picker));
          this.getRequestCode();
        }
			},
			error:(err)=>{
        console.log('CALLBACK HAS ERROR authRefreshToken' );
        // this.callbackReponse$.next(err);
			}
		})
	}

  checkTokenValid(token){
    this.accountsService.tokenInfoGoogle(token).subscribe({
      next:(tokenInfo)=>{
        console.log('checkTokenValid tokenInfo', tokenInfo);
        if (this.checkScopeDrive(tokenInfo)) {
          this.gapiLoad(token);
        }else{
          this.authRefreshToken();
        }
        
        // this.getTokenToBuildPicker(token);
      },
      error:(err)=>{
        console.log('checkTokenValid ERRO ', err);
        this.authRefreshToken();
        
      }
    })
  }

  getRequestCode(){ 
    this.tokenClient.requestCode();
  }

  checkScopeDrive(tokenInfo){
    
    if (tokenInfo.scope.includes('drive.readonly')) {
      return true;
    }

    return false;
  }


  // Adiciona scopo do drive para o usuário google do cliente
  private onAuthApiLoad(){
    this.tokenClient = google.accounts.oauth2.initCodeClient({
      client_id: this.clientId,
      scope: this.scope,
      'immediate': false,
    // prompt: 'consent',
      callback: (response)=>{
        console.log('response ', response);
        if (response.hasOwnProperty('error')) {
          // this.callbackReponse$.next(response);
        } else {
          // Transforma o novo código gerado depois da permissões em um token válido
          this.accountsService.loginWithGoogle({code: response.code}).subscribe({
            next: (res) => {
              this.accountsService.setGoogleCredencials(res); // Atualiza (googleAuthTokenPicker,googleAuthToken) localStorega 
              this.gapiLoad(res.token_picker);
            },
            error:(err)=>{
              if (err.status == 401) {
                response = {"error": {"errCode":400,"error":"invalid_grant"}}
                // this.callbackReponse$.next(response);
              }
            }
          })
        }
  
      },
    });
    // gapi.auth.authorize({
    //       'client_id': this.clientId,
    //       'scope': this.scope,
    //       'immediate': false,
    //     }, async () => {
    //       // load client updated 
    //       await this.loadClientDrive()
    //     });
    // google.accounts.oauth2.initCodeClient({
    //   client_id: this.clientId,
    //   scope: this.scope,
    //   prompt: 'consent',
    //   callback: (response)=>{
    //     console.log('response ', response);
    //     if (response.hasOwnProperty('error')) {
    //       // this.callbackReponse$.next(response);
    //     } else {
    //       this.loadClientDrive()
    //     }
  
    //   }
    // });
  }

  gapiLoad(token) {
    this.updatedTokenPicker = token;
    if (gapi.hasOwnProperty('client')) {
      gapi.client.setToken({access_token: token}); // Atualiza o novo token (permissions)
      gapi.load('client:picker', this.loadClientDrive.bind(this));
    }else{
      gapi.load("client");
      setTimeout(() => {
        this.gapiLoad(token);
      }, 500);
    }
  }

  private loadClientDrive() {
    console.log('loadClientDrive ');
    
    return gapi.client.load("https://content.googleapis.com/discovery/v1/apis/drive/v3/rest")
      .then(()=> { 
        console.log("GAPI client loaded for API"); 
        this.isGapiLoaded$.next(true);  
      },
      (err)=> { 
        this.isGapiLoaded$.next(false);  
        console.error("Error loading GAPI client for API", err); 
      });
  }


  // private onAuthApiLoad(): void {
  //   gapi.auth.authorize({
  //     'client_id': this.clientId,
  //     'scope': this.scope,
  //     'immediate': false,
  //   }, () => {
  //     // load client updated 
  //     gapi.load("client", {'callback': this.loadedClient(true)});
  //     this.handleAuthResult.bind(this);
  //   });
  // }

  // private loadedClient(value: boolean): void {
  //   this.haveClientAndAuth = value;
  // }

  // private handleAuthResult(authResult): void {
  //   if (!authResult && authResult.error) {
  //     this.isGapiLoaded$.next(false);  
  //   }
  // }

  async downloadFile2(fileId: any){
    console.log('fileId ', fileId);
    
    let teste = await gapi.client.drive.files.get({
      fileId: fileId,
      fields: 'exportLinks,webContentLink,id,name'
    });

    return teste;

    return defer(() => gapi.client.drive.files.get({
      fileId: fileId,
      fields: 'exportLinks,webContentLink,id,name'
    }).then(async (file: any) => {
      return await file;
    }).catch(async (err: any) => {
      return await err;
    }));
  }


  downloadFile(fileId: any){
    console.log('fileId ', fileId);
    
    return defer(() => gapi.client.drive.files.get({
      fileId: fileId,
      fields: 'exportLinks,webContentLink,id,name'
    }).then((file: any) => {
      return file;
    }).catch((err: any) => {
      return err;
    }));
  }
}
