import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TooltipModule } from 'primeng/tooltip';
import { DisplayPanelModule } from 'src/app/components/display-panel/display-panel.module';
import { GenericModalModule } from 'src/app/components/modals/generic-modal/generic-modal.module';
import { SharedModule } from 'src/app/shared/shared.module';

import { DropdownsModule } from '../dropdowns/dropdowns.module';

import { DuplicateDisciplineComponent } from './duplicate-discipline/duplicate-discipline.component';
import { ExportContentsModalComponent } from './export-contents-modal/export-contents-modal.component';
import { ImportClassroomModalComponent } from './import-classroom-modal/import-classroom-modal.component';
import { ImportModalComponent } from './import-modal/import-modal.component';
import { PreRegisteredDatesModalComponent } from './pre-registered-dates-modal/pre-registered-dates-modal.component';
import { SelectDisciplineBoxComponent } from './select-discipline-box/select-discipline-box.component';
import { SelectDisciplineClassroomBoxComponent } from './select-discipline-classroom-box/select-discipline-classroom-box.component';
import { SelectOptionBoxComponent } from './select-option-box/select-option-box.component';

@NgModule({
      declarations: [
        ImportModalComponent,
        SelectOptionBoxComponent,
        SelectDisciplineBoxComponent,
        ImportClassroomModalComponent,
        SelectDisciplineClassroomBoxComponent,
        PreRegisteredDatesModalComponent,
        ExportContentsModalComponent,
        DuplicateDisciplineComponent,
      ],
      imports: [
        CommonModule,
        SharedModule,
        DisplayPanelModule,
        GenericModalModule,
        DropdownsModule,
        TooltipModule
    ],
      exports: [
        ImportModalComponent,
        SelectOptionBoxComponent,
        SelectDisciplineBoxComponent,
        ImportClassroomModalComponent,
        SelectDisciplineClassroomBoxComponent,
        PreRegisteredDatesModalComponent,
        ExportContentsModalComponent,
        DuplicateDisciplineComponent
      ],
})
export class CopyAndReplicationModule { }
