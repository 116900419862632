import {
    Component, EventEmitter, Input, OnChanges, Output, ViewChild
} from '@angular/core';
import { PaginationComponent } from 'src/app/components/pagination/pagination.component';
import { Skill } from 'src/app/pages/content-planner/interfaces/curricular-parameters.interface';
import { ContentPlannerService } from 'src/app/pages/content-planner/services/content-planner.service';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
    selector: 'app-modal-select-skills',
    templateUrl: './modal-select-skills.component.html',
    styleUrls: ['./modal-select-skills.component.scss']
})
export class ModalSelectSkillsComponent implements OnChanges {
    @ViewChild(PaginationComponent) pagination: PaginationComponent | any;
    @Output() closeModalSelectSkills = new EventEmitter();
    @Output() sendSelectedGuidelines = new EventEmitter();
    @Output() submitSelectedSkills = new EventEmitter();
    @Input() isVisibleModalSelectSkills = false;
    @Input() createdModule = false;
    @Input() editModeSkills = false;
    @Input() i18n: any = {};
    @Input() skillsSelectedList: Skill[] = [];
    skills: Skill[] = [];
    filteredSkills: Skill[] = [];
    isSelected = false;
    isAllSelected = false;
    loading = false;
    page = 1;
    per_page = 12;
    searchQuery = '';
    searchQueryByComponent = '';
    guidelines: any = [];
    isExpanded: boolean[] = [];
    isExpandedSegment: boolean[] = [];
    isExpandedComponent: boolean[] = [];
    componentsList: any = [];
    skillsList: any = [];
    tempSkillsList: any = [];

    paginationParams: any = {};

    constructor(private contentPlannerService: ContentPlannerService, private sharedService: SharedService) { }

    ngOnChanges(): void {
        if (this.isVisibleModalSelectSkills) {
            this.resetModalData();
            this.guidelinesIsChecked();
            this.getGuidelines();
        }
    }

    getGuidelines(): void {
        this.loading = true;

        this.contentPlannerService.getGuidelines().subscribe({
            next: (response) => {
                this.guidelines = response;
                this.loading = false;
            },
            error: (error) => {
                this.loading = false;
            }
        });
    }

    resetSearchComponent(): void {
        this.searchQueryByComponent = '';
        // this.tempSkillsList = [];
    }

    toggleExpand(index: number) {
        const tempState = this.isExpanded[index];
        this.isExpanded.fill(false);
        this.isExpandedSegment.fill(false);
        this.isExpandedComponent.fill(false);
        this.isExpanded[index] = !tempState;

        this.resetSearchComponent();
    }

    toggleExpandSegment(index: number, guidelineId: number, segmentId: number) {
        const tempState = this.isExpandedSegment[index];
        this.isExpandedSegment.fill(false);
        this.isExpandedSegment[index] = !tempState;
        this.getComponents(guidelineId, segmentId, index, 'component');
        this.resetSearchComponent();
    }

    toggleExpandComponent(index: number, guidelineId: number, segmentId: number) {
        const tempState = this.isExpandedComponent[index];
        this.isExpandedComponent.fill(false);
        this.isExpandedComponent[index] = !tempState;
        this.getSkillsList(guidelineId, segmentId, index, 'component');

        this.resetSearchComponent();
    }

    getComponents(guidelineId: number, segmentId: number, index: number, column: string) {
        this.contentPlannerService.getSkillValues(guidelineId, segmentId, column, 0).subscribe({
            next: (response) => {
                this.componentsList[index] = response;
            },
            error: () => {
                console.log('error');
            }
        });
    }

    getSkillsList(guidelineId: number, segmentId: number, index: number, column: string) {
        this.contentPlannerService.getSkillValues(guidelineId, segmentId, column, 1).subscribe({
            next: (response) => {
                this.skillsList[index] = response;
                this.guidelinesIsCheckedByComponent(index);
            },
            error: () => {
                console.log('error');
            }
        });
    }

    getSkills(query: string, page: number, per_page): void {
        this.loading = true;
        this.contentPlannerService.getSkills(query, page, per_page).subscribe({
            next: (response) => {
                if (this.createdModule && !this.editModeSkills) {
                    this.resetModalData();
                }

                this.skills = response.skills;

                this.guidelinesIsChecked();

                this.filteredSkills = this.skills;

                if (this.filteredSkills.length) {
                    this.refreshPagination(response);
                }

                this.loading = false;
            },
            error: () => {
                this.skills = [];
                this.filteredSkills = [];
            }
        });
    }

    async getSearchSkills(query: string) {
        const search = await this.sharedService.debounce(query);

        if (!query) {
            return;
        }

        if (typeof search === 'string') {
            this.searchQuery = search;
            this.page = 1;
            this.getSkills(this.searchQuery, this.page, this.per_page);
        }
    }

    async getSearchSkillsByComponent(index: number, guidelineId: number, componentId: number, query: string) {
        const search = await this.sharedService.debounce(query);

        if (this.tempSkillsList.length === 0) {
            this.tempSkillsList = this.skillsList[index];
        }

        if (search && typeof search === 'string') {
            this.skillsList[index] = this.tempSkillsList.filter((e: any) => e.code.toLowerCase().includes(search.toLowerCase())
                || e.description.toLowerCase().includes(search.toLowerCase()));
        } else {
            this.skillsList[index] = this.tempSkillsList;
            this.tempSkillsList = [];
        }
    }

    setIsSelectedDefault(): void {
        this.skills.map((e) => {
            e.isSelected = this.isSelected;
            return null;
        });
    }

    setAllSelected(): void {
        this.skills.forEach((guideline, i) => {
            this.skills[i].isSelected = this.isAllSelected;
            this.setSelected(guideline, guideline.isSelected);
        });
    }

    setSelected(guideline: Skill, boolean: boolean): void {
        const index = this.skillsSelectedList.findIndex((savedGuideline) => savedGuideline.id === guideline.id);

        if (boolean && index === -1) {
            this.skillsSelectedList.push(guideline);
        } else if (!boolean && index !== -1) {
            this.skillsSelectedList.splice(index, 1);
        } else if (boolean && index !== -1) {
            this.skillsSelectedList[index] = guideline;
        }
    }

    guidelinesIsChecked(): void {
        if (this.skillsSelectedList.length) {
            this.skillsSelectedList.forEach((element) => {
                this.skills.filter((e, i) => {
                    if (element.id === e.id) {
                        this.skills[i].isSelected = true;
                    }
                    return null;
                });
            });
        }

        this.everyChecked();
    }

    guidelinesIsCheckedByComponent(index): void {
        if (this.skillsSelectedList.length) {
            this.skillsSelectedList.forEach((element) => {
                this.skillsList[index].filter((e, i) => {
                    if (element.id === e.id) {
                        this.skillsList[index][i].isSelected = true;
                    }
                    return null;
                });
            });
        }

        this.everyCheckedByComponent();
    }

    everyChecked(): void {
        if (this.skillsSelectedList.length) {
            this.isAllSelected = this.skills.every((e) => e.isSelected === true);
        }
    }

    everyCheckedByComponent(): void {
        if (this.skillsSelectedList.length) {
            this.isAllSelected = this.skillsList.every((e) => e.isSelected === true);
        }
    }

    resetModalData(): void {
        this.isSelected = false;
        this.isAllSelected = false;
        // this.setIsSelectedDefault();
        // this.skillsSelectedList = [];
        this.isExpanded.fill(false);
        this.isExpandedSegment.fill(false);
        this.isExpandedComponent.fill(false);
    }

    closeModal(): void {
        this.resetModal();
        this.closeModalSelectSkills.emit(false);
    }

    resetModal(): void {
        this.searchQuery = '';
    }

    emitGuidelines(): void {
        this.sendSelectedGuidelines.emit(this.skillsSelectedList);
    }

    onSubmitSelectedSkils(): void {
        this.submitSelectedSkills.emit(this.skillsSelectedList);
    }

    onSubmit(): void {
        if (this.skillsSelectedList.length) {
            this.onSubmitSelectedSkils();
            this.closeModal();
        }
    }

    goToPage(event): void {
        const params = { page: event.activePage, per_page: this.per_page };
        const query: string = this.searchQuery;

        this.contentPlannerService.getSkills(query, params.page, params.per_page).subscribe({
            next: (response) => {
                this.page = response.currentPage;
                this.skills = response.skills;
                this.guidelinesIsChecked();
                this.filteredSkills = this.skills;
                this.loading = false;
            },
            error: () => {
                this.skills = [];
                this.filteredSkills = [];
            }
        });
    }

    refreshPagination(response): void {
        this.paginationParams = {
            items: [...Array(response.lastPage).keys()],
            itemsPerPage: this.per_page,
            visibleButtons: 5,
            activePage: this.page
        };
        if (this.pagination) {
            this.pagination.params = this.paginationParams;
            this.pagination.initPaginationLib(true);
        }
    }
}
