<!-- Body -->
<div class="mx-8 my-6">
    <!-- Blue warning pre registered dates message -->
    <div
        class="flex justify-center items-center gap-2 p-4 bg-customBlue-glass border border-customBlue-royal rounded-md leading-4"
    >
        <div class="">
            <span
                class="iconify w-5 h-5 text-customBlue-royal"
                data-icon="zondicons:exclamation-solid"
            ></span>
        </div>

        <div 
            class="text-16 font-semibold text-customBlue-royal"
        >
            {{ getAlertMessage() }}
        </div>
    </div>

    <!-- Message proceed pre registered dates -->
    <div class="my-4">
        <div class="text-16 font-medium text-customBlue-default leading-19">
            {{
                translationValues.message
            }}
        </div>
    </div>

    <!-- Choose check boxes -->
    <div class="flex flex-col gap-4">
        <!-- Keep current dates container -->
        <div
            class="check-box-container"
            [ngClass]="{
                'border border-customBlue-default': keepingCurrentDates,
                'border border-transparent': !keepingCurrentDates
            }"
        >
            <!-- Keep current checkBox -->
            <div class="flex items-center">
                <div #checkButton>
                    <app-check-button
                        (isActive)="changeValueKeepingCurrentDates($event)"
                        [iconSize]="21"
                        [roundedValue]="5"
                        [active]="keepingCurrentDates"
                    ></app-check-button>
                </div>
                <div>
                    <div class="text-title">
                        {{ i18n.disciplines_pre_registered_keep_dates_label }}
                    </div>
                    <div class="text-14 text-customGray-dark">
                        {{
                            translationValues.keep_dates
                        }}
                    </div>
                </div>
            </div>

            <!-- Dates for resource types checkBoxes -->
            <div 
                #containerReplicateDateType 
                class="flex flex-col gap-2 mt-2"
            >
                <div 
                    *ngIf="showResorucesDatesCheckboxes?.date_resource_posting"
                    class="flex"
                >
                    <app-check-button
                        (change)="
                            changeValueDateResourceTypes(
                                'date_resource_posting'
                            )
                        "
                        [iconSize]="16"
                        [active]="datesResourcesValues.date_resource_posting"
                        [roundedValue]="5"
                    ></app-check-button>

                    <div class="text-label">
                        {{ i18n.disciplines_pre_registered_date_resource_posting}}
                    </div>
                </div>

                <div 
                    *ngIf="showResorucesDatesCheckboxes?.date_task_posting"
                    class="flex"
                >
                    <app-check-button
                        (change)="
                            changeValueDateResourceTypes(
                                'date_task_posting'
                            )
                        "
                        [iconSize]="16"
                        [active]="datesResourcesValues.date_task_posting"
                        [roundedValue]="5"
                    ></app-check-button>

                    <div class="text-label">
                        {{ i18n.disciplines_pre_registered_date_task_posting }}
                    </div>
                </div>

                <div 
                    *ngIf="showResorucesDatesCheckboxes?.date_delivery"
                    class="flex"
                >
                    <app-check-button
                        (change)="
                            changeValueDateResourceTypes('date_delivery')
                        "
                        [iconSize]="16"
                        [active]="datesResourcesValues.date_delivery"
                        [roundedValue]="5"
                    ></app-check-button>

                    <div class="text-label">
                        {{ i18n.disciplines_pre_registered_date_delivery }}
                    </div>
                </div>

                <div 
                    *ngIf="showResorucesDatesCheckboxes?.date_start_videoconference"
                    class="flex"
                >
                    <app-check-button
                        (change)="
                            changeValueDateResourceTypes(
                                'date_start_videoconference'
                            )
                        "
                        [iconSize]="16"
                        [active]="
                            datesResourcesValues.date_start_videoconference
                        "
                        [roundedValue]="5"
                    ></app-check-button>

                    <div class="text-label">
                        {{ i18n.disciplines_pre_registered_date_start_videoconference }}
                    </div>
                </div>

                <div 
                    *ngIf="showResorucesDatesCheckboxes?.date_section_display"
                    class="flex"
                >
                    <app-check-button
                        (change)="
                            changeValueDateResourceTypes(
                                'date_section_display'
                            )
                        "
                        [iconSize]="16"
                        [active]="datesResourcesValues.date_section_display"
                        [roundedValue]="5"
                    ></app-check-button>

                    <div class="text-label">
                        {{ i18n.disciplines_pre_registered_date_section_display }}
                    </div>
                </div>
            </div>
        </div>

        <!-- Import with no dates container -->
        <div
            *ngIf="!showResorucesDatesCheckboxes?.only_videoconference_ontime"
            class="check-box-container"
            [ngClass]="{
                'border border-customBlue-default': noDates,
                'border border-transparent': !noDates
            }"
        >
            <!-- Import with no dates checkbox -->
            <div class="flex items-center">
                <app-check-button
                    (isActive)="changeValueNoDates($event)"
                    [iconSize]="21"
                    [active]="noDates"
                    [roundedValue]="5"
                ></app-check-button>
                <div>
                    <div class="text-title">
                        {{ translationValues.no_keep_dates_label }}
                    </div>
                    <div class="text-14 text-customGray-dark">
                        {{
                            translationValues.no_keep_dates
                        }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Footer -->
<div class="sm:flex-row flex-col flex items-center justify-center pb-6 px-6 gap-3" >
    <button
        class="border border-customBlue-default rounded-md text-customWhite-default bg-customBlue-default font-semibold px-6 py-2 text-16 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
        style="min-width: 152px;"
        type="button"
        [disabled]="showResorucesDatesCheckboxes?.only_videoconference_ontime ? !hasAnyDateResourcesValueTrue() : !hasAnyDateResourcesValueTrue() && !noDates"
        [ngClass]="{'disabled:opacity-20 cursor-not-allowed': showResorucesDatesCheckboxes?.only_videoconference_ontime ? !hasAnyDateResourcesValueTrue() : !hasAnyDateResourcesValueTrue() && !noDates}"
        (click)="save()"
    >
        {{ i18n.disciplines_save }}
    </button>
    <button
        class="border text-customBlue-default border-customBlue-default rounded-md bg-white font-semibold px-6 py-2 text-16 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
        style="min-width: 152px;"
        type="button"
        (click)="closeModal()"
    >
        {{ i18n.disciplines_cancel }}
    </button>
</div>

