<section class="flex flex-col gap-4" #firstDomElement>
  <!-- Header  -->
  <header class="flex flex-col" *ngIf="!planning">
    <h1 class="text-customBlue-default font-bold text-22">
      {{ i18n.planner_my_planning }}
    </h1>
    <a
      class="bg-transparent text-customGray-default py-1 flex items-center gap-1"
      [routerLink]="['/class-planner']"
    >
      <span class="iconify text-18" data-icon="ion:chevron-back"></span>
      <span>{{ i18n.planner_go_back_planning }}</span>
    </a>
  </header>

  <article
    class="bg-customWhite-default p-4 rounded-2xl border border-dashed border-customGray-mediumGray text-customBlue-default"
  >
    <!-- Loading  -->
    <app-bullets-loader *ngIf="loading"></app-bullets-loader>

    <div *ngIf="!loading">
      <header class="flex flex-col md:flex-row md:items-center gap-4 justify-between mb-6">
        <div class="flex flex-col md:flex-row md:items-center gap-4">
          <div class="bg-customBlue-light p-3 w-max rounded-full">
            <span class="iconify text-24" data-icon="tabler:school"></span>
          </div>

          <span class="text-18">
            <p>{{ detailedView.segment_name }}</p>
            <p>
              {{ detailedView.degree_name }} - {{ detailedView.area_name }} -
              {{ detailedView.component_name }}
            </p>
          </span>
        </div>

        <div>
          <!-- Search and chat  -->
          <!-- <div class="flex items-center rounded-lg border bg-customWhite-default border-customWhite-bWhite w-full sm:w-auto text-customGray-default">
                        <button class="cursor-pointer p-2" type="button">
                            <span class="iconify text-24" data-icon="mingcute:search-line"></span>
                        </button>
                        <label class="hidden" for="search-modules">{{ i18n.planner_looking_for }}...</label>
                        <input
                          class="pr-2 py-2 rounded-lg w-full outline-none"
                          type="text"
                          name="search-modules"
                          id="search-modules"
                          placeholder="{{ i18n.planner_looking_for }}..."
                          autocomplete="off"
                          [(ngModel)]="search_modules_filter"
                          />
                    </div> -->
        </div>
      </header>
      <!-- Modules  -->
      <ul class="flex flex-col gap-4">
        <li *ngFor="let module of filteredModules; let moduleIndex = index">
          <button
            type="button"
            class="flex flex-col sm:flex-row items-center justify-between gap-2 p-3 rounded-t w-full bg-customBlue-light"
            (click)="toggleModule(moduleIndex)"
          >
            <span class="flex flex-col sm:flex-row items-center gap-1">
              <span class="iconify w-10" data-icon="bi:file-earmark-text"></span>
              <p class="font-bold text-left">{{ module.name }}</p>
            </span>

            <div
              class="transition-all"
              [ngClass]="{
                'transform rotate-180': module.control
              }"
            >
              <span
                class="iconify text-customBlue-royal w-7 h-7"
                data-icon="iconamoon:arrow-down-2-fill"
              ></span>
            </div>
          </button>

          <div class="overflow-hidden">
            <div [@collapse]="module.control" class="border border-t-0 border-customBlue-light">
              <!-- Skills  -->
              <div class="flex flex-col gap-3 p-5 border-b border-customBlue-light">
                <p class="font-bold">
                  {{ i18n.planner_skills_worked }}
                </p>

                <ul class="flex flex-col gap-3">
                  <li
                    *ngFor="let skill of module.skills; let i = index"
                    class="border border-customGray-mediumGray p-2 rounded flex flex-row gap-2 items-center cursor-pointer"
                    (click)="toggleDescription(i)"
                  >
                    <span
                      class="border border-customBlue-default rounded max-w-max h-7 flex text-center items-center px-3"
                    >
                      {{ skill.code }}
                    </span>
                    <span
                      class="p-2 text-customGray-dark"
                      [ngClass]="{
                        truncate: !expandedItems[i]
                      }"
                    >
                      {{ skill.description }}
                    </span>
                  </li>
                  <!-- Empty skills  -->
                  <li *ngIf="!module.skills.length">
                    <p class="text-customGray-darker">
                      {{ i18n.planner_there_no }}
                    </p>
                  </li>
                </ul>
              </div>

              <div class="flex flex-col gap-3 p-5 border-b border-customBlue-light">
                <p class="font-bold">Metodologias</p>

                <ul class="flex flex-col gap-3">
                  <li
                    *ngFor="let methodology of module.methodologies; let i = index"
                    class="border border-customGray-mediumGray p-2 rounded flex flex-row gap-2 items-center cursor-pointer"
                    (click)="toggleDescription(i)"
                  >
                    <span
                      class="p-2 text-customGray-dark"
                      [ngClass]="{
                        truncate: !expandedItems[i]
                      }"
                    >
                      {{ methodology.name }}
                    </span>
                  </li>
                  <!-- Empty skills  -->
                  <li *ngIf="!module.methodologies.length">
                    <p class="text-customGray-darker">Não há</p>
                  </li>
                </ul>
              </div>

              <div
                *ngIf="module.has_fields_detail"
                class="flex flex-col gap-3 p-5 border-b border-customBlue-light"
              >
                <!-- knowledge_objects -->
                <div *ngIf="module.details.knowledge_objects.length">
                  <p class="font-bold mb-3">
                    {{ i18n.planner_knowledge_objects }}
                  </p>

                  <ul class="flex flex-col gap-3 text-customGray-dark">
                    <li
                      *ngFor="let knowledge_objects of module.details.knowledge_objects"
                      class="border border-customGray-mediumGray rounded py-2 px-4"
                    >
                      <p>{{ knowledge_objects.value }}</p>
                    </li>
                  </ul>
                </div>

                <!-- experiences_field -->
                <div *ngIf="module.details.experiences_field.length">
                  <p class="font-bold mb-3">
                    {{ i18n.planner_experience_fields }}
                  </p>

                  <ul class="flex flex-col gap-3 text-customGray-dark">
                    <li
                      *ngFor="let experiences_field of module.details.experiences_field"
                      class="border border-customGray-mediumGray rounded py-2 px-4"
                    >
                      <p>{{ experiences_field.value }}</p>
                    </li>
                  </ul>
                </div>

                <!-- thematic_unit -->
                <div *ngIf="module.details.thematic_unit.length">
                  <p class="font-bold mb-3">
                    {{ i18n.planner_thematic_unit }}
                  </p>

                  <ul class="flex flex-col gap-3 text-customGray-dark">
                    <li
                      *ngFor="let thematic_unit of module.details.thematic_unit"
                      class="border border-customGray-mediumGray rounded py-2 px-4"
                    >
                      <p>{{ thematic_unit.value }}</p>
                    </li>
                  </ul>
                </div>

                <!-- action_field -->
                <div *ngIf="module.details.action_field.length">
                  <p class="font-bold mb-3">
                    {{ i18n.planner_action_fields }}
                  </p>

                  <ul class="flex flex-col gap-3 text-customGray-dark">
                    <li
                      *ngFor="let action_field of module.details.action_field"
                      class="border border-customGray-mediumGray rounded py-2 px-4"
                    >
                      <p>{{ action_field.value }}</p>
                    </li>
                  </ul>
                </div>

                <!-- language_axis -->
                <div *ngIf="module.details.language_axis.length">
                  <p class="font-bold mb-3">
                    {{ i18n.planner_language_axes }}
                  </p>

                  <ul class="flex flex-col gap-3 text-customGray-dark">
                    <li
                      *ngFor="let language_axis of module.details.language_axis"
                      class="border border-customGray-mediumGray rounded py-2 px-4"
                    >
                      <p>{{ language_axis.value }}</p>
                    </li>
                  </ul>
                </div>

                <!-- support_genres -->
                <div *ngIf="module.details.support_genres.length">
                  <p class="font-bold mb-3">
                    {{ i18n.planner_support_genres }}
                  </p>

                  <ul class="flex flex-col gap-3 text-customGray-dark">
                    <li
                      *ngFor="let support_genres of module.details.support_genres"
                      class="border border-customGray-mediumGray rounded py-2 px-4"
                    >
                      <p>{{ support_genres.value }}</p>
                    </li>
                  </ul>
                </div>

                <!-- specialization_genres -->
                <div *ngIf="module.details.specialization_genres.length">
                  <p class="font-bold mb-3">
                    {{ i18n.planner_in_depth_genres }}
                  </p>

                  <ul class="flex flex-col gap-3 text-customGray-dark">
                    <li
                      *ngFor="let specialization_genres of module.details.specialization_genres"
                      class="border border-customGray-mediumGray rounded py-2 px-4"
                    >
                      <p>
                        {{ specialization_genres.value }}
                      </p>
                    </li>
                  </ul>
                </div>

                <!-- cross_cutting_themes -->
                <div *ngIf="module.details.cross_cutting_themes.length">
                  <p class="font-bold mb-3">
                    {{ i18n.planner_cross_cutting_themes }}
                  </p>

                  <ul class="flex flex-col gap-3 text-customGray-dark">
                    <li
                      *ngFor="let cross_cutting_themes of module.details.cross_cutting_themes"
                      class="border border-customGray-mediumGray rounded py-2 px-4"
                    >
                      <p>
                        {{ cross_cutting_themes.value }}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <!-- Procedure -->
              <!--
                            <div
                                class="flex flex-col gap-3 p-5 border-b border-customBlue-light"
                            >
                                <p class="font-bold">
                                    {{ i18n.planner_procedures }}
                                </p>
                                <ul
                                    class="flex flex-col gap-3 text-customGray-dark"
                                >
                                    <li
                                        *ngFor="
                                            let procedure of module.procedure
                                        "
                                        class="border border-customGray-mediumGray rounded py-2 px-4"
                                    >
                                        <p>{{ procedure.value }}</p>
                                    </li>
                                    <li *ngIf="!module.procedure.length">
                                        <p class="text-customGray-darker">
                                            {{ i18n.planner_there_no }}
                                        </p>
                                    </li>
                                </ul>
                            </div>
                            -->

              <div class="flex flex-col gap-3 p-5">
                <p class="font-bold">
                  {{ i18n.planner_methodologies_and_resources }}
                </p>
                <ul class="flex flex-col gap-3 text-customGray-dark">
                  <li
                    *ngFor="let class of module.planning_classes"
                    class="border border-customGray-mediumGray rounded py-2 px-4 flex flex-col md:flex-row md:items-center gap-2 justify-between"
                  >
                    <div class="flex justify-between w-full">
                      <p>{{ class.description }}</p>
                      <button
                        (click)="gotoDocs(class?.url_file)"
                        class="w-36 rounded-md border border-customBlue-default line-height-16 py-1 text-customBlue-default text-16"
                      >
                        {{ i18n.planner_google_docs }}
                      </button>
                    </div>
                  </li>
                  <!-- Empty procedure  -->
                  <li *ngIf="!module.planning_classes.length">
                    <p class="text-customGray-darker">
                      {{ i18n.planner_there_no }}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>
        <!-- Empty Modules  -->
        <li *ngIf="!filteredModules.length">
          <p class="text-customGray-dark">
            {{ i18n.planner_empty_modules }}
          </p>
        </li>
      </ul>
    </div>
  </article>
  <!-- Footer  -->
  <footer class="flex justify-center mt-4" *ngIf="!planning">
    <button
      type="button"
      class="border border-customBlue-default rounded p-2 text-customBlue-default"
      (click)="goToStartScreen()"
    >
      {{ i18n.planner_back_top }}
    </button>
  </footer>
</section>

<app-modal-material-list
  [isVisibleModalMaterialList]="valueVisibleModalMaterialList"
  [materialList]="valueModalMaterialList"
  (closeModalMaterialList)="controlModalMaterialList($event, valueModalMaterialList)"
  [i18n]="i18n"
></app-modal-material-list>
