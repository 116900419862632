import { Component, Input, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
    selector: 'app-content-view-skeleton',
    templateUrl: './content-view-skeleton.component.html',
    styleUrls: ['./content-view-skeleton.component.scss']
})
export class ContentViewSkeletonComponent implements OnInit {
    @Input() expanded: boolean;

    contentDescription: any = [{}, {}, {}, {}, {}, {}];

    constructor(private sharedService: SharedService) { }

    ngOnInit(): void {
        this.contentDescription.forEach((element, index) => {
            const item = element;
            if (index === 0) {
                item[`width_description${index}`] = 45;
                return;
            }
            item[`width_description${index}`] = this.sharedService.generateRandomNumber(80, 85);
        });
    }
}
