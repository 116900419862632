<app-generic-modal [isVisible]="true">
    <div class="container">
        <div class="header-modal">
            <div class="title-modal">
                {{ title }}
            </div>
            <p *ngIf="subTitle" class="sub-title-modal text-center">
                {{ subTitle }}
            </p>
        </div>

        <!-- Pre registered Dates -->
        <app-pre-registered-dates-modal
            *ngIf="templateType === 'preRegisteredSelectionMode'"
            [showResorucesDatesCheckboxes]="preRegisteredDates"
            [parentComponent]="'export'"
            (sendDatesResourcesValues)="getDatesResourcesValues($event)"
            (sendCloseModal)="closeModalImport()"
        ></app-pre-registered-dates-modal>

        <!-- TODO transform each template below in individual components -->
        <div *ngIf="isItInTheTemplates === true">
            <!-- ----------------------- Templates ---------------------->
            <div data-template-name="contentSelectionMode" *ngIf="contentSelectionMode">
                
                <div class="mx-auto">
                    <div class="body-modal flex-col mx-1">
                        <div class="input-search-container">
                            <label for="search">
                                <img
                                    src="../../../../../assets/svg/search-gray.svg"
                                />
                            </label>
                            <input
                                id="search"
                                class="input-search"
                                (keyup)="search($event)"
                            />
                        </div>
                        <div class="explanations-container">
                            <div class="text-options-1">{{ available }}</div>
                            <div
                                class="text-options-0"
                                role="button"
                                (click)="selectAllSections()"
                            >
                                {{ selectAll }}
                            </div>
                            <!-- <div class="text-options-0">{{ selectAll }}</div> -->
                        </div>
                        <div class="scroller" id="container">
                            <ng-container *ngIf="!loading">
                                <app-select-option-box
                                    [sections]="filteredSections"
                                    [textFound]="textFound"
                                    [alteredFilter]="alteredFilter"
                                    (sendingCheckData)="
                                        receivesCheckedContents($event)
                                    "
                                    (sendingCheckSectionData)="
                                        receivesCheckedSection($event)
                                    "
                                    [receiveControlAllSelect]="controlAllSelect"
                                    [receiveExternalIDContent]="
                                        receiveExternalIDContent
                                    "
                                ></app-select-option-box>
                                <div
                                    class="flex fle-row justify-center items-center w-full h-60"
                                    *ngIf="!sections?.length && !loading"
                                >
                                    <div
                                        class="flex flex-col justify-center items-center color-gray-icon"
                                    >
                                        <img
                                            src="../../../../../../../assets/svg/attention.svg"
                                            alt=""
                                        />
                                        <p>{{ i18n.reports_empty_data }}</p>
                                    </div>
                                </div>
                            </ng-container>
                            <div class="loading-container" *ngIf="loading">
                                <div class="loading">
                                    <img
                                        src="../../../../../assets/svg/loading.svg"
                                    />
                                    <p>{{ i18n.reports_wait }}</p>
                                    <p>{{ i18n.reports_we_are_loading }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="footer">
                    <button
                        class="button-save"
                        (click)="
                            continueImport(true);
                        "
                        [ngClass]="{
                            'disabled:opacity-75 cursor-not-allowed':
                                !modelForm.controls['sourceSections'].value
                                    ?.length &&
                                !modelForm.controls['sourceContents'].value
                                    ?.length
                        }"
                        [disabled]="
                            !modelForm.controls['sourceSections'].value
                                ?.length &&
                            !modelForm.controls['sourceContents'].value?.length
                        "
                    >
                        {{ buttonSave }}
                    </button>
                    <button class="button-cancel" (click)="closeModalImport()">
                        {{ i18n.disciplines_cancel }}
                    </button>
                </div>
            </div>
            <!-- ----  -->
            <div data-template-name="templateDisciplineMode" #disciplineSelection *ngIf="templateDisciplineMode">
                <div [formGroup]="modelForm">
                    <div class="body-modal flex-col mx-1">
                        <div class="input-search-container">
                            <label for="search">
                                <img
                                    src="../../../../../assets/svg/search-gray.svg"
                                />
                            </label>
                            <input
                                [formControl]="typedData"
                                id="search"
                                class="input-search"
                            />
                        </div>
                        <div
                            class="contents-duplicate"
                            *ngIf="type.type !== 'private'"
                        >
                            <div class="options mt-4">
                                <input
                                    formControlName="keepDisciplineInSync"
                                    id="toogle"
                                    class="options__input"
                                    type="checkbox"
                                />
                                <div class="options__input__label">
                                    {{
                                        i18n.disciplines_duplicate_content_and_end
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="explanations-container">
                            <div class="text-options-1">{{ available }}</div>
                            <div
                                class="text-options-0"
                                role="button"
                                (click)="selectAllDisciplines()"
                            >
                                {{ selectAll }}
                            </div>
                        </div>
                        <div class="scroller" id="container">
                            <div *ngIf="loading === false">
                                <ng-container
                                    *ngFor="let discipline of dataDisciplines"
                                >
                                    <app-select-discipline-box
                                        [discipline]="discipline"
                                        [modelForm]="modelForm"
                                        [selectedDiscipline]="
                                            targetDisciplinesToInput
                                        "
                                        (formDisciplineData)="
                                            checkDisciplineChange($event)
                                        "
                                    ></app-select-discipline-box>
                                </ng-container>
                                <div
                                    class="flex fle-row justify-center items-center w-full h-60"
                                    *ngIf="!dataDisciplines?.length && !loading"
                                >
                                    <div
                                        class="flex flex-col justify-center items-center color-gray-icon"
                                    >
                                        <img
                                            src="../../../../../../../assets/svg/attention.svg"
                                            alt=""
                                        />
                                        <p>{{ i18n.reports_empty_data }}</p>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="loading-container"
                                *ngIf="loading === true"
                            >
                                <div class="loading">
                                    <img
                                        src="../../../../../assets/svg/loading.svg"
                                    />
                                    <p>{{ i18n.reports_wait }}</p>
                                    <p>{{ i18n.reports_we_are_loading }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="mb-4 flex justify-center"
                    *ngIf="disciplinesRawData?.page !== undefined"
                >
                    <app-pagination
                        #pagination
                        [params]="paginationParams"
                        (navigation)="goToPage($event)"
                    ></app-pagination>
                </div>
                <div class="footer">
                    <button
                        class="button-save"
                        (click)="
                            !modelForm.controls['sourceSections'].value.length
                                ? continueSelectedDisciplines()
                                : emitDisciplineData()
                        "
                        [ngClass]="{
                            'disabled:opacity-75 cursor-not-allowed':
                                !modelForm.controls['targetDisciplines'].value
                                    ?.length
                        }"
                        [disabled]="
                            !modelForm.controls['targetDisciplines'].value
                                ?.length
                        "
                    >
                        {{
                            !modelForm.controls["sourceSections"].value.length
                                ? i18n.disciplines_continue
                                : buttonSave
                        }}
                    </button>
                    <button class="button-cancel" (click)="closeModalImport()">
                        {{ i18n.disciplines_cancel }}
                    </button>
                </div>
            </div>
            <!-- ----  -->
            <div data-template-name="templateSectionMode" #SectionSelection *ngIf="templateSectionMode">
                <div
                    class="px-4 flex flex-col gap-2.5 text-customBlue-default font-medium"
                >
                    <p>{{ targetDisciplineName }}</p>
                    <p>{{ i18n.disciplines_import_modal_existing_sections }}</p>
                </div>
                <div class="body-modal flex-col mx-1">
                    <div class="scroller" id="container">
                        <div *ngIf="loading === false">
                            <ng-container
                                *ngFor="let discipline of filteredSections"
                            >
                                <app-select-discipline-box
                                    [discipline]="discipline"
                                    [modelForm]="modelForm"
                                    [selectedDiscipline]="targetSectionsToInput"
                                    (formDisciplineData)="
                                        checkSectionChange($event)
                                    "
                                ></app-select-discipline-box>
                            </ng-container>
                            <div
                                class="flex fle-row justify-center items-center w-full h-60"
                                *ngIf="!filteredSections?.length && !loading"
                            >
                                <div
                                    class="flex flex-col justify-center items-center color-gray-icon px-4 gap-2"
                                >
                                    <img
                                        src="../../../../../../../assets/svg/attention.svg"
                                        alt=""
                                    />
                                    <p class="text-center">
                                        {{
                                            i18n.discipline_no_sections_in_discipline
                                        }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="loading-container" *ngIf="loading === true">
                            <div class="loading">
                                <img
                                    src="../../../../../assets/svg/loading.svg"
                                />
                                <p>{{ i18n.reports_wait }}</p>
                                <p>{{ i18n.reports_we_are_loading }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer">
                    <button
                        class="button-save"
                        (click)="continueSelectedDisciplines()"
                        [ngClass]="{
                            'disabled:opacity-75 cursor-not-allowed':
                                (!targetSectionsToInput.length &&
                                    filteredSections?.length) ||
                                loading
                        }"
                        [disabled]="
                            (!targetSectionsToInput.length &&
                                filteredSections?.length) ||
                            loading
                        "
                    >
                        {{ buttonName }}
                    </button>
                    <button class="button-cancel" (click)="closeModalImport()">
                        {{ i18n.disciplines_cancel }}
                    </button>
                </div>
            </div>
        </div>

        <!-- TODO remove (verifying if it used in some place over platform)
            all isItInTheTemplates === false div , because all this section is in charge of importModalComponent 
        -->
        <div *ngIf="isItInTheTemplates === false">
            <div>isItInTheTemplates = false</div>
            <!-- ----------------------- TURMAS ---------------------->
            <div *ngIf="templateSelectionMode">
                <div [formGroup]="modelForm">
                    <div class="body-modal flex-col mx-1">
                        <div class="input-search-container">
                            <label for="search">
                                <img
                                    src="../../../../../assets/svg/search-gray.svg"
                                />
                            </label>
                            <input
                                id="search"
                                class="input-search"
                                [formControl]="typedData"
                            />
                        </div>
                        <div class="contents-duplicate">
                            <div class="options mt-4">
                                <input
                                    id="toogle"
                                    type="checkbox"
                                    class="options__input"
                                    formControlName="keepDisciplineInSync"
                                />
                                <div class="options__input__label">
                                    {{
                                        i18n.disciplines_duplicate_content_and_end
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="explanations-container">
                            <div class="text-options-1">{{ available }}</div>
                            <!-- <div class="text-options-0">{{ selectAll }}</div> -->
                        </div>
                        <div class="scroller" id="container">
                            <div *ngIf="loading === false">
                                <ng-container
                                    *ngFor="let discipline of dataDisciplines"
                                >
                                    <app-select-discipline-box
                                        [discipline]="discipline"
                                        [modelForm]="modelForm"
                                        (formDisciplineData)="
                                            checkTemplateChange($event)
                                        "
                                    ></app-select-discipline-box>
                                </ng-container>
                                <div
                                    class="flex fle-row justify-center items-center w-full h-60"
                                    *ngIf="!dataDisciplines?.length && !loading"
                                >
                                    <div
                                        class="flex flex-col justify-center items-center color-gray-icon"
                                    >
                                        <img
                                            src="../../../../../../../assets/svg/attention.svg"
                                            alt=""
                                        />
                                        <p>{{ i18n.reports_empty_data }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="loading-container" *ngIf="loading">
                                <div class="loading">
                                    <img
                                        src="../../../../../assets/svg/loading.svg"
                                    />
                                    <p>{{ i18n.reports_wait }}</p>
                                    <p>{{ i18n.reports_we_are_loading }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="mb-4 flex justify-center"
                    *ngIf="disciplinesRawData?.page !== undefined"
                >
                    <app-pagination
                        #pagination
                        [params]="paginationParams"
                        (navigation)="goToPage($event)"
                    ></app-pagination>
                </div>
                <div class="footer">
                    <button class="button-cancel" (click)="closeModalImport()">
                        {{ i18n.disciplines_cancel }}
                    </button>
                </div>
            </div>
            <!-- --  -->
            <div #SectionSelection *ngIf="templateSectionImportMode">
                <div
                    class="px-4 flex flex-col gap-2.5 text-customBlue-default font-medium"
                >
                    <p>{{ targetDisciplineName }}</p>
                    <p>{{ i18n.disciplines_import_modal_existing_sections }}</p>
                </div>
                <div class="body-modal flex-col mx-1">
                    <div class="scroller" id="container">
                        <div *ngIf="loading === false">
                            <ng-container
                                *ngFor="let discipline of filteredSections"
                            >
                                <app-select-discipline-box
                                    [discipline]="discipline"
                                    [modelForm]="modelForm"
                                    [selectedDiscipline]="targetSectionsToInput"
                                    (formDisciplineData)="
                                        checkSectionChange($event)
                                    "
                                ></app-select-discipline-box>
                            </ng-container>
                            <div
                                class="flex fle-row justify-center items-center w-full h-60"
                                *ngIf="!filteredSections?.length && !loading"
                            >
                                <div
                                    class="flex flex-col justify-center items-center color-gray-icon"
                                >
                                    <img
                                        src="../../../../../../../assets/svg/attention.svg"
                                        alt=""
                                    />
                                    <p>{{ i18n.reports_empty_data }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="loading-container" *ngIf="loading === true">
                            <div class="loading">
                                <img
                                    src="../../../../../assets/svg/loading.svg"
                                />
                                <p>{{ i18n.reports_wait }}</p>
                                <p>{{ i18n.reports_we_are_loading }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer">
                    <button
                        class="button-save"
                        (click)="emitDisciplineData()"
                        [ngClass]="{
                            'disabled:opacity-75 cursor-not-allowed':
                                !modelForm.controls['targetSections'].value
                                    ?.length
                        }"
                        [disabled]="
                            !modelForm.controls['targetSections'].value?.length
                        "
                    >
                        {{ buttonSave }}
                    </button>

                    <button class="button-cancel" (click)="closeModalImport()">
                        {{ i18n.disciplines_cancel }}
                    </button>
                </div>
            </div>
            <!-- ---  -->
            <div *ngIf="templateDisciplineMode" #contentSelection>
                <div>
                    <div class="body-modal flex-col mx-1">
                        <div class="input-search-container">
                            <label for="search">
                                <img
                                    src="../../../../../assets/svg/search-gray.svg"
                                />
                            </label>
                            <input
                                id="search"
                                class="input-search"
                                (input)="search($event)"
                            />
                        </div>
                        <div class="explanations-container">
                            <div class="text-options-1">{{ available }}</div>
                            <div
                                class="text-options-0"
                                role="button"
                                (click)="selectAllSections()"
                            >
                                {{ selectAll }}
                            </div>
                        </div>
                        <div class="scroller" id="container">
                            <ng-container *ngIf="loading === false">
                                <app-select-option-box
                                    [sections]="filteredSections"
                                    [textFound]="textFound"
                                    [alteredFilter]="alteredFilter"
                                    (sendingCheckData)="
                                        receivesCheckedContents($event)
                                    "
                                    (sendingCheckSectionData)="
                                        receivesCheckedSection($event)
                                    "
                                    [receiveControlAllSelect]="controlAllSelect"
                                ></app-select-option-box>
                            </ng-container>
                            <div
                                class="flex fle-row justify-center items-center w-full h-60"
                                *ngIf="!filteredSections?.length && !loading"
                            >
                                <div
                                    class="flex flex-col justify-center items-center color-gray-icon"
                                >
                                    <img
                                        src="../../../../../../../assets/svg/attention.svg"
                                        alt=""
                                    />
                                    <p>{{ i18n.reports_empty_data }}</p>
                                </div>
                            </div>
                            <div
                                class="loading-container"
                                *ngIf="loading === true"
                            >
                                <div class="loading">
                                    <img
                                        src="../../../../../assets/svg/loading.svg"
                                    />
                                    <p>{{ i18n.reports_wait }}</p>
                                    <p>{{ i18n.reports_we_are_loading }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer">
                    <button
                        class="button-save"
                        (click)="
                            !modelForm.controls['sourceSections'].value?.length
                                ? continueTargetSectionsImport()
                                : emitDisciplineData()
                        "
                        [ngClass]="{
                            'disabled:opacity-75 cursor-not-allowed':
                                !modelForm.controls['sourceContents'].value
                                    ?.length
                        }"
                        [disabled]="
                            !modelForm.controls['sourceContents'].value?.length
                        "
                    >
                        {{
                            !modelForm.controls["sourceSections"].value?.length
                                ? i18n.disciplines_continue
                                : buttonSave
                        }}
                    </button>
                    <button class="button-cancel" (click)="closeModalImport()">
                        {{ i18n.disciplines_cancel }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</app-generic-modal>
