import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { API_Routes } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class DisciplinesModelsService {
    isImportModelOpen$ = new BehaviorSubject<boolean>(false);
    isExportContentsModalOpen$ = new BehaviorSubject<boolean>(false);
    isImportModelClassroomOpen$ = new BehaviorSubject<boolean>(false);
    isLoadingModal$ = new BehaviorSubject<boolean>(false);
    isLoading$ = new BehaviorSubject<boolean>(false);
    preRegisteredDates$ = new BehaviorSubject<any>({});
    sectionsAndContentsSelected$ = new BehaviorSubject<any>({});
    isDuplicateDisciplineOpen$ = new BehaviorSubject<boolean>(false);

    constructor(private http: HttpClient, private toastr: ToastrService) {}

    openImportModal() {
        this.isImportModelOpen$.next(true);
    }

    closeImportModal() {
        this.isImportModelOpen$.next(false);
    }

    openDuplicateDiscipline() {
        this.isDuplicateDisciplineOpen$.next(true);
    }

    closeDuplicateDiscipline() {
        this.isDuplicateDisciplineOpen$.next(false);
    }

    openExportContentsModal() {
        this.isExportContentsModalOpen$.next(true);
    }

    closeExportContentsModal() {
        this.isExportContentsModalOpen$.next(false);
    }

    addModelModalClassroomOpen() {
        this.isImportModelClassroomOpen$.next(true);
    }

    addModelModalClassroomClose() {
        this.isImportModelClassroomOpen$.next(false);
    }

    isLoadingModalTrue() {
        this.isLoadingModal$.next(true);
    }

    isLoadingModalFalse() {
        this.isLoadingModal$.next(false);
    }

    isLoadingTrue() {
        this.isLoading$.next(true);
    }

    isLoadingFalse() {
        this.isLoading$.next(false);
    }

    showMessage(title: string, message: string, type: string): void {
        this.toastr.show(message, title, { progressBar: true }, type);
    }

    getDisciplines(
        types: any,
        query: any,
        level: any,
        modalities: any,
        months: any,
        years: any,
        page: number,
        per_page: number
    ): Observable<any> {
        return this.http
            .get<any>(
            `${API_Routes.URL}/disciplines/?type=${types}&q=${query}&levels=${level}&modalities=${modalities}&months=${months}&years=${years}&page=${page}&per_page=${per_page}`
        )
            .pipe(take(1));
    }

    getSections(discipline_external_id: string, replication_permission?: string): Observable<any> {
        let replicationPermission = '';
        if (replication_permission?.length) {
            replicationPermission = replication_permission;
        }
        return this.http
            .get<any>(
            `${API_Routes.URL}/disciplines/${discipline_external_id}/sections${replicationPermission}`
        )
            .pipe(take(1));
    }

    getDisciplineLevels(): Observable<any> {
        return this.http.get(`${API_Routes.URL}/levels`).pipe(take(1));
    }

    getClassroomDisciplineLog(): Observable<any> {
        return this.http.get(`${API_Routes.URL}/disciplines/classroom-disicpline-log`).pipe(take(1));
    }

    getDisciplineModalities(): Observable<any> {
        return this.http.get(`${API_Routes.URL}/modalities`).pipe(take(1));
    }

    postDisciplines(params: any): Observable<any> {
        return this.http
            .post(`${API_Routes.URL}/disciplines/`, params)
            .pipe(take(1));
    }

    postImport(params: any): Observable<any> {
        return this.http.post(`${API_Routes.URL}/disciplines/`, params);
    }

    postImportDisciplineOrigin(
        params: any,
        originDisciplineExternalId: any
    ): Observable<any> {
        return this.http.post(
            `${API_Routes.URL}/disciplines/${originDisciplineExternalId}/replicate`,
            params
        );
    }

    postToExportClassroomDisciplines(params: any): Observable<any> {
        return this.http.post(
            `${API_Routes.URL}/disciplines/export-classroom`,
            params
        );
    }

    postImportDisciplineClassRoom(params: any): Observable<any> {
        return this.http.post(
            `${API_Routes.URL}/disciplines/import-classroom`,
            params
        );
    }

    deleteDisciplines(id: number) {
        return this.http
            .delete(`${API_Routes.URL}/disciplines/${id}`)
            .pipe(take(1));
    }

    getStatusImport(discipline_external_id: string): Observable<any> {
        return this.http
            .get<any>(
            `${API_Routes.URL}/disciplines/${discipline_external_id}/replicate/job`
        )
            .pipe(take(1));
    }

    postReplicateSync(discipline_external_id: string, params): Observable<any> {
        return this.http.post<any>(
            `${API_Routes.URL}/disciplines/${discipline_external_id}/replicate/sync`,
            params
        );
    }

    postDuplicate(discipline_external_id: string, params: any) {
        return this.http
            .post(
                `${API_Routes.URL}/disciplines/${discipline_external_id}/duplicate`,
                params
            )
            .pipe(take(1));
    }

    getDatesByResources(discipline_external_id: string, params): Observable<any> {
        return this.http.post<any>(`${API_Routes.URL}/disciplines/${discipline_external_id}/replicate/dates`, params);
    }
}
