import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/services/localStorageService/local-storage.service';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';

import { AccountsService } from '../../accounts.service';

declare const google: any;

@Component({
    selector: 'app-google-login',
    templateUrl: './google-login.component.html',
    styleUrls: ['./google-login.component.scss']
})
export class GoogleLoginComponent implements OnInit {
    @Input() isVisible: boolean;
    isLoading: boolean;
    i18n: any = [];

    constructor(
        private accountsService: AccountsService,
        private sharedService: SharedService,
        public platModalService: PlatformModalsService,
        private router: Router,
        private localStorageService: LocalStorageService
    ) { }

    ngOnInit(): void {
        this.getTranslations();
        this.getAllowProvider();
    }

    getAllowProvider() {
        if (this.localStorageService.getLocalStorageItem('allowedProviders') && this.localStorageService.getLocalStorageItem('backendAccount')) {
            const providers = JSON.parse(localStorage.getItem('allowedProviders') || '');
            const backendAccount = JSON.parse(localStorage.getItem('backendAccount') || '');

            if (providers?.length) {
                const googleProvider = providers.find((item) => item.name === 'google');

                if (googleProvider !== undefined && Object.keys(googleProvider).length) {
                    this.getGoogleClientId();

                    const whoami = {
                        clientId: googleProvider.client_id,
                        apiKey: googleProvider.api_key,
                        appId: googleProvider.app_id,
                        mail: backendAccount
                    };

                    this.accountsService.setWhoamiCredencials(whoami);
                }
            }
        }
    }

    getTranslations(): void {
        this.i18n = { ...this.sharedService.getTranslationsOf('Login'), ...this.sharedService.getTranslationsOf('Errors') };
    }

    getGoogleClientId() {
        this.isLoading = true;
        const allowedProviders = JSON.parse(localStorage.getItem('allowedProviders') || '');
        if (Array.isArray(allowedProviders) || allowedProviders !== undefined || allowedProviders !== '') {
            allowedProviders.forEach((element) => {
                if (element.name === 'google' && element.client_id !== '' && element.client_id !== undefined) {
                    this.loadGoogleClient(element.client_id);
                }
            });
        } else {
            throw new Error('Plataform settings is undefined');
        }
        this.isLoading = false;
        this.isVisible = true;
    }

    client;
    loadGoogleClient(client_id) {
        this.client = google.accounts.oauth2.initCodeClient({
            client_id,
            scope: 'email profile',
            callback: (response) => {
                if (response != null) {
                    this.checkGoogleUser(response.code);
                }
            },
        });
    }

    loginWithGoogle() {
        if (this.client) {
            this.client.requestCode();
        } else {
            this.getAllowProvider();
            setTimeout(() => {
                this.client.requestCode();
            }, 200);
        }
    }

    checkGoogleUser(user: any) {
        if (user) {
            this.authGoogle(user);
        } else {
            localStorage.setItem('logOut', 'true');
        }
    }

    authGoogle(code: any) {
        this.isLoading = true;
        const params = {
            code
        };

        this.accountsService.loginWithGoogle(params)
            .subscribe({
                next: (response) => {
                    // Se usuário não aceitou os termos, abre modal e encaminha objeto de
                    // resposta de
                    this.sharedService.storeUserCredentials(response);
                    if (response.terms !== null) {
                        this.accountsService.authObject$.next(response);
                        this.accountsService.openModalPolicyAndPrivacy$.next({ openModal: true, readOnly: false });
                    } else {
                        this.accountsService.setGoogleCredencials(response);
                        this.sharedService.loggedIn.next(true);
                        this.accountsService.isRedirectRoute();
                        this.router.navigate(['']);
                    }
                },
                error: (err) => {
                    this.isLoading = false;
                    if (err.status === 422) {
                        this.platModalService.toggle('message', this.i18n?.[err?.error?.key_error], 'close');
                    } else if (err.status === 404) {
                        this.platModalService.toggle('message', this.i18n?.error_not_profile_login, 'close');
                    } else {
                        this.platModalService.toggle('message', this.i18n?.error_system_error, 'close');
                    }

                    this.sharedService.loggedIn.next(false);
                },
                complete: () => { this.isLoading = false; }
            });
    }
}
