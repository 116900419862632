<div 
  [@expanded_content_list]="screenSize < 945 ? false : expanded"
  [style.max-width]="max_width"
  [style.min-width]="expanded ? 0 : '304px'"
>
    <ng-container *ngIf="!isLoading">
      <div
        class="relative flex flex-col w-full bg-white outline-none focus:outline-none rounded-lg "
      >
        <!--header-->
        <div
          class="text-center p-5 border-b border-solid border-customBlue-light rounded-t-lg "
          style="background-color: #3656bf; padding: 12px"
          
        >
          <h3 class="text-xl font-bold" style="color: #fff; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
            {{ section?.title }}
          </h3>
        </div>
        <!--body-->
        <div
          class="px-4 py-8 bg-customWhite-default "
          style="
            border: 1px solid var(--color-light-blue);
            border-top: none;
            border-radius: 0 0 8px 8px;
          "
        >
          <div
            *ngFor="let content of section?.contents; let contentIndex = index"
            class="border border-customGray-lighterGray rounded-md mb-2"
          >
            <button
              (click)="goToContent(content)"
              class="w-full p-2 px-6"
              [ngClass]="{
                'content-disabled': isLocked(content?.status, content?.begin)
              }"
              [disabled]="isLocked(content?.status, content?.begin)"
            >
              <div class="flex">
                <div *ngIf="user?.role_type === 'student'" class="flex items-center">
                  <div
                    pTooltip="{{  getTitleFromConditionalContent(content) }}"
                    tooltipPosition="left"
                  >
                    <img
                      class="w-7 h-7 max-w-none" 
                      [src]="contentStatus(content?.status, content?.begin)" />
                  </div>
                </div>
                <div 
                  class="flex flex-col gap-1 p-2 w-full"
                 
                >
                  <div
                    class="ml-2 text-left text-customGray-dark"
                    [ngClass]="{
                      'font-bold': content?.external_id === content_external_id
                    }"
                    style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                    pTooltip="{{content?.title}}" tooltipPosition="top" pRipple
                  >
                    {{ content?.title }}
                  </div>

                  <div
                    class="ml-2 text-left bg-customGray-iceGray rounded-md"
                    style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                  >
                    <div class="text-customBlue-default text-16">
                      {{ setNameTypeContents(content?.type) }}
                    </div>
                  </div>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </ng-container>

</div>
<ng-container *ngIf="isLoading">
  <app-content-view-content-list-skeleton
    [expanded]="expanded"
  >
  </app-content-view-content-list-skeleton>
</ng-container>