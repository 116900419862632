import {
    animate, AUTO_STYLE,
    state, style, transition, trigger
} from '@angular/animations';
import {
    Component, ElementRef, Input, OnInit, ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'src/app/shared/shared.service';

import { Material, Procedure } from '../../../interfaces/modules.interface';
import { DetailedView, ModulesDetailsView } from '../../../interfaces/planning.interface';
import { ContentPlannerService } from '../../../services/content-planner.service';

const DEFAULT_DURATION = 250;

@Component({
    selector: 'app-my-planning',
    templateUrl: './my-planning.component.html',
    styleUrls: ['./my-planning.component.scss'],
    animations: [
        trigger('collapse', [
            state(
                'true',
                style({ height: AUTO_STYLE, visibility: AUTO_STYLE, opacity: 1 })
            ),
            state(
                'false',
                style({ height: '0rem', visibility: 'hidden', opacity: 0 })
            ),
            transition('false => true', animate(`${DEFAULT_DURATION}ms ease-out`)),
            transition('true => false', animate(`${DEFAULT_DURATION}ms ease-in`)),
        ]),
    ],
})
export class MyPlanningComponent implements OnInit {
    @ViewChild('firstDomElement') firstDomElement: ElementRef;
    valueModalMaterialList: Material[] = [];
    filteredModules: ModulesDetailsView[] = [];
    detailedView: DetailedView = {} as DetailedView;
    planningExternalID = '';
    filterBy = '';
    valueVisibleModalMaterialList = false;
    loading = true;
    i18n: any = {};

    @Input() planning: any = null;

    expandedItems: boolean[] = [];

    constructor(private contentPlannerService: ContentPlannerService, private route: ActivatedRoute, private router: Router, private sharedService: SharedService) { }

    ngOnInit(): void {
        this.getParams();
        this.getTraductions();
        this.checkPlannig();
    }

    checkPlannig(): void {
        if (this.planning) {
            this.setDetailedView();
        } else {
            this.getPlanningDetailedView();
        }
    }

    setDetailedView(): void {
        this.planning.modules.map((element, index) => {
            const module = element;
            const proceduceList: Procedure[] = [];
            // Add collapse in module
            module.control = false;
            module.planning_classes = [];

            // Get procedure and material list
            module.planned_classes.forEach((planned) => {
                planned.planning_classes.forEach((classes) => {
                    module.planning_classes.push(classes);

                    classes.detail_items.procedure.forEach((procedure) => {
                        proceduceList.push(procedure);
                    });
                });
            });

            // Remove duplicate itens
            module.procedure = proceduceList.filter((obj, i, self) => i === self.findIndex((t) => (
                t.value === obj.value
            )));

            // Check if has fields
            module.has_fields_detail = !!Object.values(module.details).flat().length;
            return null;
        });

        this.filteredModules = this.planning.modules;
        this.detailedView = this.planning;
        this.loading = false;
    }

    setExpandedItems(module: any): void {
        if (module?.skills?.length) {
            this.expandedItems = new Array(module.skills.length).fill(false);
        }
    }

    getParams(): void {
        if (this.route.snapshot.params.planning_external_id) {
            this.planningExternalID = this.route.snapshot.params.planning_external_id;
        }
    }

    getTraductions(): void {
        this.i18n = this.sharedService.getTranslationsOf('ContentPlanner');
    }

    getPlanningDetailedView(): void {
        if (this.planningExternalID) {
            this.contentPlannerService.getPlanningDetailedView(this.planningExternalID).subscribe({
                next: (response) => {
                    response.modules.map((element, index) => {
                        const module = element;
                        const proceduceList: Procedure[] = [];
                        // Add collapse in module
                        module.control = false;
                        module.planning_classes = [];

                        // Get procedure and material list
                        module.planned_classes.forEach((planned) => {
                            planned.planning_classes.forEach((classes) => {
                                module.planning_classes.push(classes);

                                classes.detail_items.procedure.forEach((procedure) => {
                                    proceduceList.push(procedure);
                                });
                            });
                        });

                        // Remove duplicate itens
                        module.procedure = proceduceList.filter((obj, i, self) => i === self.findIndex((t) => (
                            t.value === obj.value
                        )));

                        // Check if has fields
                        module.has_fields_detail = !!Object.values(module.details).flat().length;
                        return null;
                    });

                    this.filteredModules = response.modules;
                    this.setExpandedItems(this.filteredModules);
                    this.detailedView = response;
                    this.loading = false;
                },
                error: () => {
                    this.loading = false;
                    this.router.navigate(['/class-planner']);
                }
            });
        }
    }

    goToStartScreen(): void {
        const firstElement = this.firstDomElement.nativeElement;

        if (firstElement) {
            firstElement.scrollIntoView();
        }
    }

    controlModalMaterialList(value: boolean, materialList: Material[]): void {
        if (typeof value === 'boolean') {
            this.valueModalMaterialList = materialList;
            this.valueVisibleModalMaterialList = value;
        }
    }

    toggleModule(moduleIndex: number): void {
        this.detailedView.modules[moduleIndex].control = !this.detailedView.modules[moduleIndex].control;
    }

    normalizeString(str: string) {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }

    set search_modules_filter(value: string) {
        this.filteredModules = this.detailedView.modules;
        this.filterBy = value;

        const normalizedValue = this.normalizeString(value.toLowerCase());

        this.filteredModules = this.detailedView.modules.filter((element) => {
            const normalizedName = this.normalizeString(element.name.toLowerCase());
            if (normalizedName.indexOf(normalizedValue) > -1) {
                const module = element;
                module.control = false;
                return true;
            }
            return false;
        });
    }

    get search_modules_filter(): string {
        return this.filterBy;
    }

    toggleDescription(index: number): void {
        this.expandedItems[index] = !this.expandedItems[index];
    }

    gotoDocs(url: any): void {
        if (url) {
            window.open(url, '_blank');
        }
    }
}
