import { Component, Input, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { DashboardService } from '../dashboard.service';
import { TimeService } from 'src/app/services/time-services.service';
import { LocalStorageService } from 'src/app/services/localStorageService/local-storage.service';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit, OnChanges{

 name: any = [];
 @Input() user: any = [];
 @Input() i18n: any = [];
 @Input() dashData: any = [];
 adminData: any = []
 showLoading: boolean = true
 isLoading$ = this.dashboardService.isLoading$;


 constructor(
  private dashboardService: DashboardService,
  private timeService: TimeService,
  private localStorageService: LocalStorageService,
  public sharedService: SharedService,
 ) {}

 ngOnInit(): void {
    this.letter();
    this.getLoading();
 }

 ngOnChanges(changes: SimpleChanges) {
   console.log('On Changes emit', changes); 
  if (Object.keys(this.dashData).length > 0) {
    this.adminData = this.dashData.stats
    console.log('DASH_ADMIN DATA', this.adminData)
  }
}

// Replace for sharedService getDateTime
// getDateTime(date) {
//   let currentLanguage;
//   if (this.localStorageService.getLocalStorageItem('currentLanguage')) {
//     currentLanguage = localStorage.getItem('currentLanguage')
//   }
//   if (!currentLanguage) {
//     currentLanguage = 'pt-BR'
//   }

//   if (!date) {
//     return
//   }

//   let newHour = date.split(' ')[1];
//   let newDate = new Date(date).toLocaleDateString(currentLanguage, { year:"numeric", month:"long", day:"numeric"}) 
//   return this.i18n.dash_last_update +' '+ newDate +' '+ this.i18n.upload_hours +' '+newHour;
// }


 letter() {
   let name = this.user.name;
   let initials = name.charAt(0);
   this.name = initials;
 }


 getLoading() {
  console.log('MOSTRA TEACHER: ', this.showLoading);
  this.isLoading$.subscribe(
    (data) => this.showLoading = data
  )  
  }
}
