import {
    Component, HostListener, OnInit, ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { finalize } from 'rxjs/operators';

import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TranslateServiceLocale } from './components/translate/translate.service';
import { AccountsService } from './pages/accounts/accounts.service';
import { ContentService } from './pages/disciplines/components/content/content.service';
import { LocalStorageService } from './services/localStorageService/local-storage.service';
import { PlatformModalsService } from './services/modals/platform-modals.service';
import { TitlePageService } from './services/titlePageService/title-page.service';
import { SharedService } from './shared/shared.service';

declare global {
    interface Window {
        gtag: any;
    }
    interface Window {
        gtag: any;
    }
}
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    @ViewChild(SidebarComponent) sidebar: SidebarComponent;
    @ViewChild(HeaderComponent) header: HeaderComponent;

    title = 'Inicie Plataforma';
    addStyle = '--screenHeight: 100vh';
    isIOS = false;

    isTranslationsUploaded$ = this.accountsService.isTranslationsUploaded$;
    isTranslationsUploaded = false;

    userHasToken: boolean | null = null;
    hasTokenPartner = false;

    loading = true;

    options: any = {};
    userIsImpersonating: any;
    canAccessibility = false;

    constructor(
        private sharedService: SharedService,
        private accountsService: AccountsService,
        private titleService: TitlePageService,
        private localStorageService: LocalStorageService,
        public platModalService: PlatformModalsService,
        private primeNGConfig: PrimeNGConfig,
        private route: ActivatedRoute,
        private router: Router,
        private translateServiceLocale: TranslateServiceLocale,
        private contentService: ContentService
    ) { }

    ngOnInit(): void {
        this.setLanguageFromURL();
        this.localStorageService.checkCookiesEnabled();
        this.localStorageService.checkVersion();
        this.getTokenStatus();
        this.checkTokenStatus();
        this.setHeadScripts();
        this.getImpersonatingStatus();
        this.setTitle();
        setTimeout(this.getScreenSize.bind(this), 500);
        this.setTranslationToPrimeNgCalendar();
        this.setFavicon();
        this.firstLoad();
        // Function reload when is IOS
        this.isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
        this.getCurrentLanguage();
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(): void {
        if (!this.isIOS) {
            const getHeight = window.innerHeight + 5;
            this.addStyle = `--screenHeight:${getHeight}px`;
        }
    }

    getTokenStatus(): void {
        this.sharedService.loggedIn.subscribe({
            next: (logged: boolean) => {
                if (this.userHasToken !== logged) {
                    const url = window.location.href;

                    if (!url.includes('localhost')) {
                        this.setGtagHead(logged);
                    }

                    this.userHasToken = logged;
                }
            }
        });
    }

    checkTokenStatus(): void {
        this.route.queryParams.subscribe({
            next: (response) => {
                if (response && Object.prototype.hasOwnProperty.call(response, 'token_partner')) {
                    this.hasTokenPartner = true;
                    localStorage.removeItem('token');
                }
            }
        });

        if (this.sharedService.fnUserHasToken() && this.sharedService.fnUserHasValidToken()) {
            this.userHasToken = true;
        }
    }

    // Primeiro load da aplicação
    firstLoad(): void {
        if (this.localStorageService.checkAppIsLoaded() && this.userHasToken) {
            this.isTranslationsUploaded = true;
            this.accountsService.whoamiDataSubject.next(true);
            this.loading = false;
            return;
        }

        if (!this.localStorageService.checkAppIsLoaded() || !this.userHasToken) {
            this.accountsService.whoami()
                .pipe(
                    finalize(() => {
                        this.getTranslationsFile();
                    })
                )
                .subscribe({
                    next: (response) => {
                        this.options = response;

                        // set configs padrão para serem usados na plataforma
                        localStorage.setItem('allowedProviders', JSON.stringify(response.authentication.allowedProviders));
                        localStorage.setItem('provider', response.storage.provider);
                        localStorage.setItem('advanced', JSON.stringify(response.advanced));
                        localStorage.setItem('gcsInfraPath', (response.storage.gcsInfraPath));
                        localStorage.setItem('backendAccount', JSON.stringify(response.authentication.backendAccount));
                        localStorage.setItem('support_email', JSON.stringify(response.authentication.support_email));
                        localStorage.setItem('languages', JSON.stringify(response.translation.languages));
                        localStorage.setItem('redirect_option', response.authentication.redirect_to_discipline);

                        localStorage.setItem('main_logo', JSON.stringify(response.customization.main_logo?.public_url || ''));
                        localStorage.setItem('login_background', JSON.stringify(response.customization.login_background?.public_url || ''));
                        localStorage.setItem('login_image', JSON.stringify(response.customization.login_image?.public_url || ''));

                        if (response.authentication.allowedProviders.length) {
                            this.accountsService.update_localstorage.next(response.authentication);
                        }

                        if (response?.customization) {
                            const settingsGeneral = response?.customization;
                            localStorage.setItem('site_metadata', JSON.stringify({ settingsGeneral }));
                        }

                        this.applyCustomization();
                    },
                    error: () => {
                        this.loading = false;
                    }
                });
        }
    }

    getTranslationsFile(): void {
        const uri = localStorage.getItem('gcsInfraPath');
        const selectedLanguage = this.sharedService.getSelectedLanguage();

        this.translateServiceLocale.getTranslationsFile(uri, selectedLanguage).subscribe({
            next: (file) => {
                if (!Object.keys(file).length) {
                    this.localStorageService.checkVersion();
                    // throw new Error(`Translations file empty; selectedLanguage ${selectedLanguage}; uri ${uri}`);
                }

                localStorage.setItem('translations', JSON.stringify(file));

                if (!this.hasTokenPartner) {
                    this.accountsService.isTranslationsUploaded$.next(true);
                }

                this.loginEdebe();

                // Finaliza o load da plataforma
                this.isTranslationsUploaded = true;
                this.accountsService.whoamiDataSubject.next(true);
                this.loading = false;
            },
            error: (error) => {
                this.loading = false;

                if (error.status === 404) {
                    this.sharedService.throwError('Cannot load translations file');
                }
            }
        });
    }

    loginEdebe(): void {
        this.route.queryParams.subscribe({
            next: (response) => {
                if (response && Object.prototype.hasOwnProperty.call(response, 'token_partner')) {
                    const token = response.token_partner;
                    localStorage.setItem('token', token);

                    this.accountsService.refreshTokenStandalone().subscribe({
                        next: (credentials) => {
                            this.sharedService.storeUserCredentials(credentials);
                            this.router.navigate(['./dashboard']);
                        },
                    });
                }
            }
        });
    }

    applyCustomization() {
        if (this.options.customization.site_title) {
            document.title = this.options.customization.site_title;
        }
        if (this.options.customization.favicon) {
            const link: HTMLLinkElement | null = document.querySelector('link[rel~=\'icon\']');
            if (link) link.href = this.options.customization.favicon.public_url;
        }
        if (this.options.advanced.login_screen_script && !this.sharedService.checkIfScriptsExists('login_script')) {
            this.sharedService.insertHTMLFromString(this.options.advanced.login_screen_script, 'login_script');
        }

        // TODO verificar se Vlibras está habilitado pelo whoami (options)
        // if (this.options.vlibras) {
        this.accountsService.hasVLibrasActivated$.next(true);

        // };

        // TODO verificar se UserWay está habilitado pelo whoami (options)
        // if (this.options.UserWay) {
        this.accountsService.hasUserWayActivated$.next(true);

        // }

    // this.sharedService.setFavicon();
    }

    setTranslationToPrimeNgCalendar(): void {
        this.localStorageService.getCalendarTranslation().subscribe({
            next: (i18n) => {
                this.primeNGConfig.setTranslation(i18n.primeng);
            }
        });
    }

    setFavicon(): void {
        // Verifica se requisião do whoami já finalizou
        if (!this.isTranslationsUploaded) {
            return;
        }

        // Valida chave no localStorage e coloca o favicon
        if (!this.localStorageService.getLocalStorageItem('site_metadata')) {
            throw new Error('Error site_metadata does not exist or is empty in localStorage ');
        }

        const { settingsGeneral } = JSON.parse(localStorage.getItem('site_metadata') || '');
        let favicon = '';

        if (settingsGeneral.favicon == null) {
            favicon = 'assets/favicon.png';
        } else {
            favicon = settingsGeneral.favicon.public_url;
        }

        document?.getElementsByTagName('head')[0]?.querySelector('link[rel="icon"]')?.setAttribute('href', favicon);
    }

    setHeadScripts() {
        const url = window.location.href;
        this.checkIfGlobalScriptsAndInsertIntoHead('global_script');
        this.checkIfApplyGlobalScripts();
        if (!url.includes('localhost')) {
            const clarity = document.createElement('script');
            clarity.type = 'text/javascript';
            document?.getElementsByTagName('head')[0].appendChild(clarity).append('(function(c,l,a,r,i,t,y){ c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)}; t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i; y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y); })(window, document, "clarity", "script", "dq64wjjrjo");');

            const mouseflow = document.createElement('script');
            mouseflow.type = 'text/javascript';
            document?.getElementsByTagName('head')[0].appendChild(mouseflow).append('window._mfq = window._mfq || []; (function() { var mf = document.createElement("script"); mf.type = "text/javascript"; mf.defer = true; mf.src = "//cdn.mouseflow.com/projects/00f50ecc-08b6-4cb0-b889-88f504dd9d12.js"; document.getElementsByTagName("head")[0].appendChild(mf); })();');

            const hotjar = document.createElement('script');
            document?.getElementsByTagName('head')[0].appendChild(hotjar).append(
                `(function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:2476790,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
                })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`
            );
        }
    }

    checkIfApplyGlobalScripts() {
        const id = 'global_script';
        this.sharedService.removeGlobalScript$.subscribe(({
            next: (removeGlobalScript: boolean) => {
                if (removeGlobalScript) {
                    this.sharedService.removeScript(id);
                    return;
                }
                this.checkIfGlobalScriptsAndInsertIntoHead(id);
            }
        }));
    }

    checkIfGlobalScriptsAndInsertIntoHead(id: string) {
        if (!this.sharedService.checkIfScriptsExists(id)) {
            this.insertCustomGlobalScripts();
        }
    }

    insertCustomGlobalScripts() {
        let advancedOptions;

        if (this.localStorageService.getLocalStorageItem('advanced')) {
            advancedOptions = JSON.parse(localStorage.getItem('advanced') || '');
        }

        if (advancedOptions) {
            if (advancedOptions.global_screen_script !== '') {
                this.sharedService.insertHTMLFromString(advancedOptions.global_screen_script, 'global_script');
            }
        }
    }

    insertGtag(gtag_id: string, user: any) {
        // remove script google tag src if exists
        this.sharedService.removeScript(`${gtag_id}tagmanager`);

        const gtmId = document.createElement('script');
        gtmId.async = true;
        gtmId.id = `${gtag_id}tagmanager`;
        gtmId.src = `https://www.googletagmanager.com/gtag/js?id=${gtag_id}`;
        document?.getElementsByTagName('head')[0].appendChild(gtmId);

        let setUser = '';
        if (user.email !== '') {
            setUser = `gtag('set', 'user_properties',{ email: '${user.email}', domain: '${user.domain}' });`;
        } else {
            setUser = `gtag('set', 'user_properties',{ domain: '${user.domain}' });`;
        }

        // remove google tag script functions if exists
        this.sharedService.removeScript(gtag_id);

        const gtmscr = document.createElement('script');
        gtmscr.id = gtag_id;
        document?.getElementsByTagName('head')[0].appendChild(gtmscr).append(`window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '${gtag_id}');${setUser}`);
    }

    removeScript(elementId): void {
        const scriptElement = document.getElementById(elementId);

        if (scriptElement) {
            const headElement = scriptElement.parentNode;
            if (headElement !== null) {
                headElement.removeChild(scriptElement);
            }
        }
    }

    setGtagHead(logged): void {
        let advanced: any = '';

        const userObj = {
            email: '',
            domain: window.origin
        };

        if (logged) {
            const user = this.sharedService.getUserSync();

            if (user) {
                userObj.email = user.email;
            }
        }
        if (this.localStorageService.getLocalStorageItem('advanced')) {
            advanced = JSON.parse(localStorage.getItem('advanced') || '');
            if (advanced.google_analytics_id) {
                // GTAG client
                this.insertGtag(advanced.google_analytics_id, userObj);
            }
        }
        // GTAG Inicie
        // this.insertGtag('G-H9CLF4W3S7', userObj);
        this.insertGtag('G-DC7JXNJ046', userObj);
    }

    getImpersonatingStatus(): void {
        this.sharedService.impersonating.subscribe((impersonate:boolean) => {
            this.userIsImpersonating = impersonate;
        });
    }

    toggleMenuOnAside(): void {
        this.sidebar.toggleAside();
    }

    toggleExpandBtnOnHeader(): void {
        this.header.toggleExpandBtn();
    }

    setTitle(): void {
        // set undefined title to get default title from settings
        this.titleService.setTitleSite(undefined);
    }

    getCurrentLanguage(): void {
        const currentLanguage = localStorage.getItem('currentLanguage');
        this.canAccessibility = currentLanguage === 'pt-BR';
    }

    setLanguageFromURL(): void {
        this.route.queryParams.subscribe({
            next: (params) => {
                if (Object.prototype.hasOwnProperty.call(params, 'lang')) {
                    localStorage.setItem('currentLanguage', params.lang);
                }
            }
        });
    }
}
