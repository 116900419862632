import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DisciplineService } from 'src/app/pages/disciplines/discipline.service';


@Component({
  selector: 'app-modal-ementa',
  templateUrl: './modal-ementa.component.html',
  styleUrls: ['./modal-ementa.component.scss'],
})

export class ModalEmentaComponent implements OnInit, OnChanges {
  @Input() isVisibleModalEmenta: boolean = false;
  @Input() disciplineExternalID = '';
  @Input() description: string = '';
  @Input() i18n: any = {};
  @Output() update = new EventEmitter(); 
  @Output() close = new EventEmitter(); 
  form: FormGroup;

  resetEditor: boolean = true;
  constructor (private fb: FormBuilder, private disciplineService: DisciplineService) { }

  ngOnInit(): void {
    this.createForm();
  }

  ngOnChanges(): void {
    if (this.isVisibleModalEmenta) {
      this.resetEditor = true;
      this.form.controls['description']?.setValue(this.description)
    }
  }

  createForm(): void {
    this.form = this.fb.group({
      description: ['', Validators.required]
    })
  }

  updateDisciplineDescription(): void {
    if (this.form.valid) {
      const params = { description: this.form.controls['description'].value}
      this.disciplineService.patchDiscipline(this.disciplineExternalID, params).subscribe({
        next: () => {
          this.updateState();
          this.closeModal();
        }
      })
    }
    return
  }

  updateState(): void {
    this.update.emit(this.form.controls['description'].value);
  }

  closeModal(): void {
    this.resetEditor = false;
    this.close.emit(true);
  }
}

