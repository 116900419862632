import {
    AfterContentInit,
    Component,
    ElementRef,
    Input,
    ViewChild,
} from '@angular/core';

@Component({
    selector: 'app-plus-button',
    templateUrl: './plus-button.component.html',
    styleUrls: ['./plus-button.component.scss'],
})
export class PlusButtonComponent implements AfterContentInit {
    @ViewChild('addButton') addButton: ElementRef;
    @Input() label = '';
    @Input() fromRight = false;
    @Input() disabled = false;
    addButtonWidth: string;

    constructor() {}

    ngAfterContentInit(): void {
        this.addButtonWidth = `--width: ${this.addButton?.nativeElement?.scrollWidth}px`; /* setting the dropdown width when active */
    }
}
