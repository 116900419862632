import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';

import { AccountsService } from '../../accounts.service';

@Component({
    selector: 'app-use-terms',
    templateUrl: './use-terms.component.html',
    styleUrls: ['./use-terms.component.scss']
})
export class UseTermsComponent implements OnInit {
    i18n: any = [];

    constructor(private accountsService: AccountsService, private sharedService: SharedService) { }

    ngOnInit(): void {
        this.getTranslations();
    }

    getTranslations(): void {
        this.i18n = this.sharedService.getTranslationsOf('UseTerms');
    }

    userTerms(): void {
        this.accountsService.openModalPolicyAndPrivacy$.next({ openModal: true, readOnly: true });
    }
}
