/* eslint-disable no-underscore-dangle */

import { formatDate } from '@angular/common';
import {
    AfterViewInit, ChangeDetectorRef, Component, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild
} from '@angular/core';
import {
    FormControl,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { createMask } from '@ngneat/input-mask';
import { Subscription } from 'rxjs';
import { UploadInlineComponent } from 'src/app/components/upload-inline/upload-inline.component';
import { ScheduleResponse } from 'src/app/pages/disciplines/components/assessments/interfaces/assessment.interface';
import { SectionService } from 'src/app/pages/disciplines/components/content/section.service';
import { DisciplineDetailService } from 'src/app/pages/disciplines/components/disciplines-detail/discipline-detail.service';
import { SettingsLtiService } from 'src/app/pages/settings/components/settings-lti/settings-lti.service';
import { LocalStorageService } from 'src/app/services/localStorageService/local-storage.service';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';
import { minDateValidator } from 'src/app/utils/form-validators/form-validators';

import { collapseAnimation } from '../../../../../../../../utils/animations/animations';
import { ContentService } from '../../../../content.service';
import { ContentSharedService } from '../../../../content-shared.service';

@Component({
    selector: 'app-assignment',
    templateUrl: './assignment.component.html',
    styleUrls: ['./assignment.component.scss'],
    animations: [
        collapseAnimation('250')
    ],
})
export class AssignmentComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    isLoading$ = this.contentSharedService.isLoading$;
    assignment: any;
    assignmentForm: UntypedFormGroup;

    assignmentType: any[] = [];

    providersLTI: any = [];
    availableFields: any = [];
    ocultarAteInicio = false;
    criarForum = false;
    permitirEntregaAposTermino = false;
    avancoCondicional = false;
    recursoAvaliativo = false;
    description: any;
    content: any = [];
    contentLength: any;
    section: any = [];
    discipline_external_id: string;
    section_external_id: string;
    content_external_id: string;
    type_content: string;
    editMode: boolean;
    titleForm: string;
    urlScorm: string;
    arrFiles: any = [];
    docAssigFile: any;
    confOrganizer: any;
    users: any = [];
    organizadorConferencia = '';
    isIframable: any = null;
    urlInvalid: any = null;
    sections: any = [];
    contents: any = [];
    files: any = [];
    displayBasic: boolean;
    textError = '';
    maxFiles: number;
    modalOpen$ = this.sharedService.modalOpen$;
    isDescriptionValid = false;
    section_title: string;
    enableButton = false;

    contentForm: any = [];
    selectedCriteria: any;
    showTimeSelect: any;
    selectedOptions: any;
    showAttempts: any;
    // showConditionalAdvance: any;
    completionRuleValue: any;
    optionsConditional: any = [];
    currentLanguage: string | null;
    tranlateCriterionAndAdvance: any = [];
    i18n: any = [];
    ready = false;
    contentSpecific: any;
    minDate = new Date();

    available_grade = 0;
    substitute_activity: any = {};
    substitute_activity_send: any = {};
    messageErrorEditRubric = false;

    rubric_edited: any;

    // tipo de arquivo para o upload. Por padrão é docs
    fileType = 'docs';
    syncWithDrive: boolean;

    gradesConfig: any = {};
    periodParams: any = {};

    isGoogleButtonsLocked: boolean;

    // Tipos de Conteúdo
    tarefa: any = {
        nomeTarefa: true,
        editor: true,
        providerLTI: false,
        organizadorConferencia: false,
        url: false,
        envioArquivoTarefa: false,
        envioArquivo: false,
        startDate: true,
        endDate: true,
        ocultarAteInicio: true,
        createForum: true,
        permitirEntregaAposTermino: true,
        avancoCondicional: true,
        recursoAvaliativo: true,
        idResourceLTI: false,
        tipoTarefa: true,
        maxFiles: false,
    };

    arquivoDrive: any = {
        nomeTarefa: true,
        editor: true,
        providerLTI: false,
        organizadorConferencia: false,
        url: false,
        envioArquivo: true,
        startDate: true,
        endDate: false,
        ocultarAteInicio: true,
        createForum: true,
        permitirEntregaAposTermino: true,
        avancoCondicional: true,
        recursoAvaliativo: true,
        idResourceLTI: false,
        tipoTarefa: false,
        maxFiles: false,
    };

    paginaTexto: any = {
        nomeTarefa: true,
        editor: true,
        providerLTI: false,
        organizadorConferencia: false,
        url: false,
        envioArquivo: false,
        startDate: true,
        endDate: false,
        ocultarAteInicio: true,
        createForum: true,
        permitirEntregaAposTermino: true,
        avancoCondicional: true,
        recursoAvaliativo: true,
        idResourceLTI: false,
        tipoTarefa: false,
        maxFiles: false,
    };

    url: any = {
        nomeTarefa: true,
        editor: true,
        providerLTI: false,
        organizadorConferencia: false,
        url: true,
        envioArquivo: false,
        startDate: true,
        endDate: false,
        ocultarAteInicio: true,
        createForum: true,
        permitirEntregaAposTermino: true,
        avancoCondicional: true,
        recursoAvaliativo: true,
        idResourceLTI: false,
        tipoTarefa: false,
        maxFiles: false,
    };

    videoYoutube: any = {
        nomeTarefa: true,
        editor: true,
        providerLTI: false,
        organizadorConferencia: false,
        url: true,
        envioArquivo: false,
        startDate: true,
        endDate: true,
        ocultarAteInicio: true,
        createForum: true,
        permitirEntregaAposTermino: true,
        avancoCondicional: true,
        recursoAvaliativo: true,
        idResourceLTI: false,
        tipoTarefa: false,
        maxFiles: false,
    };

    conferencia: any = {
        nomeTarefa: true,
        editor: true,
        providerLTI: false,
        organizadorConferencia: true,
        url: false,
        envioArquivo: false,
        startDate: false,
        endDate: false,
        ocultarAteInicio: false,
        createForum: true,
        permitirEntregaAposTermino: true,
        avancoCondicional: true,
        recursoAvaliativo: true,
        idResourceLTI: false,
        tipoTarefa: false,
        maxFiles: false,
        startDateConference: true,
        endDateConference: true,
    };

    questionario: any = {
        nomeTarefa: true,
        editor: true,
        providerLTI: false,
        url: false,
        envioArquivo: false,
        startDate: true,
        endDate: true,
        ocultarAteInicio: true,
        createForum: true,
        permitirEntregaAposTermino: true,
        avancoCondicional: true,
        recursoAvaliativo: true,
        idResourceLTI: false,
        maxFiles: false,
    };

    questionarioExterno: any = {
        nomeTarefa: true,
        editor: true,
        providerLTI: false,
        organizadorConferencia: false,
        url: false,
        envioArquivo: false,
        startDate: true,
        endDate: true,
        ocultarAteInicio: true,
        createForum: true,
        permitirEntregaAposTermino: false,
        avancoCondicional: true,
        recursoAvaliativo: true,
        idResourceLTI: false,
        tipoTarefa: true,
        maxFiles: false,
    };

    externa: any = {
        nomeTarefa: true,
        editor: true,
        providerLTI: false,
        organizadorConferencia: false,
        url: false,
        envioArquivo: false,
        startDate: true,
        endDate: true,
        ocultarAteInicio: true,
        createForum: true,
        permitirEntregaAposTermino: true,
        avancoCondicional: true,
        recursoAvaliativo: true,
        idResourceLTI: false,
        tipoTarefa: false,
        maxFiles: false,
    };

    scorm: any = {
        nomeTarefa: true,
        editor: true,
        providerLTI: false,
        organizadorConferencia: false,
        url: false,
        envioArquivo: true,
        startDate: true,
        endDate: false,
        ocultarAteInicio: true,
        createForum: true,
        permitirEntregaAposTermino: true,
        avancoCondicional: true,
        recursoAvaliativo: true,
        idResourceLTI: false,
        tipoTarefa: false,
        maxFiles: false,
    };

    lti: any = {
        nomeTarefa: true,
        editor: true,
        providerLTI: true,
        organizadorConferencia: false,
        url: false,
        envioArquivo: false,
        startDate: true,
        endDate: false,
        ocultarAteInicio: true,
        createForum: true,
        permitirEntregaAposTermino: true,
        avancoCondicional: true,
        recursoAvaliativo: true,
        idResourceLTI: true,
        tipoTarefa: false,
        maxFiles: false,
    };

    forum: any = {
        nomeTarefa: true,
        editor: true,
        providerLTI: false,
        organizadorConferencia: false,
        url: false,
        envioArquivo: false,
        startDate: true,
        endDate: true,
        ocultarAteInicio: true,
        createForum: false,
        permitirEntregaAposTermino: true,
        avancoCondicional: true,
        recursoAvaliativo: true,
        idResourceLTI: false,
        tipoTarefa: false,
        maxFiles: false,
    };

    printedExam: any = {
        nomeTarefa: true,
        editor: true,
        providerLTI: false,
        organizadorConferencia: false,
        url: false,
        envioArquivo: false,
        startDate: false,
        endDate: false,
        ocultarAteInicio: true,
        createForum: false,
        permitirEntregaAposTermino: false,
        avancoCondicional: true,
        recursoAvaliativo: true,
        idResourceLTI: false,
        tipoTarefa: false,
        maxFiles: false,
    };

    scheduleAssignment = false;
    initialDate: Date = new Date();
    allowLateSubmission = false;
    enableFieldAllowLateSubmisson = false;

    endDateInputMask: any;
    endDateInputValue: any;

    startDateInputMask: any;
    startDateInputValue: any;
    checker: any;
    isFieldAttachedForumDisabled = false;

    @ViewChild(UploadInlineComponent) UploadInlineComponent!: UploadInlineComponent;
    isModalFileSharingOpen: boolean;
    googlePostParams: any;
    hasModalFileSharing = false;
    flowMethod = '';
    classId: number;
    trieducClassId: string;
    labelSchedulerContent = '';

    showReplaceBtn = true;
    showRemoveBtn = true;
    isUploadLoading = false;

    valueSubstituteActivity = false;

    subscriptions: Subscription[] = [];
    timeMask = createMask<Date>({
        alias: 'datetime',
        inputFormat: 'HH:MM',
        placeholder: 'HH:MM',
    });

    selectedAssessmentType = '';
    isEvaluationBankOpen = false;
    scheduleSelected: ScheduleResponse | null = null;
    scheduleSelectedId: string;

    attemptsGetVisualizationWithBackoff = 0;
    hasVisualizationWithBackoff = false;
    private timeoutIdBackoff;

    constructor(
        private contentSharedService: ContentSharedService,
        private platModalsService: PlatformModalsService,
        private contentService: ContentService,
        public sectionService: SectionService,
        private settingsLtiService: SettingsLtiService,
        public sharedService: SharedService,
        private FormBuilder: UntypedFormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private localStorageService: LocalStorageService,
        private cd: ChangeDetectorRef,
        private disciplineDetailService: DisciplineDetailService

    ) {
        this.checker = setInterval(() => {
            // the following is required, otherwise the view will not be updated
            this.cd.markForCheck();
        }, 75);
    }

    ngOnInit(): void {
        // padrão é verdadeiro
        this.contentSharedService.syncWithDrive$.next(true);
        this.getTranslations();
        this.getUrlParams();
        this.getTranslationsAdvanceCriteria();
        this.createAssignmentForm();
        this.getAssignmentForm();
        this.getSavedDataInCreateContent();
        this.getCriterionsFromLocal();
        this.getDisciplineBasic();
        this.getProvider();
    }

    ngAfterViewInit(): void {
        this.getContentForm();
        this.loadContents();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes) {
            console.log('isGoogleButtonsLocked', this.isGoogleButtonsLocked);
        }
    }

    provider = '';
    getProvider() {
        this.provider = (localStorage.getItem('provider') || '');
    }

    getDisciplineBasic() {
        const sub = this.disciplineDetailService.disciplineBasic$
            .subscribe({
                next: (disciplineDetail) => {
                    if (disciplineDetail) {
                        this.trieducClassId = disciplineDetail.trieduc_class_id;

                        if (disciplineDetail.participants) {
                            this.users = disciplineDetail.participants?.teacher?.filter((e) => e.canBeConferenceOrganizer === true);

                            if (this.editMode && this.content?.contentSpecific?.conferenceOrganizer !== null) {
                                this.assignmentForm.controls.organizadorConferencia.setValue(this.content?.contentSpecific?.conferenceOrganizer?.external_id);
                            }
                        }

                        if (disciplineDetail.type && disciplineDetail.type === 'template') {
                            this.flowMethod = 'platform';
                            return;
                        }

                        if (disciplineDetail.general_upload_method !== 'both') {
                            return;
                        }

                        if (
                            (disciplineDetail.external_drive && disciplineDetail.platform)
                            && (this.assignment === 'File')) {
                            this.hasModalFileSharing = true;
                            return;
                        }

                        if (disciplineDetail.external_drive) {
                            this.flowMethod = 'external_drive';
                            return;
                        }

                        if (disciplineDetail.platform) {
                            this.flowMethod = 'platform';
                        }
                    }
                }
            });
        this.subscriptions.push(sub);
    }

    isShowLoading() {
        let loading = true;
        const sub = this.isLoading$.subscribe((val) => (loading = val));
        this.subscriptions.push(sub);
        return loading;
    }

    loadingFalse() {
        this.platModalsService.close('loading');
    }

    getTranslationsAdvanceCriteria() {
        this.tranlateCriterionAndAdvance = {
            ...this.sharedService.getTranslationsOf('AdvanceAndCriterion'),
            ...this.sharedService.getTranslationsOf('Modal'),
        };
        this.currentLanguage = localStorage.getItem('currentLanguage');
    }

    getUpdatedTranslationsAdvanceCriteria() {
        const sub = this.sharedService.translationsUpdate$.subscribe((translations: any) => {
            this.tranlateCriterionAndAdvance = translations?.AdvanceAndCriterion;
        });

        this.subscriptions.push(sub);
    }

    loadTypeTask(): void {
        this.assignmentType = [];

        if (this.localStorageService.getLocalStorageItem('provider')) {
            if (localStorage.getItem('provider') !== 'GOOGLE_CLOUD_STORAGE') {
                this.assignmentType.push({
                    icon: 'simple-icons:googlesheets', color: '#47a166', type: 'SheetsAssignment', nameSelect: `${this.i18n.disciplines_type_new_sheets}`, nameButton: `${this.i18n.disciplines_attach_sheets}`
                });
                this.assignmentType.push({
                    icon: 'simple-icons:googleforms', color: '#7053b9', type: 'UploadQuiz', nameSelect: `${this.i18n.disciplines_type_new_upload_quiz}`, nameButton: `${this.i18n.disciplines_attach_upload_quiz}`
                });
                this.assignmentType.push({
                    icon: 'simple-icons:googledocs', color: '#3e8bf4', type: 'DocsAssignment', nameSelect: `${this.i18n.disciplines_type_new_docs}`, nameButton: `${this.i18n.disciplines_attach_docs}`
                });
                this.assignmentType.push({
                    icon: 'logos:google-drive', color: '', type: 'UploadAssignment', nameSelect: `${this.i18n.disciplines_type_upload_drive_file}`, nameButton: `${this.i18n.disciplines_type_upload_drive_file}`
                });
                return;
            }

            this.assignmentType.push({ type: 'UploadAssignment', nameSelect: this.i18n.disciplines_type_upload_assignment });
        }
    }

    getTranslations() {
        this.i18n = {
            ...this.sharedService.getTranslationsOf('AdvanceAndCriterion'),
            ...this.sharedService.getTranslationsOf('Disciplines'),
            ...this.sharedService.getTranslationsOf('Modal'),
            ...this.sharedService.getTranslationsOf('Errors'),
            ...this.sharedService.getTranslationsOf('ContentPlanner'),
            ...this.sharedService.getTranslationsOf('Upload'),
            ...this.sharedService.getTranslationsOf('Grades')

        };

        this.loadTypeTask();

        // this.tipoTarefa.forEach(element => {
        //   if (element.type == 'DocsAssignment') {
        //     element.name =
        //   }
        // });
        // if (this.i18n.disciplines_upload_assignment) {

        //   this.tipoTarefa['DocsAssignment'].name = this.i18n.disciplines_upload_assignment;
        // }
        // if (this.i18n.disciplines_docs_assignment) {

        //   this.tipoTarefa['UploadAssignment'].name = this.i18n.disciplines_docs_assignment;
        // }
        // if (this.i18n.disciplines_sheets_assignment) {

        //   this.tipoTarefa['DocsAssignment'].name = this.i18n.disciplines_sheets_assignment;
        // }

        this.currentLanguage = localStorage.getItem('currentLanguage');
    }

    loadingTrue() {
        this.platModalsService.toggle('loading');
    }

    // Forms
    createAssignmentForm() {
        this.assignmentForm = this.FormBuilder.group({
            nomeTarefa: ['', Validators.required],
            descriptionEditor: [''],
            providerLTI: [''],
            id_LTI_resource: [''],
            tipoTarefa: [''],
            url: [''],
            maxFiles: ['1'],
            organizadorConferencia: [''],
            envioArquivo: [''],
            startDate: [new Date()],
            calendarStartDate: ['', [this.minDateStartDateValidator.bind(this)]],
            endDate: [''],
            calendarEndDate: ['', [this.minDateValidator.bind(this)]],
            avancoCondicional: [''],
            /*  completionCriteria: ['',], */
            lengthOfstay: ['', [Validators.max(120), Validators.min(1)]],
            attempt_sub: ['1'],
            allowLateSubmission: [true],
            hideUntilStart: [false],
            createAttachedForum: [false],
            calendarApplicationDate: ['', [this.minDatePrintedDateValidator.bind(this)]],
            calendarPublishGradeDate: ['', [this.minDatePrintedValidator.bind(this)]],
            resources: [''],
            local: [''],
            application_date: [''],
            publish_grade_date: [''],
            duration: [''],
            exam_external_id: [''],
            exam_type: [''],
            exam_exam_description: [''],
            exam_exam_name: [''],
        });

        this.assignmentForm.get('lengthOfstay')?.valueChanges.subscribe((value) => {
            this.completionRuleValue = value;
        });

        this.assignmentForm.get('attempt_sub')?.valueChanges.subscribe((value) => {
            this.completionRuleValue = value;
        });

        this.assignmentForm.get('startDate')?.valueChanges.subscribe((value) => {
            const StartDate = value;
            const EndDate = this.assignmentForm.get('endDate');

            if (EndDate !== null) {
                if (StartDate > EndDate.value) {
                    this.assignmentForm.controls.calendarEndDate.setValue(EndDate.value);
                }
            }
        });
    }

    get lengthOfstayValid() {
        return this.assignmentForm.get('lengthOfstay');
    }

    get descriptionEditor() {
        return this.assignmentForm.get('descriptionEditor');
    }

    get nomeTarefa() {
        return this.assignmentForm.get('nomeTarefa');
    }

    get urlValid() {
        return this.assignmentForm.get('url');
    }

    get startDate() {
        return this.assignmentForm.get('startDate');
    }

    get endDate() {
        return this.assignmentForm.get('endDate');
    }

    get application_date() {
        return this.assignmentForm.get('application_date');
    }

    get publish_grade_date() {
        return this.assignmentForm.get('publish_grade_date');
    }

    // Seta no formulário de criação de conteúdo os valores salvos quando vai para a rubrica
    getSavedDataInCreateContent(): void {
        const sub = this.contentService.saveDataCreatedContent$.subscribe({
            next: (res) => {
                if (Object.keys(res).length) {
                    const response = res.payload;
                    this.setFormValues(response);
                    this.gradesConfig = response?.grades_config;
                    this.contentSharedService.gradesConfig$.next(response?.grades_config);
                    this.files = res.files;
                }
            }
        });

        this.subscriptions.push(sub);
    }

    descriptionContentLabel = '';
    nameContentLabel = '';
    urlLabel = '';

    getAssignmentForm() {
        // Adds the label name dynamically and sets a default message if it doesn't exist
        this.labelSchedulerContent = this.i18n[`discipline_label_scheduler_content_${this.assignment}`];
        this.labelSchedulerContent = this.labelSchedulerContent ?? this.i18n.disciplines_schedule_assignment;

        switch (this.assignment) {
            // O caso 'Assignment' ocorre somente no post, o tipo de conteúdo ele escolhe no próprio
            // formulário e eles podem ser: UploadAssignment, DocsAssignment SheetsAssignment e UploadQuiz
            case 'Assignment':
                this.availableFields = this.tarefa;
                this.titleForm = this.returnTextBasedInEditMode(`${this.i18n.disciplines_docs_assignment}`, `${this.i18n.disciplines_new_docs_assignment}`);
                this.descriptionContentLabel = this.i18n.disciplines_assignment_description;
                this.nameContentLabel = this.i18n.disciplines_task_name;
                this.maxFiles = 1;
                break;
            case 'DocsAssignment':
                this.availableFields = this.tarefa;
                this.titleForm = this.returnTextBasedInEditMode(`${this.i18n.disciplines_docs_assignment}`, `${this.i18n.disciplines_new_docs_assignment}`);
                this.descriptionContentLabel = this.i18n.disciplines_assignment_description;
                this.nameContentLabel = this.i18n.disciplines_task_name;
                break;
            case 'SheetsAssignment':
                this.availableFields = this.tarefa;
                this.titleForm = this.returnTextBasedInEditMode(`${this.i18n.disciplines_docs_assignment}`, `${this.i18n.disciplines_new_docs_assignment}`);
                this.descriptionContentLabel = this.i18n.disciplines_assignment_description;
                this.nameContentLabel = this.i18n.disciplines_task_name;
                break;
            case 'UploadAssignment':
                this.availableFields = this.tarefa;
                this.titleForm = this.returnTextBasedInEditMode(`${this.i18n.disciplines_docs_assignment}`, `${this.i18n.disciplines_new_docs_assignment}`);
                this.descriptionContentLabel = this.i18n.disciplines_assignment_description;
                this.nameContentLabel = this.i18n.disciplines_task_name;
                break;
            case 'UploadQuiz':
                this.availableFields = this.tarefa;
                this.titleForm = this.returnTextBasedInEditMode(`${this.i18n.disciplines_upload_quiz_external}`, `${this.i18n.disciplines_new_upload_quiz_external}`);
                this.descriptionContentLabel = this.i18n.disciplines_assignment_description;
                this.nameContentLabel = this.i18n.disciplines_task_name;
                this.fileType = 'forms';
                this.maxFiles = 1;
                this.assignmentForm.controls.tipoTarefa.setValue('UploadQuiz');
                this.fnChangeAssigmentType('UploadQuiz');
                break;
            case 'File':
                this.availableFields = this.arquivoDrive;
                this.titleForm = this.returnTextBasedInEditMode(`${this.i18n.disciplines_page_file}`, `${this.i18n.disciplines_new_page_file}`);
                this.descriptionContentLabel = this.i18n.disciplines_content_description;
                this.nameContentLabel = this.i18n.disciplines_page_file_name;
                this.maxFiles = 4;
                this.fileType = 'noForms';
                break;
            case 'Youtube':
                this.availableFields = this.videoYoutube;
                this.titleForm = this.returnTextBasedInEditMode(`${this.i18n.disciplines_youtube}`, `${this.i18n.disciplines_new_youtube}`);
                this.descriptionContentLabel = this.i18n.disciplines_content_description;
                this.nameContentLabel = this.i18n.disciplines_youtube_name;
                this.urlLabel = this.i18n.disciplines_youtube_url;
                break;
            case 'TextPage':
                this.availableFields = this.paginaTexto;
                this.titleForm = this.returnTextBasedInEditMode(`${this.i18n.disciplines_text_page}`, `${this.i18n.disciplines_new_text_page}`);
                this.descriptionContentLabel = this.i18n.disciplines_content_description;
                this.nameContentLabel = this.i18n.disciplines_text_page_name;
                break;
            case 'Link':
                this.availableFields = this.url;
                this.titleForm = this.returnTextBasedInEditMode(`${this.i18n.disciplines_link}`, `${this.i18n.disciplines_new_link}`);
                this.descriptionContentLabel = this.i18n.disciplines_content_description;
                this.nameContentLabel = this.i18n.disciplines_link_name;
                this.urlLabel = 'Link';
                break;
            case 'Forum':
                this.availableFields = this.forum;
                this.titleForm = this.returnTextBasedInEditMode(`${this.i18n.disciplines_forum}`, `${this.i18n.disciplines_new_forum}`);
                this.descriptionContentLabel = this.i18n.disciplines_content_description;
                this.nameContentLabel = this.i18n.disciplines_forum_name;
                break;
            case 'Conference':
                this.availableFields = this.conferencia;
                this.titleForm = this.returnTextBasedInEditMode(`${this.i18n.disciplines_conference}`, `${this.i18n.disciplines_new_conference}`);
                this.descriptionContentLabel = this.i18n.disciplines_content_description;
                this.nameContentLabel = this.i18n.disciplines_conference_name;
                this.fnChangeHideUntilStart();
                break;
            case 'InternalConference':
                this.availableFields = this.conferencia;
                this.titleForm = this.returnTextBasedInEditMode(`${this.i18n.disciplines_conference}`, `${this.i18n.disciplines_new_conference}`);
                this.descriptionContentLabel = this.i18n.disciplines_content_description;
                this.nameContentLabel = this.i18n.disciplines_conference_name;
                this.fnChangeHideUntilStart();
                break;
            case 'InternalQuiz':
                this.availableFields = this.questionario;
                this.titleForm = this.returnTextBasedInEditMode(`${this.i18n.disciplines_internal_quiz}`, `${this.i18n.disciplines_new_internal_quiz}`);
                this.descriptionContentLabel = this.i18n.disciplines_content_description;
                this.nameContentLabel = this.i18n.disciplines_internal_quiz_name;
                this.enableButton = true;
                break;
            case 'External':
                this.availableFields = this.externa;
                this.titleForm = this.returnTextBasedInEditMode(`${this.i18n.disciplines_external}`, `${this.i18n.disciplines_new_external}`);
                this.descriptionContentLabel = this.i18n.disciplines_content_description;
                this.nameContentLabel = this.i18n.disciplines_external_name;

                break;
            case 'Scorm':
                this.availableFields = this.scorm;
                this.titleForm = this.returnTextBasedInEditMode(`${this.i18n.disciplines_scorm}`, `${this.i18n.disciplines_new_scorm}`);
                this.descriptionContentLabel = this.i18n.disciplines_content_description;
                this.nameContentLabel = this.i18n.disciplines_scorm_name;
                this.maxFiles = 1;
                break;
            case 'LTI':
                this.availableFields = this.lti;
                this.titleForm = this.returnTextBasedInEditMode(`${this.i18n.disciplines_page_lti}`, `${this.i18n.disciplines_new_page_lti}`);
                this.descriptionContentLabel = this.i18n.disciplines_content_description;
                this.nameContentLabel = this.i18n.disciplines_page_lti_name;
                break;
            case 'PrintedExam':
                this.availableFields = this.printedExam;
                this.titleForm = this.returnTextBasedInEditMode(`${this.i18n.disciplines_content_exam_new}`, `${this.i18n.disciplines_new_page_lti}`);
                this.descriptionContentLabel = this.i18n.disciplines_content_exam;
                this.nameContentLabel = this.i18n.disciplines_content_exam_name;
                break;

            default:
                break;
        }
    }

    returnTextBasedInEditMode(textNoEditMode: string, textInEditMode: string) {
        if (this.editMode) {
            return textInEditMode;
        }
        return textNoEditMode;
    }

    getUrlParams() {
        this.route?.params?.subscribe((params) => {
            this.discipline_external_id = this.router?.url.split('/')[2];
            this.section_external_id = params?.section;
            this.content_external_id = params?.content;
            this.assignment = params?.type;
            this.editMode = Boolean(Number(params?.edit));
            this.section_title = params.section_title;
            this.rubric_edited = params.rubric_edited;
            this.classId = params.class_id;
        });
    }

    setFormValues(content: any) {
        this.content = content;

        // this.selectedCriteria = this.content?.completion_rule;

        // hide_until_start
        this.assignmentForm.controls.hideUntilStart.setValue(content?.hide_until_start);
        this.scheduleAssignment = content?.hide_until_start;

        // this.createForum = content?.allow_late_submission; PENDENTE
        this.permitirEntregaAposTermino = content?.allow_late_submission;

        if (content.type === 'Youtube' || content.type === 'Link') {
            this.assignmentForm.controls.url.setValue(content?.contentSpecific?.link_site);
        }

        if (content.type === 'LTI') {
            // Colocar id do recurso
            this.assignmentForm.controls.id_LTI_resource.setValue(
                content?.contentSpecific?.lti_content_id
            );
            this.assignmentForm.controls.providerLTI.setValue(
                content?.contentSpecific?.lti_provider_id
            );
        }

        if (content.type === 'UploadAssignment') {
            this.assignmentForm.controls.tipoTarefa.setValue('UploadAssignment');
            this.fnChangeAssigmentType('UploadAssignment');
            this.assignmentForm.controls.maxFiles.setValue(
                content?.contentSpecific?.maxFiles
            );
        }

        if (content.type === 'DocsAssignment') {
            this.assignmentForm.controls.tipoTarefa.setValue('DocsAssignment');
            this.fnChangeAssigmentType('DocsAssignment');
        }

        if (content.type === 'SheetsAssignment') {
            this.assignmentForm.controls.tipoTarefa.setValue('SheetsAssignment');
            this.fnChangeAssigmentType('SheetsAssignment');
        }

        if (content.type === 'UploadQuiz') {
            this.assignmentForm.controls.tipoTarefa.setValue('UploadQuiz');
            this.fnChangeAssigmentType('UploadQuiz');
        }

        if (content.type === 'PrintedExam') {
            this.assignmentForm.controls.resources.setValue(content?.contentSpecific?.resources);
            this.assignmentForm.controls.local.setValue(content?.contentSpecific?.local);

            const applicationDate = new Date(content?.contentSpecific?.application_date);
            this.assignmentForm.controls.application_date.setValue(applicationDate);
            this.assignmentForm.controls.calendarApplicationDate.setValue(applicationDate);

            const publishDate = new Date(content?.contentSpecific?.publish_grade_date);
            this.assignmentForm.controls.publish_grade_date.setValue(publishDate);
            this.assignmentForm.controls.calendarPublishGradeDate.setValue(publishDate);

            this.assignmentForm.controls.duration.setValue(content?.contentSpecific?.duration);
            this.assignmentForm.controls.exam_external_id.setValue(content?.contentSpecific?.exam_external_id);
            this.assignmentForm.controls.exam_exam_name.setValue(content?.contentSpecific?.exam_name);
            this.assignmentForm.controls.exam_exam_description.setValue(content?.contentSpecific?.exam_description);
            this.assignmentForm.controls.exam_type.setValue(content?.contentSpecific?.type);
            this.selectedAssessmentType = content?.contentSpecific?.type;

            this.setScheduleSelectedEdit(content?.contentSpecific);
            this.fnChangeAssigmentType('PrintedExam');
        }

        this.receivesSelectedCriteria(this.content.completion_rule);

        this.assignmentForm.controls.attempt_sub.setValue(content?.completion_rule_value);

        const end_date = content?.end_date == '' || content?.end_date == null ? '' : new Date(content?.end_date);

        let start_date = content?.start_date == '' || content?.start_date == null ? '' : new Date(content?.start_date);

        if (content?.hide_until_start) {
            if (content?.start_date == null || content?.start_date == '') {
                start_date = new Date();
            }
        }

        // // verificar se conditional_content dentro do content vem vazio. Se sim, puxar todos os conteúdos da seção corrente
        if (content.conditional_content_enable) {
            this.avancoCondicional = true;
            this.assignmentForm.controls.avancoCondicional.setValue(content?.conditional_content_id);
        } else {
            this.avancoCondicional = false;
        }

        this.assignmentForm.controls.nomeTarefa.setValue(content?.title);
        // this.assignmentForm.controls['url'].setValue(this.section?.order);

        // StartDate setValue
        this.assignmentForm.controls.startDate.setValue(start_date);
        this.assignmentForm.controls.calendarStartDate.setValue(start_date);

        // EndDate setValue
        this.assignmentForm.controls.endDate.setValue(end_date);
        this.assignmentForm.controls.calendarEndDate.setValue(end_date);
        this.fnPickDateToInput();

        this.assignmentForm.controls.descriptionEditor.setValue(
            content?.description
        );
        this.assignmentForm.controls.lengthOfstay.setValue(
            this.completionRuleValue
        );

        this.assignmentForm.controls.allowLateSubmission.setValue(
            content?.allow_late_submission
        );
        this.allowLateSubmission = content?.allow_late_submission;

        // this.assignmentForm.controls['lengthOfstay'].setValue(content?.description);
        this.assignmentForm.controls.organizadorConferencia.setValue(
            content?.contentSpecific?.conferenceOrganizer === null
                ? null
                : content?.contentSpecific?.conferenceOrganizer?.external_id
        );

        this.assignmentForm.controls.createAttachedForum.setValue(
            content?.create_attached_forum
        );

        if (content?.create_attached_forum === true) {
            this.assignmentForm.get('createAttachedForum')?.disable();
            this.isFieldAttachedForumDisabled = true;
        }
    }

    openUploadTemplateAssignment() {
        if (this.isGoogleButtonsLocked) {
            return;
        }
        if (this.provider !== 'GOOGLE_CLOUD_STORAGE') {
            this.files = [];
            setTimeout(() => {
                this.UploadInlineComponent.btnUploadGoogle.nativeElement.click();
            }, 100);
        }
    }

    fnChangeAssigmentType(type?: string) {
        const assignmentType = !type ? this.assignmentForm.controls.tipoTarefa.value : type;

        this.assignmentForm.controls.tipoTarefa.setValue(assignmentType);
        this.selectedCriteria = undefined;
        this.assignment = assignmentType;
        if (!this.editMode) {
            this.files = [];
        }

        this.availableFields.maxFiles = false;
        this.availableFields.envioArquivoTarefa = false;

        if (assignmentType === 'UploadAssignment') {
            this.availableFields.maxFiles = true;
            this.availableFields.envioArquivoTarefa = false;
            this.fileType = 'noForms';
            this.flowMethod = 'platform';
        }

        if (assignmentType === 'UploadQuiz') {
            this.flowMethod = 'external_drive';
            this.fileType = 'forms';
        }

        if (assignmentType === 'SheetsAssignment') {
            this.availableFields.envioArquivoTarefa = true;
            this.fileType = 'sheets';
            this.flowMethod = 'platform';
        }

        if (assignmentType === 'DocsAssignment') {
            this.availableFields.envioArquivoTarefa = true;
            this.fileType = 'document';
            this.flowMethod = 'platform';
        }
    }

    fnChangeHideUntilStart() {
        this.scheduleAssignment = !this.scheduleAssignment;
        this.assignmentForm.controls.hideUntilStart.setValue(this.scheduleAssignment);

        if (this.scheduleAssignment) {
            // Seta a data atual com 1 minutos a mais
            const date = new Date(new Date().setMinutes(new Date().getMinutes() + 1));
            const formatDateValueStartDate = this.currentLanguage === 'en' ? formatDate(date, 'MM/dd/yyyy HH:mm', 'en-US') : formatDate(date, 'dd/MM/yyyy HH:mm', 'pt-BR');

            this.assignmentForm.controls.startDate.setValue(date);
            this.assignmentForm.controls.calendarStartDate.setValue(formatDateValueStartDate);
        } else {
            this.assignmentForm.controls.startDate.setValue(null);
            this.assignmentForm.controls.calendarStartDate.setValue(null);
        }
    }

    // Necessary condition as it was causing problems in exchanging value
    fnChangeAllowLateSubmission(): void {
        if (this.editMode) {
            this.allowLateSubmission = !this.allowLateSubmission;
            this.assignmentForm.controls.allowLateSubmission.setValue(this.allowLateSubmission);
        } else {
            this.assignmentForm.controls.allowLateSubmission.setValue(this.allowLateSubmission);
            this.allowLateSubmission = !this.allowLateSubmission;
        }
    }

    returnButtonText(assignment: string): string {
        const contentIdentifier = this.getIdentifier(assignment);

        if (this.scheduleAssignment && this.editMode) {
            return this.i18n[`disciplines_save_schedule_${contentIdentifier}`];
        }

        if (this.scheduleAssignment && !this.editMode) {
            return this.i18n[`disciplines_schedule_${contentIdentifier}`];
        }

        if (this.editMode) {
            return this.i18n[`disciplines_save_assign_${contentIdentifier}`];
        }

        return this.i18n[`disciplines_assign_${contentIdentifier}`];
    }

    getIdentifier(assignment: string): string {
        if (assignment.includes('Assignment') || assignment.includes('UploadQuiz')) {
            return 'task';
        }

        if (assignment.includes('InternalQuiz') || assignment === 'PrintedExam') {
            return 'quiz';
        }

        return 'activity';
    }

    loadContents() {
        if (!this.editMode) this.loadingTrue();

        this.sectionService
            .getSections(this.discipline_external_id)
            .subscribe((sections: any) => {
                this.sectionService.sections = sections;
                this.contents = [];
                this.sections = sections.filter(
                    (i: any) => i.external_id === this.section_external_id
                );
                this.contents = this.sections.map((i: any) => i.contents);

                if (this.editMode) {
                    this.contents = this.contents.filter(
                        (i: any) => i.external_id !== this.content_external_id
                    );
                }

                this.contentLength = this.contents[0].length;
                if (!this.editMode) this.loadingFalse();
            });
    }

    criterions: any = [];
    setupCriterions(content: any) {
        if (!content.criterions.length) {
            return;
        }

        content.criterions.forEach((criterion) => {
            criterion.max_rubric_points = content?.max_rubric_points;
            criterion.deleted = false;
            criterion.levels.forEach((level) => {
                level.deleted = false;
            });
        });

        localStorage.setItem('criterionsList', JSON.stringify(content.criterions));

        this.criterions = content.criterions;
    }

    getCriterionsFromLocal() {
        const criterionsListLocal = localStorage.getItem('criterionsList') !== null ? JSON.parse(localStorage.getItem('criterionsList') || '') : null;
        // Rubric edited
        if (this.rubric_edited == '1' && criterionsListLocal !== null) {
            this.criterions = criterionsListLocal;
        }
    }

    isFormCompleted = false;
    getContentForm() {
        if (this.assignment === 'LTI') {
            this.settingsLtiService.getLtiSettings().subscribe((providersLTI) => {
                this.providersLTI = providersLTI;
            });
        }

        // Passar value false para não sincronizar com o Drive
        if (this.assignment === 'UploadQuiz') {
            this.contentSharedService.syncWithDrive$.next(false);
        }
        if (this.editMode) {
            this.hasVisualizationWithBackoff = true;

            if (this.attemptsGetVisualizationWithBackoff === 0) {
                this.loadingTrue();
            }

            this.contentService
                .getContentForm(
                    this.discipline_external_id,
                    this.section_external_id,
                    this.content_external_id
                )
                .subscribe({
                    next: (content: any) => {
                        this.clearVisualizationBackoff();

                        content.external_id = this.content_external_id;
                        this.substitute_activity_send = content.substitute_activity;

                        // Not edited rubric
                        if (this.rubric_edited !== '1') {
                            this.setupCriterions(content);
                        }
                        // carrega os arquivos para serem acessados pelo component de Upload
                        if (
                            content?.contentSpecific !== null
                            && content?.type !== 'DocsAssignment'
                            && content?.type !== 'SheetsAssignment'
                        ) {
                            this.files = content.contentSpecific.files || [];
                            this.sharedService.replaceLinkFile(this.files);
                        }

                        // carrega os arquivos para serem acessados pelo component de Upload
                        if (
                            content?.contentSpecific !== null
                            && content?.type === 'UploadQuiz'
                        ) {
                            this.files = content.contentSpecific.files || [];
                            this.sharedService.replaceLinkFile(this.files);
                        }

                        if (content?.contentSpecific?.template !== null && content?.contentSpecific?.template !== undefined) {
                            this.availableFields.envioArquivoTarefa = true;
                            this.files = [content?.contentSpecific?.template] || [];
                            this.sharedService.replaceLinkFile(this.files);
                        }

                        if (content?.grades_config !== null && this.rubric_edited !== '1') {
                            this.gradesConfig = content.grades_config;
                            this.contentSharedService.gradesConfig$.next(
                                content?.grades_config
                            );
                        }
                        this.setFormValues(content);
                        this.loadingFalse();
                        this.isFormCompleted = true;
                    },
                    error: (error) => {
                        if (error?.error?.error === 'build_content_in_progress' && this.attemptsGetVisualizationWithBackoff < 6 && this.hasVisualizationWithBackoff) {
                            this.attemptsGetVisualizationWithBackoff += 1;
                            // Calcula o tempo de espera exponencial em milissegundos
                            const delay = 2 ** this.attemptsGetVisualizationWithBackoff * 1000;

                            this.timeoutIdBackoff = setTimeout(() => {
                                this.getContentForm();
                            }, delay);

                            return;
                        }

                        // Reseta o contador se o status mudar ou o máximo de tentativas for atingido
                        this.attemptsGetVisualizationWithBackoff = 0;
                        let messageError = error?.error?.error || this.i18n.content_error_default;
                        this.loadingFalse();

                        if (error?.error?.error === 'build_content_in_progress' || error.error.error === 'build_content_failed') {
                            messageError = error.error.error;
                        }

                        if (Object.prototype.hasOwnProperty.call(this.i18n, messageError)) {
                            messageError = this.i18n[messageError];
                        }

                        this.platModalsService.toggle('message', messageError, 'close');
                    },
                });
        }

        if (!this.editMode && this.rubric_edited !== '1') {
            this.sharedService.deleteKeyLocalStorage('criterionsList');
        }
    }

    openModal() {
        this.sharedService.modalOpen();
    }

    // Salvar os dados do formulário quando vai para rubrica
    saveDataInObservable() {
        const payload = {
            payload: this.generatePayload(),
            files: this.files
        };
        this.contentService.saveDataCreatedContent$.next(payload);
    }

    getGdId(files, tipoTarefa) {
        let gdId: any;
        gdId = files[0]?.id;

        if (tipoTarefa === 'SheetsAssignment') {
            return gdId;
        } if (tipoTarefa === 'DocsAssignment') {
            return gdId;
        }
        return null;
    }

    // Função que monta o payload
    generatePayload() {
        const template_gd_id = this.getGdId(this.files, this.assignment);
        const assignmentsHandler = {
            File: () => {
                const files = this.files.map((item) => item.id);
                return { files };
            },
            Scorm: () => assignmentsHandler.File(),
            UploadAssignment: () => ({ maxFiles: this.assignmentForm.controls.maxFiles.value }),
            InternalQuiz: () => this.contentSpecific,
            UploadQuiz: () => {
                const files = this.files.map((item) => item.id);
                return { files };
            },
            Assignment: () => {
                if (
                    this.assignmentForm.controls.tipoTarefa.value == 'UploadAssignment'
                ) {
                    return { maxFiles: this.assignmentForm.controls.maxFiles.value };
                }
            },
            InternalConference: () => {
                this.organizadorConferencia = this.assignmentForm.controls.organizadorConferencia.value;
                return {
                    name: this.assignmentForm.controls.nomeTarefa.value || '',
                    conference_organizer_external_id: this.organizadorConferencia,
                };
            },
            Conference: () => {
                this.organizadorConferencia = this.assignmentForm.controls.organizadorConferencia.value;
                return {
                    conference_organizer_external_id: this.organizadorConferencia,
                };
            },
            LTI: () => ({
                lti_provider_id: this.assignmentForm.controls.providerLTI.value,
                lti_content_id: this.assignmentForm.controls.id_LTI_resource.value,
            }),
            Forum: () => ({
                title: this.assignmentForm.controls.nomeTarefa.value,
                text: this.assignmentForm.controls.descriptionEditor.value === '' ? this.assignmentForm.controls.nomeTarefa.value : this.assignmentForm.controls.descriptionEditor.value,
            }),
            PrintedExam: () => ({
                resources: this.assignmentForm.controls.resources.value,
                local: this.assignmentForm.controls.local.value,
                application_date: this.assignmentForm.controls.application_date.value,
                publish_grade_date: this.assignmentForm.controls.publish_grade_date.value,
                duration: this.assignmentForm.controls.duration.value,
                exam_external_id: this.assignmentForm.controls.exam_external_id.value,
                exam_name: this.assignmentForm.controls.exam_exam_name.value,
                exam_description: this.assignmentForm.controls.exam_exam_description.value,
                type: this.assignmentForm.controls.exam_type.value,
            }),
        };

        const checkFiles = Boolean(this.files.length);
        const typeHandler = assignmentsHandler[this.assignment] || function () { };

        const contentSpecific = typeHandler();

        const title = this.assignmentForm.controls.nomeTarefa.value || '';
        const link_site = this.assignmentForm.controls.url == undefined
            ? ''
            : this.assignmentForm.controls.url.value;
        const envioArquivo = this.assignmentForm.controls.envioArquivo.value; // pendente
        const tipoTarefa = this.assignmentForm.controls.tipoTarefa.value;
        const avancoCondicional = this.assignmentForm.controls.avancoCondicional.value;
        const description = this.assignmentForm.controls.descriptionEditor.value || '';
        const completion_rule = this.assignment === 'UploadQuiz' ? 'STUDENT_NOTIFICATION' : this.selectedCriteria;
        const grades_config = this.gradesConfig;
        const hide_until_start = this.assignment.includes('Conference') ? false : this.assignmentForm.controls.hideUntilStart.value;
        const create_attached_forum = this.assignmentForm.controls.createAttachedForum.value;
        let allow_late_submission = this.assignmentForm.controls.allowLateSubmission.value;

        const formatDateTime = (type: string, dateValue: string) => {
            if (!dateValue) return null;

            return type === 'date'
                ? formatDate(dateValue, 'yyyy-MM-dd', 'en-US')
                : type === 'time'
                    ? formatDate(dateValue, 'HH:mm:ss', 'en-US')
                    : formatDate(dateValue, 'yyyy-MM-dd HH:mm:ss', 'en-US');
        };

        let getStartDate = formatDateTime(
            'full',
            this.assignmentForm.controls.startDate.value
        );

        const getEndDate = formatDateTime(
            'full',
            this.assignmentForm.controls.endDate.value
        );

        if (hide_until_start === false && !this.assignment.includes('Conference')) {
            getStartDate = null;
        }

        if (getEndDate === null) {
            allow_late_submission = false;
        }

        const params = {
            title,
            description,
            type: this.assignment === 'Assignment' ? tipoTarefa : this.assignment,
            start_date: !getStartDate ? null : getStartDate,
            end_date: !getEndDate ? null : getEndDate,
            hide_until_start,
            allow_late_submission,
            completion_rule,
            completion_rule_value: this.completionRuleValue || null,
            link_site,
            template_gd_id: template_gd_id || null,
            grades_config,
            criterions: !this.criterions?.length
                ? []
                : this.criterions,
            contentSpecific,
            substitute_activity: Object.keys(this.substitute_activity).length
                ? this.substitute_activity
                : null,
            conditional_content_id: this.optionsConditional.conditionalAdvanceSelect,
            conditional_content_enable: this.optionsConditional.conditionalAdvance,
            create_attached_forum: this.assignment === 'Forum' ? false : create_attached_forum,
        };

        return params;
    }

    postAssignment() {
        this.loadingTrue();

        const params = this.generatePayload();

        if (params.type === 'InternalQuiz' && params.grades_config?.weight) {
            let validationWeight = false;

            // Faz a soma do peso de cada questão
            const totalQuestionWeight = params.contentSpecific?.questions.reduce((acc, question) => acc + parseFloat(question.weight), 0);

            // Verifica se o resultado da soma é diferente dos pontos disponíveis
            if (totalQuestionWeight !== parseFloat(params.grades_config.weight)) {
                validationWeight = true;
            }

            // Se for diferente não deixa enviar a request (ele precisa usar todos os pontos)
            if (validationWeight) {
                this.loadingFalse();
                this.showModalError(this.i18n.content_quiz_validation_weight);
                return;
            }
        }

        if (params.type === 'UploadQuiz' && !params.contentSpecific.files.length) {
            this.loadingFalse();
            this.showModalError(this.i18n.content_files_required);
            return;
        }

        if (this.valueSubstituteActivity && params.substitute_activity === null) {
            this.loadingFalse();
            this.showModalError(this.i18n.disciplines_substitute_activity_required);
            return;
        }

        // Se tiver atividade substitutiva e não tiver nota
        if (params.substitute_activity !== null && !params.grades_config?.weight) {
            this.loadingFalse();
            this.showModalError(this.i18n.disciplines_error_evaluative_resources_required_when_substitute_activity);
            return;
        }

        const requestFn = this.editMode ? 'updateContent' : 'postContent';
        const handler = {
            success: (response) => {
                this.loadingFalse();

                this.sectionService.criterions = [];
                this.contentService.saveDataCreatedContent$.next({});
                this.contentSharedService.gradesConfig$.next({});

                if (this.classId) {
                    const channel = new BroadcastChannel('resource');
                    channel.postMessage(
                        {
                            action: 'resourceAdded',
                            sectionExternalId: this.section_external_id,
                            classId: this.classId,
                            type: this.assignment,
                            contentId: response.content_id,
                            contentExternalId: response.external_id,
                        }
                    );

                    channel.close();
                    window.close();
                }

                this.router.navigate(['../../content-tab'], { relativeTo: this.route });
                this.sharedService.deleteKeyLocalStorage('criterionsList');
            },
            next: () => {
                this.loadContents();
                this.loadingFalse();
            },
            error: (err) => {
                this.loadingFalse();
                this.criterions = [];
                let messageError = '';

                switch (err.status) {
                    case 403:
                        messageError = err.error.message;
                        break;
                    case 404:
                        if (err.error.hasOwnProperty('error')) {
                            messageError = err.error.error;
                        }

                        if (err.error.hasOwnProperty('message')) {
                            messageError = err.error.message;
                        }

                        break;
                    case 409:
                        // messageError = err.error;
                        if (err.error.message === 'Deadlock of Contents.') {
                            messageError += `<p>${this.i18n.content_deadlock}</p><br>`;
                        }
                        if (err?.error?.message === 'weight_exceed_a_hundred_percent') {
                            messageError = this.i18n.grades_grade_cannot_be_higher_than_max_grade;
                        }
                        if (err.error.error === 'disciplines_informed_period_is_not_valid') {
                            messageError += `<p>${this.i18n.disciplines_informed_period_is_not_valid}</p><br>`;
                        }
                        if (err.error.error === 'disciplines_assignment_error_no_point_value') {
                            messageError += `<p>${this.i18n.disciplines_assignment_error_no_point_value}</p><br>`;
                        }
                        if (err.error.error === 'disciplines_assignment_error_upload_question') {
                            messageError += `<p>${this.i18n.disciplines_assignment_error_upload_question}</p><br>`;
                        }
                        if (err.error.error === 'disciplines_assignment_error_is_not_quiz') {
                            messageError += `<p>${this.i18n.disciplines_assignment_error_is_not_quiz}</p><br>`;
                        }
                        if (err.error.error === 'disciplines_assignment_error_no_items') {
                            messageError += `<p>${this.i18n.disciplines_assignment_error_no_items}</p><br>`;
                        }
                        if (err.error.error === 'disciplines_assignment_error_data_question') {
                            messageError += `<p>${this.i18n.disciplines_assignment_error_data_question}</p><br>`;
                        }
                        if (err.error.error === 'disciplines_assignment_error_hour_question') {
                            messageError += `<p>${this.i18n.disciplines_assignment_error_hour_question}</p><br>`;
                        }

                        if (err.error.error.includes('The substituted content') && err.error.error.includes('does not have a grade')) {
                            messageError = `<p>${this.i18n.disciplines_error_evaluative_resources_required_when_substitute_activity}</p><br>`;
                        }

                        break;
                    case 422:
                        if (err?.error?.error) {
                            Object.values(err?.error?.error).forEach((element: any) => {
                                messageError += `<p>${element}</p><br>`;
                            });
                        } else {
                            const dateNow = new Date().toLocaleDateString();
                            const timeNow = new Date().toLocaleTimeString();
                            messageError = `${this.i18n.disciplines_date_init_error} ${dateNow} ${timeNow}`;
                        }
                        break;
                    default:
                        messageError = this.i18n.content_error_default;
                }

                // if (
                //   (!this.assignmentForm.controls['startDate'].value &&
                //     this.availableFields.startDate) ||
                //   (!this.assignmentForm.controls['endDate'].value &&
                //     this.availableFields.endDate)
                // ) {
                //   messageError = this.i18n.content_error_dates_hours;
                // }

                if (params.link_site.length > 200) {
                    messageError = this.i18n.content_link_site_long;
                }

                if (messageError.includes(this.i18n.disciplines_informed_period_is_not_valid)) {
                    this.platModalsService.toggle('decision', this.i18n.grades_subject_assignment_force, {
                        forward: () => {
                            params.grades_config.force = true;

                            this.contentService[requestFn](
                                this.section_external_id,
                                this.content_external_id,
                                params
                            ).subscribe(handler.success, handler.error);
                        },
                        finally: () => {
                            this.platModalsService.close('decision');
                        },
                    });
                } else {
                    this.platModalsService.toggle(
                        'message',
                        {
                            message: messageError,
                            icon_existence: true,
                            icon_color: '#F36C48',
                            custom_icon: 'attention-icon',
                        },
                        'close'
                    );
                }

                this.loadingFalse();
            },
        };

        this.contentService[requestFn](
            this.section_external_id,
            this.content_external_id,
            params
        ).subscribe(handler.success, handler.error);
    }

    showModalError(message: string): void {
        this.platModalsService.toggle(
            'message',
            {
                message,
                icon_existence: true,
                icon_color: '#F36C48',
                custom_icon: 'attention-icon',
            },
            'close'
        );
    }

    receiveEvaluationResources($event: any) {
        this.gradesConfig = $event;
    }

    receiveFiles($event: any) {
        this.files = $event || [];

        this.sharedService.replaceLinkFile(this.files);
        this.cd.detectChanges();
    }

    isLoadingFile = false;
    receiveisLoadingFile($event) {
        console.log('$event receiveisLoadingFile', $event);

        this.isLoadingFile = $event;

        this.cd.detectChanges();
    }

    checkIfShowUploadList(assignment: string): boolean {
        if (assignment !== '' || assignment !== undefined || assignment !== null) {
            const hasUploadList = assignment.match(/UploadQuiz|DocsAssignment|SheetsAssignment/);

            if (hasUploadList) {
                return true;
            }

            return false;
        }

        return false;
    }

    receiveFileSharingModalOpen($event) {
        this.isModalFileSharingOpen = $event;
    }

    receiveIsModalOpenFromFileSharing($event) {
        this.isModalFileSharingOpen = $event;

        if (!this.isModalFileSharingOpen) {
            this.UploadInlineComponent.isLoading = false;
            this.isGoogleButtonsLocked = false;
            this.UploadInlineComponent.googleData = [];
            this.UploadInlineComponent.countFilesInsertedPermission = 0;
        }
    }

    receiveGooglePostParams($event) {
        this.googlePostParams = $event;
    }

    receiveIsUploadLoading($event) {
        console.log('receiveIsloadingButtons', $event);
        this.isUploadLoading = $event;
    }

    receiveIsUploadLocked($event) {
        console.log('receiveIsloadingButtons', $event);
        this.isGoogleButtonsLocked = $event;
        this.cd.detectChanges();
    }

    callPostGoogleFiles(flow_method) {
        this.isModalFileSharingOpen = false;
        this.googlePostParams.flow_method = flow_method;
        this.UploadInlineComponent.callInsertPermission(this.googlePostParams);
    }

    checkUrl($event: any) {
        const url = this.assignmentForm.controls.url === undefined
            ? ''
            : this.assignmentForm.controls.url.value;
        const params = {
            url,
        };
        this.contentService.checkUrl(params).subscribe(
            (isIframable: any) => {
                if (isIframable?.isIframable === false) {
                    this.isIframable = false;
                    this.urlInvalid = false;
                } else {
                    this.isIframable = true;
                    this.urlInvalid = false;
                }
            },
            (err) => {
                if (err?.error?.error === 'URL invalid') {
                    this.isIframable = null;
                    this.urlInvalid = true;
                } else {
                    this.isIframable = null;
                    this.urlInvalid = true;
                }
            }
        );
    }

    receivesSelectedCriteria($event) {
        this.selectedCriteria = $event;

        if (this.selectedCriteria === 'TIME') {
            this.showTimeSelect = true;
            this.completionRuleValue = this.content.completion_rule_value;
            this.assignmentForm.controls.lengthOfstay.setValue(this.completionRuleValue);
        } else if (this.selectedCriteria !== 'TIME') {
            this.showTimeSelect = false;
            this.completionRuleValue = null;
        }

        console.log('this.selectedCriteria ', this.selectedCriteria);
        console.log('this.assignment ', this.assignment);

        if (this.selectedCriteria === 'ATTEMPT_SUBMISSION' && (this.assignment !== 'Forum' && this.assignment !== 'UploadQuiz')) {
            this.showAttempts = true;
            this.assignmentForm.controls.attempt_sub.setValue(this.content.completion_rule_value || '0');
        } else if (this.selectedCriteria !== 'ATTEMPT_SUBMISSION') {
            this.showAttempts = false;
            if (this.selectedCriteria !== 'TIME') {
                this.completionRuleValue = null;
            }
        }

        console.log('this.showAttempts ', this.showAttempts);

        if (this.selectedCriteria === 'ATTEMPT_SUBMISSION' && this.assignment === 'Forum') {
            this.assignmentForm.controls.attempt_sub.setValue('1');
        }
    }

    receivesOptionsForm($event) {
        this.optionsConditional = $event;
    }

    cancelAssignment() {
        this.assignmentForm.reset();
        this.files = [];
        this.contentService.saveDataCreatedContent$.next({});
        this.contentSharedService.gradesConfig$.next({});
        this.router.navigate(['../../content-tab'], { relativeTo: this.route });
    }

    receivePayload<T>(data: { ready: boolean; payload: T }): void {
        this.ready = data.ready;
        this.contentSpecific = data.payload;
    }

    receiveWeight(value: number) {
        this.available_grade = value;
    }

    receivePeriodOutput(event: Event): void {
        this.periodParams = event;
    }

    receiveActivity(event: Event): void {
        this.substitute_activity = event;
    }

    receiveSubstituteActivityControl(event: Event): void {
        if (typeof event === 'boolean') {
            this.valueSubstituteActivity = event;
        }
    }

    goCreateRubrica(): void {
        this.saveDataInObservable();
        this.route.params.subscribe((params) => {
            try {
                const queryParams = {
                    section: params.section,
                    section_title: params.section_title,
                    type: params?.type,
                    calculation_type: this.periodParams?.period?.calculation_type,
                    content: '',
                    edit: '',
                    weight: 0
                };

                if (params?.edit === '1') {
                    queryParams.content = params.content;
                    queryParams.edit = params?.edit;
                    queryParams.weight = this.content?.grades_config?.weight || 0;
                } else {
                    queryParams.weight = this.periodParams.grades || 0;
                }

                this.router.navigate(['../create-rubrica', queryParams], {
                    relativeTo: this.route,
                });
            } catch (error) {
                this.messageErrorEditRubric = true;
                setTimeout(() => (this.messageErrorEditRubric = false), 10000);
            }
        });
    }

    // // Validate Data of p-calendar
    validateMinDate(dateSelected) {
        if (!this.editMode) {
            if (dateSelected < this.minDate) {
                this.assignmentForm.controls.endDate.setValue(this.minDate);
            }
        }
    }

    minDateValidator(control: FormControl): { [key: string]: any } | null {
        const enteredDate = control;

        let dateMin: Date = new Date();
        let returnValue: any = null;

        if (this.assignmentForm && !this.editMode) {
            dateMin = this.startDate?.value === '' ? this.initialDate : this.startDate?.value;
            returnValue = minDateValidator(dateMin)(enteredDate);
        }
        return returnValue;
    }

    minDatePrintedValidator(control: FormControl): { [key: string]: any } | null {
        const enteredDate = control;

        let dateMin: Date = new Date();
        let returnValue: any = null;

        if (this.assignmentForm && !this.editMode) {
            dateMin = this.application_date?.value === '' ? this.initialDate : this.application_date?.value;
            returnValue = minDateValidator(dateMin)(enteredDate);
        }
        return returnValue;
    }

    minDateStartDateValidator(control: FormControl): { [key: string]: any } | null {
        const enteredDate = control;

        let dateMin: Date = new Date();
        let returnValue: any = null;
        if (this.assignmentForm && !this.editMode) {
            dateMin = this.initialDate;
            returnValue = minDateValidator(dateMin)(enteredDate);
        }
        return returnValue;
    }

    minDatePrintedDateValidator(control: FormControl): { [key: string]: any } | null {
        const enteredDate = control;

        let dateMin: Date = new Date();
        let returnValue: any = null;
        if (this.assignmentForm && !this.editMode) {
            dateMin = this.application_date?.value === '' ? this.initialDate : this.application_date?.value;
            returnValue = minDateValidator(dateMin)(enteredDate);
        }
        return returnValue;
    }

    fnPickDateToInput() {
        if (this.endDate?.value !== null && this.endDate?.value !== '') {
            this.enableFieldAllowLateSubmisson = true;
        } else {
            this.enableFieldAllowLateSubmisson = false;
        }
    }

    ngOnDestroy(): void {
        if (this.subscriptions.length) {
            this.subscriptions.forEach((subscription) => {
                subscription.unsubscribe();
            });
        }

        this.clearVisualizationBackoff();
    }

    clearVisualizationBackoff(): void {
        this.hasVisualizationWithBackoff = false;

        if (this.timeoutIdBackoff) {
            clearTimeout(this.timeoutIdBackoff); // Limpa o timeout
            this.timeoutIdBackoff = null; // Zera o identificador para evitar chamadas desnecessárias
        }
    }

    setselectedAssessmentType(type: string) {
        this.selectedAssessmentType = type;
        this.assignmentForm.controls.exam_type.setValue(type);

        if (type === 'bank') {
            this.isEvaluationBankOpen = true;
        }
    }

    closeModalEvaluation() {
        this.isEvaluationBankOpen = false;
    }

    setScheduleSelected(schedule: ScheduleResponse) {
        this.scheduleSelected = schedule;
        this.assignmentForm.controls.exam_external_id.setValue(schedule.agendamentoId);
        this.assignmentForm.controls.exam_exam_name.setValue(schedule.agendamentoNome);
        this.assignmentForm.controls.exam_exam_description.setValue(schedule.realizacaoNome);
        this.scheduleSelectedId = schedule.agendamentoId;
    }

    removeScheduleSelected() {
        this.scheduleSelected = null;
        this.assignmentForm.controls.exam_external_id.setValue(null);
    }

    goToSchedules() {
        const url = this.router.createUrlTree(['../../assessments'], { relativeTo: this.route }).toString();
        window.open(url, '_blank');
    }

    setScheduleSelectedEdit(schedule: any) {
        this.scheduleSelected = {
            agendamentoId: schedule.exam_external_id,
            agendamentoNome: schedule.exam_name,
            realizacaoNome: schedule.exam_description,
            realizacaoId: schedule.exam_external_id,
            DataInicio: schedule.application_date,
            DataTermino: schedule.publish_grade_date,
            Autor: '',
            realizacaoOrdem: '',
            Disciplinas: '',
        };

        this.scheduleSelectedId = schedule.exam_external_id;
    }
}
