<div class="quiz">
  <div class="quiz-presentation">
    <div
      class="quiz-presentation__title"
      [innerHTML]="content.title | safeHTML"
    ></div>
    <div
      class="quiz-presentation__description"
      *ngIf="content?.description"
      [innerHTML]="content?.description | safeHTML"
    ></div>
    <div class="quiz-presentation__completition-criterion">
      <i class="iconify-inline quiz-presentation__icon" data-icon="bx:lock"></i>
      <span
        >{{ i18n.question_bank_content_view_criterion }}
        <b>{{ completitionRuleTranslation }}</b></span>
    </div>
  </div>

  <ng-container *ngIf="!isLoading">
      <div class="relative questions">
            <ng-container *ngIf="isStudent && content.coursework_submission_attempts > 0 && !quizInited">
                <div 
                    class="flex justify-end w-full relative"
                    style="height: 34px;"
                >
                    <div
                        class="absolute text-customBlue-default border border-customGray-lighterGray px-4 py-1 rounded bg-customWhite-default"
                        style="z-index: 2"
                    >
                        <button
                            class="flex items-center justify-center gap-3"
                            (click)="toggleOptions()"
                        >
                            <p class="font-medium">
                                {{ i18n.disciplines_external_quiz_attempt }}
                                {{
                                    returnIndexByTryId(tryId)
                                }}
                            </p>
                            <i
                                class="arrow-bottom-icon"
                                [ngClass]="{ 'rotate--90': collapseOption }"
                            ></i>
                        </button>
                        <div class="overflow-hidden">
                            <ul [@collapse]="collapseOption">
                                <li
                                    class="py-1 cursor-pointer text-semibold-hover"
                                    *ngFor="
                                        let try of tries;
                                        let i = index
                                    "
                                    (click)="
                                        changeTry(try.try_id)
                                    "
                                    [ngClass]="{
                                        'font-semibold':
                                            try.try_id === tryId
                                    }"
                                >
                                    {{
                                        i18n.disciplines_external_quiz_attempt
                                    }}
                                    {{ i + 1 }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </ng-container>
            
            <div class="questions__title">{{ content.title }}</div>
            <div class="questions__number mt-2">
                <span *ngIf="(!isStudent || !isCompleted) || quizInited">{{ questions.length }} {{ i18n.question_bank_question }}</span>
    
                <span *ngIf="isStudent && isCompleted && !quizInited">
                    {{ questions.length }} 
                    <span class="lowercase">
                        {{ questions.length === 1 ? i18n.question_bank_question_singular : i18n.question_bank_question }}
                    </span> 
                    ({{ countCorrect }} {{ countCorrect < 2 ? i18n.question_bank_correct_singular : i18n.question_bank_correct }} {{i18n.question_bank_and}} {{ countIncorrect }} {{ countIncorrect < 2 ? i18n.question_bank_incorrect_singular : i18n.question_bank_incorrect }})
                </span>
            </div>
            <app-question-bank-question
                *ngFor="let question of questions; let index = index"
                [data]="question"
                [order]="index"
                [noEdit]="true"
                [noDelete]="true"
                [readonly]="!isStudent || !quizInited"
                (change)="saveStudentAnswer($event)"
                [correction]="!quizInited && getCanViewAnswers()"
            >
            </app-question-bank-question>
      </div>
  </ng-container>

  <ng-container *ngIf="isLoading">
    <app-bullets-loader></app-bullets-loader>
  </ng-container>

  <div class="decision-btns right">
    <!-- Button Back  -->
    <button type="button" class="btn backward" (click)="getBackToContents()">{{ i18n.question_bank_go_back }}</button>
    
    <!-- Button Edit  -->
    <button type="button" class="btn forward" *ngIf="!isStudent" (click)="editQuiz()">{{ i18n.question_bank_edit }}</button>
    
    <!-- Button Resume  -->
    <!-- <button type="button" class="btn forward" *ngIf="!quizInited && isStudent && remainingAttempts && ()" [disabled]="!content?.delivery_allowed" [ngClass]="{'cursor-not-allowed': !content?.delivery_allowed}" (click)="startQuiz()">
    {{  }}
    </button> -->
    <!-- Button Start  -->
    <button type="button" class="btn forward" *ngIf="!quizInited && isStudent && remainingAttempts" (click)="startQuiz()" [disabled]="!content?.delivery_allowed" [ngClass]="{'cursor-not-allowed': !content?.delivery_allowed}">
        {{ content.coursework_submission_attempts === 0 ? i18n.question_bank_content_view_start_quiz : i18n.question_bank_content_view_resume_questionnaire}}
    </button>
    <!-- Button Finish  -->
    <button type="button" class="btn forward" [disabled]="!canSubmitQuiz" [ngClass]="{'cursor-not-allowed opacity-75': !canSubmitQuiz}" *ngIf="quizInited && isStudent" (click)="finishQuiz()">
      <ng-container *ngIf="!finishQuizLoading">{{ i18n.question_bank_send }}</ng-container>
      <app-loading-balls-effect *ngIf="finishQuizLoading"></app-loading-balls-effect>
    </button>
  </div>
</div>