import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AccountsComponent } from './accounts.component';
import { LoginGuardianComponent } from './components/login-guardian/login-guardian.component';
import { LoginGuardianCodeComponent } from './components/login-guardian-code/login-guardian-code.component';
import { LoginNormalComponent } from './components/login-normal/login-normal.component';

const routes: Routes = [
    { path: '', component: AccountsComponent },
    { path: 'login-guardian', component: LoginGuardianComponent },
    { path: 'login-guardian-code', component: LoginGuardianCodeComponent },
    { path: 'login-normal', component: LoginNormalComponent },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AccountsRoutingModule { }
