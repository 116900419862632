import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_Routes } from 'src/environments/environment';

import {
    CheckNewOuResponse,
    DisciplineTeacher, Modalities, Segment, Units
} from './interfaces/disciplines.interface';

@Injectable({
    providedIn: 'root',
})
export class DisciplineService {
    constructor(private http: HttpClient) {}

    // Nova chamada da requisição sem os parâmetros meses e outros
    getNewDisciplines(type: string, query: string, level: string, modalities: string, unit: string, page: number, per_page: number): Observable<DisciplineTeacher> {
        return this.http.get<DisciplineTeacher>(`${API_Routes.URL}/v2/disciplines/?type=${type}&q=${query}&levels=${level}&modalities=${modalities}&page=${page}&per_page=${per_page}&unit=${unit}`);
    }

    getSegments(): Observable<Segment[]> {
        return this.http.get<Segment[]>(`${API_Routes.URL}/levels`);
    }

    getModalities(): Observable<Modalities[]> {
        return this.http.get<Modalities[]>(`${API_Routes.URL}/modalities`);
    }

    getUnits(): Observable<Units[]> {
        return this.http.get<Units[]>(`${API_Routes.URL}/microdata/units`);
    }

    deleteDiscipline<T>(disciplineExternalId: string) : Observable<T> {
        return this.http.delete<T>(`${API_Routes.URL}/disciplines/${disciplineExternalId}`);
    }

    archiveDiscipline<T>(disciplineExternalId: string): Observable<T> {
        return this.http.post<T>(`${API_Routes.URL}/disciplines/${disciplineExternalId}/archive`, {});
    }

    unarchiveDiscipline<T>(disciplineExternalId: string): Observable<T> {
        return this.http.post<T>(`${API_Routes.URL}/disciplines/${disciplineExternalId}/unarchive`, {});
    }

    duplicateDiscipline(disciplineExternalId: string) {
        return this.http.post(`${API_Routes.URL}/disciplines/${disciplineExternalId}/duplicate`, {});
    }

    postDisciplines<T>(params: T): Observable<T> {
        return this.http.post<T>(`${API_Routes.URL}/disciplines/`, params);
    }

    patchDiscipline<T>(disciplineExternalId: string, params: { description: string }): Observable<T> {
        return this.http.patch<T>(`${API_Routes.URL}/disciplines/${disciplineExternalId}/`, params);
    }

    subscribeDiscipline<T>(disciplineExternalId: string): Observable<T> {
        return this.http.post<T>(`${API_Routes.URL}/disciplines/${disciplineExternalId}/subscribe`, {});
    }

    // Requisição antiga, não foi retirada pois está sendo usada em outros lugares com parametros como 'meses'
    getDisciplines(
        types: any,
        query: any,
        level: any,
        modalities: any,
        months: any,
        years: any,
        page?: number,
        per_page?: number,
    ): Observable<any> {
        return this.http.get<any>(
            `${API_Routes.URL}/disciplines/?type=${types}&q=${query}&levels=${level}&modalities=${modalities}&months=${months}&years=${years}&page=${page}&per_page=${per_page}`
        );
    }

    checkNewOu(disciplineExternalID: string, ouExternalID: string): Observable<CheckNewOuResponse> {
        return this.http.get<CheckNewOuResponse>(`${API_Routes.URL}/v2/disciplines/${disciplineExternalID}/ou/${ouExternalID}/check-new-ou`);
    }

    postChangeOu<T>(disciplineExternalID: string, ouExternalID: string, body: { remove_students: boolean, enroll_students: boolean }): Observable<T> {
        return this.http.post<T>(`${API_Routes.URL}/v2/disciplines/${disciplineExternalID}/ou/${ouExternalID}/change-ou`, body);
    }
}
