import { Injectable } from '@angular/core';
import {
    CanLoad,
    Router,
} from '@angular/router';

import { SharedService } from '../../shared/shared.service';

@Injectable()
export class AccountsGuard implements CanLoad {
    constructor(private sharedService: SharedService, private router: Router) {}

    canLoad(): boolean {
        if (this.sharedService.fnUserHasToken() && this.sharedService.fnUserHasValidToken()) {
            this.router.navigate(['/dashboard']);
        }

        return true;
    }
}
