import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_Routes } from 'src/environments/environment';

import { GuardiansPayload, GuardiansUsers } from '../interfaces/user-guardian.interface';

@Injectable({
    providedIn: 'root'
})
export class UserGuardianService {
    constructor(private http: HttpClient) { }

    getSearchGuardian(CPF: string): Observable<GuardiansUsers> {
        return this.http.get<GuardiansUsers>(`${API_Routes.URL}/guardian/search?cpf=${CPF}`);
    }

    postGuardian<T>(sonOfGuardianExternalID: string, body: GuardiansPayload): Observable<T> {
        return this.http.post<T>(`${API_Routes.URL}/guardian/${sonOfGuardianExternalID}`, body);
    }

    editGuardian<T>(sonOfGuardianExternalID: string, body: GuardiansPayload): Observable<T> {
        return this.http.patch<T>(`${API_Routes.URL}/guardian/${sonOfGuardianExternalID}`, body);
    }

    deleteGuardian<T>(sonOfGuardianExternalID: string, guardianExternalID: string): Observable<T> {
        return this.http.delete<T>(`${API_Routes.URL}/guardian/son/${sonOfGuardianExternalID}/guardian/${guardianExternalID}`);
    }
}
