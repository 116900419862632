<app-generic-modal [isVisible]="true">
    <div class="container">
        <div class="header-modal">
            <div class="title-modal text-center mb-4">
                {{ i18n.disciplines_pre_registered_duplicate_title }}
            </div>
        </div>

        <ng-container *ngIf="isLoading">
            <div style="transform:translateY(50%); top: 50%; height: 150px;">
                <app-bullets-loader></app-bullets-loader>
            </div>
        </ng-container>

        <!-- Pre registered Dates -->
        <ng-container *ngIf="!isLoading">
            <app-pre-registered-dates-modal
                *ngIf="templateMode === 'preRegisteredSelectionMode'"
                [parentComponent]="'duplicate'"
                [showResorucesDatesCheckboxes]="preRegisteredDates"
                (sendDatesResourcesValues)="getDatesResourcesValues($event)"
                (sendCloseModal)="closeModalDuplicateDiscipline()"
            ></app-pre-registered-dates-modal>
        </ng-container>
    </div>
</app-generic-modal>