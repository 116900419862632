import {
    Component, EventEmitter, Input, OnChanges, Output,
    SimpleChanges
} from '@angular/core';

@Component({
    selector: 'app-select-discipline-box',
    templateUrl: './select-discipline-box.component.html',
    styleUrls: ['./select-discipline-box.component.scss']
})
export class SelectDisciplineBoxComponent implements OnChanges {
    @Input() discipline: any = [];
    @Input() selectedDiscipline: any = [];
    @Input() modelForm: any;
    @Input() disabled: boolean;

    @Output() formDisciplineData = new EventEmitter();
    selected = false;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes) {
            if (this.selectedDiscipline
                && this.selectedDiscipline.length
            ) {
                console.log('this.selectedDiscipline ', this.selectedDiscipline);
                console.log('this.discipline ', this.discipline);

                const hasDisciplineChecked = this.selectedDiscipline.some((sd) => sd.external_id === this.discipline.external_id);
                console.log('hasDisciplineChecked ', hasDisciplineChecked);

                if (hasDisciplineChecked) {
                    this.toggleSelect();
                }
            }
        }
    }

    toggleSelect() {
        this.selected = !this.selected;
    }

    checkDisciplineChange(discipline: any) {
        const disciplineObj = discipline;
        disciplineObj.checked = this.selected;
        this.formDisciplineData.emit(disciplineObj);
    }
}
