import {
    Component, EventEmitter, Input, Output
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { DisciplinesModelsService } from '../../../../shared/disciplines-models.service';

@Component({
    selector: 'app-card-discipline',
    templateUrl: './card-discipline.component.html',
    styleUrls: ['./card-discipline.component.scss'],
})
export class CardDisciplineComponent {
    @Input() disciplineCode: string;
    @Input() disciplineTitle: string;
    @Input() i18n: any;
    @Input() discipline: any = {};
    @Input() enableImportClassroom = false;

    @Output() callGetSections = new EventEmitter();
    @Output() sendingDisciplineCode = new EventEmitter();
    @Output() callingDuplicateTemplate = new EventEmitter();
    @Output() callingRemoveTemplate = new EventEmitter();

    constructor(
        private disciplinesModelsService: DisciplinesModelsService,
        private route: ActivatedRoute,
        private router: Router
    ) {}

    callImportModal() {
        this.sendingDisciplineCode.emit(this.disciplineCode);
        this.openModalImport();
    }

    callDuplicate() {
        console.log('CHAMA DUPLICATE');
        this.callingDuplicateTemplate.emit(this.disciplineCode);
    }

    callRemoveTemplate() {
        this.callingRemoveTemplate.emit(this.disciplineCode);
    }

    closeModalImport() {
        if (this.enableImportClassroom) {
            this.disciplinesModelsService.addModelModalClassroomClose();
        } else {
            this.disciplinesModelsService.closeExportContentsModal();
        }
    }

    openModalImport() {
        if (this.enableImportClassroom) {
            this.disciplinesModelsService.addModelModalClassroomOpen();
        } else {
            this.disciplinesModelsService.openExportContentsModal();
        }
    }

    callingGetSections() {
        this.callGetSections.emit(this.disciplineCode);
    }

    toDisciplineOverview(disciplineCode: any) {
        this.router.navigate(
            [`../disciplines-models/${disciplineCode}/content-tab`],
            { relativeTo: this.route }
        );
    }

    toDisciplineSettings(disciplineCode: any) {
        this.router.navigate(
            [
                `../disciplines-models/${disciplineCode}/discipline-settings/discipline-settings-data`,
            ],
            { relativeTo: this.route }
        );
    }

    textCut(limit: any, textAnnouncement: any) {
        let text = textAnnouncement;
        const overLimit = text.length > limit;
        const elipsisOrEmpty = overLimit ? '...' : '';
        text = text.substring(0, limit) + elipsisOrEmpty;
        return text;
    }
}
