<section class="max-h-screen overflow-x-hidden overflow-y-auto font-Montserrat">
    <main class="h-full min-h-screen flex flex-col items-center justify-center gap-6 p-4 bg-cover bg-no-repeat bg-center" [style.background-image]="'url(' + background_login + ')'">
        <div class="md:absolute md:top-4 md:right-4" *ngIf="!isLoading && currentLanguage === 'pt-BR'">
            <app-accessibility-login [i18n]="i18n"></app-accessibility-login>
        </div>
        
        <app-third-party-cookie class="top-0 sticky mb-5" *ngIf="!isLoading"></app-third-party-cookie>
        
        <!-- Loading -->
        <app-loading-balls-effect class="block py-6 px-10 rounded-2xl bg-customWhite-default" *ngIf="isLoading"></app-loading-balls-effect>

        <article class="bg-customWhite-default rounded-3xl grid-template-columns xsm:w-full w-4/5 sm:w-8/12 md:w-5/6" *ngIf="!isLoading">
            <div class="p-6 md:px-10 flex flex-col gap-6 items-center justify-center text-customGray-dark">
                <ng-content></ng-content>
            </div>

            <div class="bg-customBlue-light rounded-b-3xl md:rounded-l-none md:rounded-r-3xl login__box--height bg-cover bg-no-repeat bg-center" [style.background-image]="'url(' + image_side_login + ')'">
                <!-- Background  -->
            </div>
        </article>

        <p class="text-14 text-center text-customGray-dark" *ngIf="hasBackButton && !isLoading">{{ i18n.login_isnt_your_access_click_to }} <a class="font-bold" [routerLink]="['/']">{{ i18n.login_back }}</a></p>
    </main>
</section>