import {
    animate, AnimationBuilder, AnimationFactory, AnimationPlayer, style
} from '@angular/animations';
import {
    AfterViewInit, Component, ContentChildren, ElementRef, EventEmitter,
    HostListener,
    Input, OnDestroy, Output, QueryList, ViewChild, ViewChildren
} from '@angular/core';
import { Subscription } from 'rxjs';

import { CarouselService } from './carousel.service';
import { CarouselItemDirective } from './carousel-item.directive';

@Component({
    selector: 'app-carousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements AfterViewInit, OnDestroy {
    @ContentChildren(CarouselItemDirective) items : QueryList<CarouselItemDirective>;
    @ViewChildren('carouselItem', { read: ElementRef }) itemsElements: QueryList<ElementRef>;
    @ViewChild('carousel') private carousel : ElementRef;
    @Input() timing = '250ms ease-in';
    @Input() slidesToShow = 1;
    @Input() showSlideIndex = 1;
    @Input() numberSlideAtTime = 0;
    @Input() carouselWidth: ElementRef;

    @Output() emitIsNextDisabled = new EventEmitter();
    @Output() emitIsPrevDisabled = new EventEmitter();

    carouselSubscription$: Subscription;
    public player : AnimationPlayer;
    public itemWidth : number;
    public isNextDisabled: boolean;
    public isPrevDisabled: boolean;
    carouselWrapperStyle = {};

    constructor(
        private builder: AnimationBuilder,
        private carouselService: CarouselService
    ) {}

    ngAfterViewInit() {
        this.checkIsNextDisabled();
        this.checkIsPrevDisabled();
        this.calculateWidthCarouselWrapper();
        this.updateCarouselWitdhContainer();
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize() {
        this.calculateWidthCarouselWrapper();
    }

    updateCarouselWitdhContainer(): void {
        this.carouselSubscription$ = this.carouselService.updatedCarouselWidthContainer$.subscribe({
            next: (response) => {
                if (response) {
                    this.showSlideIndex = 0;
                    this.calculateWidthCarouselWrapper();
                    this.checkIsPrevDisabled();
                    this.checkIsNextDisabled();
                }
            }
        });
    }

    calculateWidthCarouselWrapper(): void {
        if (this.carouselWidth && this.carouselWidth.nativeElement) {
            this.itemWidth = this.carouselWidth.nativeElement.clientWidth / this.slidesToShow;
            this.carouselWrapperStyle = {
                width: `${this.itemWidth * this.slidesToShow}px`
            };

            if (this.showSlideIndex !== 1) {
                const offset = this.showSlideIndex * this.itemWidth;
                this.jumpToSlide(offset);
            }
        }
    }

    jumpToSlide(offset) {
        const myAnimation : AnimationFactory = this.buildAnimation(offset);
        this.player = myAnimation.create(this.carousel.nativeElement);
        this.player.play();
    }

    next() {
        if (this.showSlideIndex + this.slidesToShow === this.items.length) return;
        this.isNextDisabled = false;

        // Caso seja passado a quantidade de slide por vez, usa esse valor, caso o contrário deixa o padrão
        this.showSlideIndex += this.numberSlideAtTime !== 0 ? this.numberSlideAtTime : this.slidesToShow;

        const offset = this.showSlideIndex * this.itemWidth;
        const myAnimation : AnimationFactory = this.buildAnimation(offset);
        this.player = myAnimation.create(this.carousel.nativeElement);
        this.player.play();
        this.checkIsPrevDisabled();
        this.checkIsNextDisabled();
    }

    prev() {
        if (this.showSlideIndex === 0) return;

        // Caso seja passado a quantidade de slide por vez, usa esse valor, caso o contrário deixa o padrão
        this.showSlideIndex -= this.numberSlideAtTime !== 0 ? this.numberSlideAtTime : this.slidesToShow;

        const offset = this.showSlideIndex * this.itemWidth;
        const myAnimation : AnimationFactory = this.buildAnimation(offset);
        this.player = myAnimation.create(this.carousel.nativeElement);
        this.player.play();
        this.checkIsPrevDisabled();
        this.checkIsNextDisabled();
    }

    checkIsNextDisabled(): boolean {
        this.isNextDisabled = false;
        // Calcula o índice do último item visível
        const lastVisibleIndex = this.showSlideIndex + this.slidesToShow - 1;

        // Verifica se o último item visível é o último item no array de itens
        if (lastVisibleIndex >= this.items.length - 1) {
            this.isNextDisabled = true;
        }

        this.emitIsNextDisabled.emit(this.isNextDisabled);
        return this.isNextDisabled;
    }

    checkIsPrevDisabled() {
        this.isPrevDisabled = false;
        if (this.showSlideIndex === 0) {
            this.isPrevDisabled = true;
        }
        this.emitIsPrevDisabled.emit(this.isPrevDisabled);
        return this.isPrevDisabled;
    }

    buildAnimation(offset) {
        return this.builder.build([
            animate(this.timing, style({ transform: `translateX(-${offset}px)` }))
        ]);
    }

    ngOnDestroy(): void {
        if (this.carouselSubscription$) this.carouselSubscription$.unsubscribe();
    }
}
