import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './auth/auth-guard.service';
import { AccountsComponent } from './pages/accounts/accounts.component';
import { AccountsGuard } from './pages/accounts/accounts.guard';
import { AccountsResolver } from './pages/accounts/accounts.resolver';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { ResetPasswordGuard } from './pages/reset-password/reset-password.guard';

const routes: Routes = [
    { path: '', component: AccountsComponent, resolve: { accountsLoaded: AccountsResolver } },
    {
        path: 'accounts', loadChildren: () => import('./pages/accounts/accounts.module').then((m) => m.AccountsModule), canLoad: [AccountsGuard], resolve: { accountsLoaded: AccountsResolver }
    },
    { path: 'certificates', loadChildren: () => import('./pages/certificates-validator/certificates-validator.module').then((m) => m.CertificatesValidatorModule), resolve: { accountsLoaded: AccountsResolver } },

    { path: 'disciplines-models', loadChildren: () => import('./pages/disciplines-models/disciplines-models.module').then((m) => m.DisciplinesModelsModule), },
    { path: 'import', loadChildren: () => import('./pages/import/import.module').then((m) => m.ImportModule) },
    { path: 'import-spreadsheet', loadChildren: () => import('./pages/import-spreadsheet/import-spreadsheet.module').then((m) => m.ImportSpreadsheetModule) },
    { path: 'support', loadChildren: () => import('./pages/widget-help/widget-help.module').then((m) => m.WidgetHelpModule) },
    { path: 'notifications', loadChildren: () => import('./pages/notifications/notifications.module').then((m) => m.NotificationsModule) },
    { path: 'profile', loadChildren: () => import('./pages/profile/profile.module').then((m) => m.ProfileModule) }, // verificar se essa rota vai ser usada

    { path: 'reset', component: ResetPasswordComponent, canActivate: [ResetPasswordGuard] },
    { path: 'dashboard', loadChildren: () => import('./pages/dashboard/dashboard.module').then((m) => m.DashboardModule), canLoad: [AuthGuard] },
    {
        path: 'disciplines', loadChildren: () => import('./pages/disciplines/disciplines.module').then((m) => m.DisciplinesModule), canLoad: [AuthGuard], resolve: { accountsLoaded: AccountsResolver }
    },
    { path: 'orgunits', loadChildren: () => import('./pages/org-units/org-units.module').then((m) => m.OrgUnitsModule), canLoad: [AuthGuard] },
    { path: 'permissions', loadChildren: () => import('./pages/permissions/permissions.module').then((m) => m.PermissionsModule), canLoad: [AuthGuard] },
    { path: 'settings', loadChildren: () => import('./pages/settings/settings.module').then((m) => m.SettingsModule), canLoad: [AuthGuard] },
    { path: 'users', loadChildren: () => import('./pages/users/users.module').then((m) => m.UsersModule), canLoad: [AuthGuard] },
    { path: 'reports', loadChildren: () => import('./pages/reports/reports.module').then((m) => m.ReportsModule), canLoad: [AuthGuard] },
    { path: 'chromebook-dashboard-extension', loadChildren: () => import('./pages/chromebook-extension/chromebook-extension.module').then((m) => m.ChromebookExtensionModule), canLoad: [AuthGuard] },
    { path: 'assiduity', loadChildren: () => import('./pages/assiduity/assiduity.module').then((m) => m.AssiduityModule), canLoad: [AuthGuard] },
    { path: 'communication-app', loadChildren: () => import('./pages/communication-app/communication-app.module').then((m) => m.CommunicationAppModule), canLoad: [AuthGuard] },
    { path: 'content-planner', loadChildren: () => import('./pages/content-planner/content-planner.module').then((m) => m.ContentPlannerModule), canLoad: [AuthGuard] },
    { path: 'sports', loadChildren: () => import('./pages/sports/sports.module').then((m) => m.SportsModule), canLoad: [AuthGuard] },
    { path: 'module-chrome-geolocation-reports', loadChildren: () => import('./pages/module-chrome-geolocation-reports/module-chrome-geolocation-reports.module').then((m) => m.ModuleChromeGeolocationReportsModule), canLoad: [AuthGuard] },
    { path: 'module-chrome-geolocation-user', loadChildren: () => import('./pages/module-chrome-geolocation-user/module-chrome-geolocation-user.module').then((m) => m.ModuleChromeGeolocationUserModule), canLoad: [AuthGuard] },
    { path: 'module-chrome-geolocation-institute', loadChildren: () => import('./pages/module-chrome-geolocation-institute/module-chrome-geolocation-institute.module').then((m) => m.ModuleChromeGeolocationInstituteModule), canLoad: [AuthGuard] },
    { path: 'class-planner', loadChildren: () => import('./pages/content-planner/content-planner.module').then((m) => m.ContentPlannerModule), canLoad: [AuthGuard] },
    { path: 'cms', loadChildren: () => import('./pages/cms/cms.module').then((m) => m.CmsModule) },
    { path: 'certificates-dashboard', loadChildren: () => import('./pages/certificates-dashboard/certificates-dashboard.module').then((m) => m.CertificatesDashboardModule) },
    { path: 'certifications', loadChildren: () => import('./pages/certifications/certifications.module').then((m) => m.CertificationsModule) },

    { path: '**', loadChildren: () => import('./pages/not-found/not-found.module').then((m) => m.NotFoundModule) },
];

@NgModule({
    imports: [RouterModule.forRoot(
        routes,
        {
            preloadingStrategy: PreloadAllModules
        }
    )],
    exports: [RouterModule]
})
export class AppRoutingModule { }
